import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { getErrors } from 'firefly/component/Form/utils';
import { Errors } from 'firefly/component';

import { getValue } from 'firefly-hoc';
import { withT } from '../../hoc';

class Input extends Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    defaultImage: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]).isRequired
  };

  constructor(props) {
    super();
    this.state = { preview: props.defaultImage };
  }

  componentDidUpdate({ defaultImage: prevDefaultImage }) {
    const { defaultImage } = this.props;
    if (prevDefaultImage !== defaultImage) {
      this.setState({ preview: defaultImage });
    }
  }

  onSelect(e) {
    const file = getValue('file', e);
    const preview = file ? URL.createObjectURL(file) : this.props.defaultImage;
    this.setState({ preview });
    this.props.onChange(this.props.name, 'file')(e);
  }

  render() {
    const { name, errors, label, t } = this.props;
    const inputErrors = getErrors(name, errors);
    return (
      <Fragment>
        <div className="b-file-preview-input">
          <p className="b-label b-label--focus">{label}</p>
          <input className="b-file-preview-input__input" type="file" onChange={e => this.onSelect(e)}/>
          {this.state.preview ? (
            <img className="b-file-preview-input__preview" src={this.state.preview} alt=""/>
          ) : (
            <div className="b-file-preview-input__emptyPreview">
              + {t('add')} {label.toLowerCase()}
            </div>
          )}
        </div>
        <Errors errors={inputErrors}/>
      </Fragment>

    );
  }
}

export const FilePreviewInput = withT(Input);
