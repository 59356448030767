import React from 'react';
import { compose } from 'recompose';
import { H4 } from 'firefly/component';
import { SuggestPartnershipForm } from '../component/ContactUs';
import { withT } from '../hoc';
import { ButtonBack } from '../module/ButtonBack';

const CooperationScreen = ({ t }) => (
  <div className="b-contact-us">
    <ButtonBack goToPage="/contacts"/>
    <div className="b-contact-us__summary">
      <div className="b-contact-us__summary__container__view">
        <div className="b-contact-us__summary__container__block__view">
          <H4 className="b-contact-us__summary__container__block__header">
            {t('contact_us.summary.suggest_partnership.header')}
          </H4>
          <p className="b-contact-us__summary__container__block__description">
            {t('contact_us.summary.suggest_partnership.description')}
          </p>
          <SuggestPartnershipForm/>
        </div>
      </div>
    </div>
  </div>
);

export const Cooperation = compose(
  withT
)(CooperationScreen);
