import React, { Fragment } from 'react';
import FeedbackTable from '../../component/Table';
import Header from '../../../../component/Header';
import { withAdminT } from '../../../../hoc';


const Screen = props => (
  <Fragment>
    <Header pageTitle={props.t('pages.feedback')}/>
    <FeedbackTable {...props}/>
  </Fragment>
);

export default withAdminT(Screen);
