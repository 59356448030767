import React, { Component } from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose, renderComponent, branch } from 'recompose';
import { SpinnerGlobal } from 'firefly/component';
import consts from '../consts';
import { hasPermission } from '../utils/user';
import { withUser, withT } from '../hoc';
import { toastError } from '../utils/index';

class AuthPrivateRoute extends Component {
  static propTypes = {
    component: PropTypes.any.isRequired,
    layout: PropTypes.any,
    minPermission: PropTypes.string,
    user: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    user: null,
    layout: null,
    minPermission: consts.USER_REGULAR
  };


  render() {
    const {
      component: WrapComponent,
      layout: Layout,
      user,
      minPermission,
      t,
      ...rest
    } = this.props;

    const render = props => {
      if (user && hasPermission(user, minPermission)) {
        return Layout ? (
          <Layout {...rest}>
            <WrapComponent {...rest} />
          </Layout>
        ) : (
          <WrapComponent {...rest} />
        );
      }
      if (!user) {
        toastError(t('login.not_authenticated'));
      } else {
        toastError(t('login.not_enough_permissions'));
      }
      return <Redirect to="/" />;
    };

    return <Route render={render} {...rest} />;
  }
}

export default compose(
  withT,
  withUser,
  branch(props => props.data.loading, renderComponent(SpinnerGlobal))
)(AuthPrivateRoute);
