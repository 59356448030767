import React, { Fragment } from 'react';
import {getFullImgUrl, isHttpUrlCorrect, isUrlCorrect} from '../utils/index';
import { ExternalLink } from 'react-feather';
import {FestivalMarker, ModalEditProfile} from '../component';
import calendarIcon from '../img/calendar-icon.svg';
import noteIcon from '../img/note-icon.svg';
import locationIcon from '../img/location-icon.svg';
import defaultLogo from '../img/default-party-logo.png';


import { Map } from '../module/Map';
import {Button} from "firefly/component/Button";
import rubIcon from '../img/currencies/rub-gold-color.svg';
import rubCashbackIcon from '../img/currencies/rub-cashback.svg';
import eurIcon from '../img/currencies/euro-gold-color.svg';
import usdIcon from '../img/currencies/usd-gold-color.svg';

const currencyIcon = {
  RUB: rubIcon,
  EUR: eurIcon,
  USD: usdIcon
}

const addTimeToDate = (start_date, finish_date) => start_date + ' - ' + finish_date;

const MaybeRenderLinks = ({ name, links }) => {
  if (isUrlCorrect((links || {}).website)) {
    return (<p className="b-event-page__info__content__header">
      <a className="b-event-page__info__content__header__name-link"
         href={isHttpUrlCorrect(links.website) ? links.website: `http://${links.website}`}
         rel="noopener noreferrer"
         target="_blank">{name}</a>
      <a className="b-event-page__external-link"
         href={isHttpUrlCorrect(links.website) ? links.website: `http://${links.website}`}
         rel="noopener noreferrer"
         target="_blank">
        <ExternalLink/>
      </a>
    </p>)
  }
  if (isUrlCorrect((links || {}).fb)) {
    return (<p className="b-event-page__info__content__header">
      <a className="b-event-page__info__content__header__name-link"
         href={isHttpUrlCorrect(links.fb) ? links.fb: `http://${links.fb}`}
         rel="noopener noreferrer"
         target="_blank">{name}</a>
      <a className="b-event-page__external-link"
         href={isHttpUrlCorrect(links.fb) ? links.fb: `http://${links.fb}`}
         rel="noopener noreferrer"
         target="_blank">
        <ExternalLink/>
      </a>
    </p>)
  }
  if (isUrlCorrect((links || {}).vk)) {
    return (<p className="b-event-page__info__content__header">
      <a className="b-event-page__info__content__header__name-link"
         href={isHttpUrlCorrect(links.vk) ? links.vk: `http://${links.vk}`}
         rel="noopener noreferrer"
         target="_blank">{name}</a>
      <a className="b-event-page__external-link"
         href={isHttpUrlCorrect(links.vk) ? links.vk: `http://${links.vk}`}
         rel="noopener noreferrer"
         target="_blank">
        <ExternalLink/>
      </a>
    </p>)
  }
  return <p className="b-event-page__info__content__header">{name}</p>;
};

const checkRequireProfile = (user) => {
  return (!user.email || !user.first_name || !user.last_name || !user.dance_style || !user.agree_terms);
}

export const Festival = ({ festival, t, user, setModalProfileEdit, modalProfileEdit, create_pass_ticket }) => {
  const { name, price, logo_path, links, description, dance_styles, address, start_date, finish_date, cancelled } = festival;
  return (
    <Fragment>
      {user && (
        <ModalEditProfile modalProfileEdit={modalProfileEdit} setModalProfileEdit={setModalProfileEdit}/>
      )}
      <div className="b-event-page__info">
        <div className="b-event-page__info__content">
          <p className="b-event-page__info__content__header__mobile">{name} <MaybeRenderLinks links={links}/> </p>
        </div>
        <div className="b-event-page__info__image">
          <img className="b-event-page__info__image__img"
               src={logo_path ? getFullImgUrl(logo_path) : defaultLogo} alt=""
          />
          {cancelled ? (
            <div className="b-event-page__info__image__img__cancelled"/>
          ) : (
            ''
          )}
        </div>

        <div className="b-event-page__info__content">
          <MaybeRenderLinks name={name} links={links}/>
          <div className="b-event-page__info__content__crumbs">
            <div className="b-event-page__info__content__crumbs__item">
              <img className="b-event-page__info__content__crumbs__item__img" src={calendarIcon} alt=""/>
              {addTimeToDate(start_date, finish_date)}
            </div>

            {dance_styles.length > 0 && <div className="b-event-page__info__content__crumbs__item">
              <img className="b-event-page__info__content__crumbs__item__img" src={noteIcon} alt=""/>
              {dance_styles.map((style, id) => <div className="b-event-page__info__content__crumbs__item__style"
                                                    key={id}>{style}</div>)}
            </div>}

            <div className="b-event-page__info__content__crumbs__item">
              <a rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/?q=${festival.location.lat},${festival.location.lng}`}>
                <img className="b-event-page__info__content__crumbs__item__img" src={locationIcon} alt=""/>
              </a>
              <a rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/?q=${festival.location.lat},${festival.location.lng}`}>
                <p>{address}</p>
              </a>
            </div>
          </div>
          <p className="b-event-page__info__content__text">{description}</p>
        </div>
      </div>

      <div className="b-event-page__map">
        <Map fullWidth={true} center={festival.location}>
          <FestivalMarker festival={festival}/>
        </Map>
      </div>
    </Fragment>);
};
