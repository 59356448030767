import { Dialog, DialogBody } from "firefly/component/Dialog";
import { Link } from 'react-router-dom';
import React from "react";
import { isHttpUrlCorrect } from '../../../../../utils/index';
import {Button} from "firefly/component/Button";

export const FeedbackModal = ({ selectedFeedback, setSelectedFeedback, t, setClosedTicket, refetch, filter }) => (
  <Dialog isOpen={selectedFeedback !== null} onClose={() => setSelectedFeedback(null)}>
    <DialogBody>
      <p>{t('feedback_page.type')}: {t(`feedback_page.${selectedFeedback.type}`)}</p>

      {selectedFeedback.contact_information !== null && (
        <p>{t('feedback_page.contact_information')}: {selectedFeedback.contact_information}</p>
      )}

      {selectedFeedback.letter !== null && (
        <p>{t('feedback_page.letter')}: {selectedFeedback.letter}</p>
      )}

      {selectedFeedback.name !== null && (
        <p>{t('feedback_page.name')}: {selectedFeedback.name}</p>
      )}

      {selectedFeedback.party_id !== null && (
        <p>{t('feedback_page.party_id')}: <Link to={{ pathname: `/party/${selectedFeedback.party_id}` }}>/party/{selectedFeedback.party_id}</Link></p>
      )}

      {selectedFeedback.suggested_party !== null && (
        <div>
          {selectedFeedback.suggested_party.description !== null && (
            <p>{t('feedback_page.suggested_party.description')}: {selectedFeedback.suggested_party.description}</p>
          )}
          {selectedFeedback.suggested_party.link_on_party !== null && (
            <p>{t('feedback_page.suggested_party.link_on_party')}: <a
                className="b-event-page__external-link"
                href={isHttpUrlCorrect(selectedFeedback.suggested_party.link_on_party)
                  ? selectedFeedback.suggested_party.link_on_party
                  : `http://${selectedFeedback.suggested_party.link_on_party}`}
                rel="noopener noreferrer"
                target="_blank">{selectedFeedback.suggested_party.link_on_party}</a></p>
          )}
        </div>
      )}

      {!selectedFeedback.closed && (
        <Button className="b-button--margin-small b-button--blue-outline b-button--blue-outline--full-width b-button--blue-outline--flex--center"
                onClick={async () => {
                  await setClosedTicket({variables: {feedbackId: selectedFeedback.id}})
                  setSelectedFeedback(null)
                  refetch(filter)
                }}>
          CLOSE
        </Button>
      )}

    </DialogBody>
  </Dialog>
);