import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { withT } from '../../hoc';

const Container = ({ goToPage, history, t }) => (
  <div>
    {history.length > 0 && (
      <div onClick={() => {
        if (goToPage) {
          history.push(goToPage);
        } else {
          history.go(-1);
        }
      }} className="b-button--go-back">
        {t('go_back')}
      </div>
    )}
  </div>
);

export const ButtonBack = compose(
  withRouter,
  withT,
  withProps(props => ({
    ...props
  })),
)(Container);
