import React from 'react';
import { compose, lifecycle, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Party as Component } from '../component/Party';
import { withT } from '../hoc';
import { writeLocalState } from '../utils/index';
import bg_top from '../img/events-bg/lg.png';

const PartyScreen = ({ t, onBack, id }) => (
  <div className="b-event-page">
    <div className="b-event-page__back" onClick={onBack}>
      {t('parties.all_parties')}
    </div>
    <Component id={id} />
  </div>
);

export const Party = compose(
  withT,
  withRouter,
  withProps(props => ({
    ...props,
    id: props.match.params.id,
    onBack: () => (props.history.location.state || {}).isPartyPage ? props.history.goBack() : props.history.push('/parties'),
  })),
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(PartyScreen);
