import React from 'react';
import Sidebar from './Sidebar';

export const SidebarLayout = ({ children }) => (
  <div className="b-page-admin">
    <div className="b-page-admin__sidebar">
      <Sidebar/>
    </div>
    <div className="b-page-admin__content">
      {children}
    </div>
  </div>
);
