import React from 'react'
import { withT } from "../hoc";
import { H1 } from "firefly/component/Heading";
import { PasswordRecovery as Component } from "../component";
import {compose, lifecycle} from "recompose";
import {writeLocalState} from "../utils";
import bg_top from "../img/events-bg/lg.png";
import {ButtonBack} from "../module/ButtonBack";

const PasswordRecoveryScreen = ({ t }) => (
  <div className="b-login">
    <ButtonBack goToPage="/login"/>
    <H1 className="b-parties__header">{t('password_recovery.title')}</H1>
    <Component/>
  </div>
);

export const PasswordRecovery = compose(
  withT,
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(PasswordRecoveryScreen);