import React, { Component, Fragment } from 'react';
import shallowequal from 'shallowequal';
import moment from 'moment';
import qs from 'qs';
import * as Icons from 'react-feather';
import PropTypes from 'prop-types';
import { Pagination, Segment, SpinnerOverlay, Button } from 'firefly/component';
import BaseTable from '../../../../component/Table';
import TableSearch from '../Search';
import { filterValues } from '../../../../../utils';
import { FeedbackModal } from '../Feedback';

export default class Table extends Component {
  static propTypes = {
    refetch: PropTypes.func.isRequired,
    setSelectedFeedbackTable: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    filter: PropTypes.object,
    search: PropTypes.bool,
    loading: PropTypes.bool,
    objects: PropTypes.array,
    isOpenDeleteModal: PropTypes.bool,
    meta: PropTypes.object,
    user: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    cities: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    selectedFeedbackTable: PropTypes.object,
    setClosedTicket: PropTypes.func.isRequired
  };

  static defaultProps = {
    search: true,
    loading: true,
    filter: {},
    objects: [],
    meta: {},
    selectedFeedbackTable: null
  };

  state = {
    search: false,
  };


  componentDidUpdate(prevProps) {
    if (false === shallowequal(this.props.filter, prevProps.filter)) {
      this.refetch(this.props.filter);
    }
  }

  removeEmptyParameters(params = {}) {
    return filterValues({
      ...this.props.filter,
      ...params
    });
  }

  redirect(params = {}) {
    const queryString = qs.stringify(this.removeEmptyParameters(params));

    const { history, location } = this.props;

    history.push(`${location.pathname}?${queryString}`);
  }

  refetch(params = {}) {
    this.props.refetch(this.removeEmptyParameters(params));
  }

  onChangeOrder = order => {
    this.redirect({ order });
  };

  getColumns() {
    return [
      {
        key: x => x.id,
        sortable: 'id',
        title: 'id',
      },
      {
        key: x => (this.props.t(`feedback_page.${x.type}`) || <Icons.Minus />),
        sortable: 'type',
        title: 'type',
      },
      {
        key: x => x.letter,
        title: 'description',
      },
      {
        key: x => moment(x.created_at).format('lll'),
        sortable: 'created_at',
        title: 'created_at'
      },
      {
        key: x => <Button className="b-button--flex--center" onClick={() => {
          this.props.setSelectedFeedbackTable(x);
        }}>open</Button>,
        title: 'open'
      }
    ];
  }

  render() {
    const { search, filter, objects, meta, loading, t, selectedFeedbackTable, setSelectedFeedbackTable, setClosedTicket, refetch } = this.props;
    moment.locale('ru');
    return (
      <Fragment>
        {selectedFeedbackTable !== null && (
          <FeedbackModal
            refetch={refetch}
            filter={filter}
            setClosedTicket={setClosedTicket}
            selectedFeedback={selectedFeedbackTable}
            setSelectedFeedback={setSelectedFeedbackTable}
          />
        )}
        <SpinnerOverlay show={loading}>
          {search && (
            <Segment>
              <div className="b-input-group">
                <TableSearch
                  t={t}
                  filter={filter}
                  onSuccess={filter => this.redirect(filter)}
                />
              </div>
            </Segment>)}
          {objects.length > 0 ? (<Segment>
            <BaseTable
              order={filter.order}
              onSort={this.onChangeOrder}
              columns={this.getColumns().map(column => ({ ...column, title: t(`feedback_page.${column.title}`) }))}
              data={objects}
            />
            {meta.page_count > 1 && (
              <Pagination
                scrollToTop={false}
                pageSize={parseInt(filter.page_size, 10)}
                onPageSizeChange={page_size => this.redirect({ page_size })}
                onPageChange={page => this.redirect({ page })}
                pageCount={meta.page_count || 1}
                page={parseInt(filter.page || 1, 10)}
                total={meta.total || 1}
              />
            )}
          </Segment>) : (
            <Segment>

              {t('feedback_page.not_found')}
            </Segment>
          )}
        </SpinnerOverlay>
      </Fragment>
    );
  }
}
