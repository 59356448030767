import React from 'react';
import {compose, lifecycle} from "recompose";
import {withT} from "../hoc";
import { H2 } from 'firefly/component';
import { RestorePassword as Component } from '../component';
import {writeLocalState} from "../utils";
import bg_top from "../img/events-bg/lg.png";
import {ButtonBack} from "../module/ButtonBack";

const RestorePasswordScreen = ({ t }) => (
  <div className="b-login">
    <ButtonBack goToPage="/login"/>
    <H2 className="b-parties__header">{t('password_recovery.title')}</H2>
    <Component/>
  </div>
);

export const RestorePassword = compose(
  withT,
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(RestorePasswordScreen);