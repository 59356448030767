import objectPath from 'object-path-value';

export const getErrors = (path, errors = []) => {
  return objectPath(errors || [], path, []) || [];
};

export const isAxiosError = (e) => {
  return e.error && e.error.response && e.error.response.status === 400;
};

export const getAxiosErrors = (e) => {
  return e.error.response.data.errors;
};

export const isGraphQLError = (e) => {
  if (typeof e.graphQLErrors === 'undefined') {
    return false;
  }

  for (let i = 0; i < e.graphQLErrors.length; i++) {
    const error = e.graphQLErrors[i];
    if (error.extensions && error.extensions.code === 'VALIDATION_ERROR') {
      return true;
    }
  }

  return false;
};

export const getGraphQLErrors = (e) => {
  for (let i = 0; i < e.graphQLErrors.length; i++) {
    const error = e.graphQLErrors[i];
    if (error.extensions.code === 'VALIDATION_ERROR') {
      return error.extensions.exception.errors;
    }
  }

  return {};
};
