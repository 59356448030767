import React, { Component, createElement } from 'react';
import PropTypes from 'prop-types';
import { FormRow } from './FormRow';
import { Label } from './Label';
import { Input } from './Input';
import { Errors } from './Errors';
import { getErrors } from './utils';

export class Part extends Component {
  static propTypes = {
    name: PropTypes.string.isRequired,
    help: PropTypes.string,
    label: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object
    ]).isRequired,
    component: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.func
    ]),
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array
    ]).isRequired
  };

  static defaultProps = {
    required: true,
    component: null,
    help: null,
    input: null
  };

  state = {
    focus: false
  };

  onFocus = () => {
    this.setState({
      focus: true
    });
  };

  onBlur = () => {
    this.setState({
      focus: false
    });
  };

  render() {
    const {
      style,
      name,
      type,
      help,
      label,
      component,
      required,
      onChange,
      errors,
      extra,
      ...rest
    } = this.props;

    const { focus } = this.state;

    const inputErrors = getErrors(name, errors);

    const labelComponent = label ? (
      <Label
        modifiers={{ focus: focus || rest.value }}
        htmlFor={name}
        required={required}>
        {label}
      </Label>
    ) : null;

    const inputComponent = component ? createElement(component, {
      name,
      type,
      id: name,
      onChange,
      hasErrors: inputErrors.length > 0,
      onBlur: this.onBlur,
      onFocus: this.onFocus,
      ...rest
    }) : (
      <Input
        type={type}
        name={name}
        hasErrors={inputErrors.length > 0}
        id={name}
        onBlur={this.onBlur}
        onFocus={this.onFocus}
        onChange={onChange}
        {...rest} />
    );

    return (
      <FormRow style={style}>
        {['radio', 'checkbox'].indexOf(type) === -1 && labelComponent}
        {inputComponent}
        {['radio', 'checkbox'].indexOf(type) > -1 && labelComponent}
        {help && (
          <div className="b-form__help">
            {help}
          </div>
        )}
        {extra}
        <Errors errors={inputErrors}/>
      </FormRow>
    );
  }
}
