import { withRouter } from 'react-router-dom';
import { Sidebar } from './Sidebar';
import {pure, compose, withProps} from 'recompose';
import { withAdminT } from '../../hoc';
import { withUser } from '../../../hoc';

export default compose(
  withAdminT,
  withUser,
  withProps(props => ({
    ...props,
    loading: props.data.loading
  })),
  pure
)(withRouter(Sidebar));
