import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, FormRow, Part } from 'firefly/component';
import BaseFilterForm from '../../../../component/FilterForm';

export default class FilterForm extends BaseFilterForm {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      form,
      errors
    } = this.state;

    const { t } = this.props;

    return (
      <form onSubmit={this.submit} className="b-admin-filters__popover b-admin-filters__popover__theme-dark">

        <div className="b-admin-filters__group">
          <div className="b-admin-filters__group__checkbox-group b-admin-filters__popover__theme-dark">
            <Part
              required={false}
              type='checkbox'
              value={form.is_active || ''}
              name='is_active'
              onChange={this.change('is_active')}
              label={t('users_page.active_users')}
              className="checkbox"
              errors={errors} />
          </div>
        </div>
        <div className="b-admin-filters__group">
          <div className="b-admin-filters__group__checkbox-group b-admin-filters__popover__theme-dark">
            <Part
              required={false}
              type='checkbox'
              value={form.is_inactive || ''}
              name='is_inactive'
              onChange={this.change('is_inactive')}
              label={t('users_page.inactive_users')}
              className="checkbox"
              errors={errors} />
          </div>
        </div>
        <FormRow>
          <div className="b-page-admin--width-full b-page-admin--flex--center">
            <Button className="b-admin-filters__form b-button--flex--center b-button--color-white" type="submit">
              {t('users_page.apply')}
            </Button>
          </div>
          <Divider modifiers='small' />
          <button onClick={this.onReset} className='b-link b-link--center' type="button">
            {t('users_page.reset')}
          </button>
        </FormRow>
      </form>
    );
  }
}
