import React from 'react';
import { compose, withProps, withState } from 'recompose';
import { Popover } from 'firefly/component';
import { withCitiesFestivals, withT } from '../../hoc';

const getCityName = (cityEntry, i18n) => cityEntry.name_translations[i18n.language.slice(0, 2).toLowerCase()] || cityEntry.name;
const getCountryNameFromObject = (cities, defaultSelectValue) => {
  let citiesToCountries = {};
  Object.values(cities).map((cityEntry, key) => {
    if (citiesToCountries[cityEntry['country']] === undefined) {
      citiesToCountries[cityEntry['country']] = { country: cityEntry['country'].charAt(0).toUpperCase() + cityEntry['country'].slice(1) };
      citiesToCountries[cityEntry['country']].cities = {};
      citiesToCountries[cityEntry['country']].cities[key] = { id: cityEntry.id, name_translations: cityEntry.name_translations, name: cityEntry.name };
    }
    if (citiesToCountries[cityEntry['country']] !== undefined) {
      citiesToCountries[cityEntry['country']].cities[key] = { id: cityEntry.id, name_translations: cityEntry.name_translations, name: cityEntry.name };
    }
    return null;
  });
  return citiesToCountries;
};

const Container = ({ t,
                     i18n,
                     cities,
                     cityId,
                     onChange,
                     className,
                     refStore,
                     disabledCities = [],
                     isCityAllowed,
                     defaultSelectValue='not_chosen',
                     aroundTheWorld=false
                   }) => (
  <Popover ref={refStore.setRef('popover')} position="bottom" popperModifiers={{ arrow: false }}>
    <div className={className}>
      {cities[cityId] ? getCityName(cities[cityId], i18n) : t(defaultSelectValue)}
    </div>
    <div>
      {aroundTheWorld && (
        <div key={0} className="b-popover__content__item" onClick={() => onChange(0)}>
          {t(defaultSelectValue)}
        </div>
      )}
      {Object.values(getCountryNameFromObject(cities, defaultSelectValue)).map((cityEntry, key) => (
        <div key={key} className="b-popover__content__item">
          <Popover position="right">
            <div className="b-filters__popover-picker__content__country b-popover__content__item">
              {cityEntry.country}
            </div>
            <div>
              {Object.values(cityEntry.cities).map((city) => (
                city.id !== cityId && -1 === disabledCities.indexOf(city.id) && isCityAllowed(city.id)
                && (<div key={city.id} className="b-popover__content__item" onClick={() => onChange(city.id)}>
                  {getCityName(city, i18n)}
                </div>)
              ))}
            </div>
          </Popover>
        </div>
      ))}
    </div>
  </Popover>
);

function RefStore() {
  this.refs = {};
  this.setRef = name => ref => { this.refs[name] = ref; };
  this.getRef = name => this.refs[name];
}

export const CitySelectorFestivals = compose(
  withT,
  withCitiesFestivals,
  withState('refStore', 'setRefStore', new RefStore()),
  withProps(props => ({
    ...props,
    isCityAllowed(cityId) {
      if (props.onlyAllowedCities) {
        return (props.allowedCities || []).indexOf(cityId) !== -1;
      }
      return true;
    },
    onChange(value) {
      props.refStore.getRef('popover').close();
      props.onChange(value);
    }
  }))
)(Container);
