import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { compose, withProps, withState } from 'recompose';
import Table from './Table';
import { graphql } from 'react-apollo/index';
import { feedbackAdminList, setClosedTicket } from '../../query';
import { withAdminT } from '../../../../hoc';
import { stringifyValues } from '../../../../../utils';
import { withCities, withUser } from '../../../../../hoc';


export default compose(
  withAdminT,
  withCities,
  withUser,
  withRouter,
  withState('selectedFeedbackTable', 'setSelectedFeedbackTable', null),
  withProps(props => ({
    ...props,
    filter: {
      ...(props.filter || {}),
      page: '1',
      page_size: '10',
      ...qs.parse(props.location.search.slice(1))
    },
  })),
  graphql(feedbackAdminList, {
    options: props => ({
      variables: {
        input: stringifyValues(props.filter)
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(setClosedTicket, { name: "setClosedTicket" }),
  withProps(props => ({
    ...props,
    refetch: filter => props.data.refetch({ input: filter }),
    loading: props.data.loading,
    ...(props.data.feedbackAdminList || {})
  }))
)(Table);
