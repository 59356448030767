import React from 'react';
import { Transition, H1, H2 } from 'firefly/component';
import { ButtonBack } from '../module/ButtonBack';
import { withT } from '../hoc';

export const Privacy = withT(({ t }) => (
  <Transition>
    <ButtonBack goToPage="/"/>
    <H1>{t('Privacy.title')}</H1>

    <H2>1. {t('Privacy.title_1')} </H2>
    <div className="b-typeset">
      <p>
        {t('Privacy.desc')}
      </p>

      <p>
        1. {t('Privacy.1')}
      </p>
      <p>2. {t('Privacy.2')} <a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a>.
      </p>
    </div>
    <H2>2. {t('Privacy.title_2')}</H2>
    <div className="b-typeset">
      <p>
        1. {t('Privacy.2_1')}
      </p>

      <p>
        2. {t('Privacy.2_2')}
      </p>

      <p>
        3. {t('Privacy.2_3')}  <a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a>;
      </p>

      <p>
        4. {t('Privacy.2_4')}
      </p>

      <p>
        5. {t('Privacy.2_5')}
      </p>

      <p>
        6. {t('Privacy.2_6')}
      </p>

      <p>
        7. {t('Privacy.2_7')}
      </p>

      <p>
        8. {t('Privacy.2_8')}  <a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a>;
      </p>

      <p>
        9. {t('Privacy.2_9')} <a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a>;
      </p>

      <p>
        10. {t('Privacy.2_10')}
      </p>

      <p>
        11. {t('Privacy.2_11')}
      </p>

      <p>
        12. {t('Privacy.2_12')}
      </p>

      <p>
        13. {t('Privacy.2_13')}
      </p>
    </div>
    <H2>3. {t('Privacy.3')} </H2>
    <div className="b-typeset">
      <p>
        1. {t('Privacy.3_1')}
      </p>

      <p>
        2. {t('Privacy.3_2')}
      </p>

      <p>
        3. {t('Privacy.3_3')}
      </p>

      <p>
        4. {t('Privacy.3_4')}
      </p>

      <p>
        5. {t('Privacy.3_5')}
      </p>

      <p>
        6. {t('Privacy.3_6')}
      </p>

      <p>
        7. {t('Privacy.3_7')}
      </p>
    </div>
    <H2>4. {t('Privacy.4')} </H2>
    <div className="b-typeset">
      <p>
        1. {t('Privacy.4_1')}
      </p>

      <p>
        2. {t('Privacy.4_2_f')} <a href="mailto:panichmax@gmail.com">panichmax@gmail.com</a> {t('Privacy.4_2_s')}
      </p>

      <p>
        3. {t('Privacy.4_3')}
      </p>
    </div>
    <H2>5. {t('Privacy.5')}</H2>
    <div className="b-typeset">
      <p>
        1. {t('Privacy.5_1_f')} <a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a>. {t('Privacy.5_1_s')}
      </p>

      <p>
        2. {t('Privacy.5_2')}
      </p>
    </div>
    <H2>6. {t('Privacy.6')} </H2>
    <div className="b-typeset">
      <p>
        {t('Privacy.desc_6')}
      </p>

      <p>
        1. {t('Privacy.6_1')}
      </p>

      <p>
        2. {t('Privacy.6_2')}
      </p>

      <p>
        3. {t('Privacy.6_3_f')} <a href="mailto:panichmax@gmail.com">panichmax@gmail.com</a> {t('Privacy.6_3_s')}
      </p>

      <p>
        4. {t('Privacy.6_4_f')} <a href="mailto:panichmax@gmail.com">panichmax@gmail.com</a> {t('Privacy.6_4_s')}
      </p>
    </div>
    <H2>7. {t('Privacy.7')} </H2>
    <div className="b-typeset">
      <p>
        1. {t('Privacy.7_1')}
      </p>

      <p>
        2. {t('Privacy.7_2')}
      </p>
    </div>
    <H2>8. {t('Privacy.8')} </H2>
    <div className="b-typeset">
      <p>
        1. {t('Privacy.8_1')} <a href="mailto:panichmax@gmail.com">panichmax@gmail.com</a>.
      </p>

      <p>
        2. {t('Privacy.8_2')}
      </p>

      <p>
        3. {t('Privacy.8_3')} <a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a>.
      </p>
    </div>
  </Transition>
));
