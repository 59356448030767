import { InMemoryCache } from 'apollo-cache-inmemory';

export const cache = new InMemoryCache({
  addTypename: false
});

cache.writeData({ // initial values
  data: {
    is_menu_open: false,
    bg_top: '',
    bg_top_position: 'top right',
    bg_bottom: '',
    bg_bottom_position: 'bottom right',
    markers_closed: true
  }
});
