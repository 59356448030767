import gql from 'graphql-tag';

export const userList = gql`
  query userList($input: UserListInput!) {
    userList(input: $input) {
      meta {
        page
        page_size
        page_count
        total
      }
      objects
    }
  }
`;

export const user = gql`
  query user($userId: Int!) {
    user(userId: $userId)
  }
`;



export const userOptions = gql`
  query userOptions {
    userOptions
  }
`;
