import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import { Segment, Spinner } from 'firefly/component';
import SmartDetail from '../../../../component/SmartDetail';
import Header from '../../../../component/Header';

export default class View extends Component {
  static propTypes = {
    fetch: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    user: PropTypes.object,
    loading: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool
    ]),
    error: PropTypes.any,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    error: undefined,
    settings: {},
    loading: true,
    user: undefined
  };

  state = {
    isOpen: false
  };

  componentDidMount() {
    const {
      fetch,
      match
    } = this.props;

    fetch(match.params.id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      const {
        fetch,
        match
      } = this.props;

      fetch(match.params.id);
    }
  }

  toggle = e => {
    e.preventDefault();

    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  render() {
    const {
      loading,
      user,
      t
    } = this.props;

    moment.locale('ru');

    const mapping = [
      {
        title: 'name',
        value: x => x.first_name ? `${x.first_name} ${x.last_name}` : <Icons.Minus />
      },
      {
        title: 'email',
        value: x => x.email || (
          <Icons.X />
        )
      },
      {
        title: 'is_active',
        value: x => x.is_active ? (
          <Icons.Check />
        ) : (
          <Icons.X />
        )
      },
      {
        title: 'registration_date',
        value: x => moment(x.created_at).format('lll')
      }
    ];

    // const {
    //   isOpen
    // } = this.state;

    return (
      <div>
        <Header pageTitle={user ? user.email : t('loading')} />
        {(loading || !user) ? (
          <Spinner />
        ) : (
          <div>
            <Segment>
              <SmartDetail object={user}
                mapping={mapping.map(row => ({ ...row, title: t(`users_page.${row.title}`) }))} />
            </Segment>

          </div>
        )}
      </div>
    );
  }

  onSuccess = () => {
    this.setState({
      isOpen: false
    }, () => {
      const {
        fetch,
        match
      } = this.props;

      fetch(match.params.id);
    });
  }
}
