import { compose, withProps, withState, lifecycle } from 'recompose';
import { withFormState } from 'firefly-hoc';
import { withT, withUser } from '../hoc';
import { LoginModal as Container } from '../container/LoginModal';

export const LoginModal = compose(
  withT,
  withUser,
  withState('loginModal', 'setLoginModal', false),
  withProps(props => ({
    ...props,
  })),
  lifecycle({
    componentWillMount() {
      const { props } = this;
      props.setLoginModal(props.user === null);
    }
  }),
  withFormState,
)(Container);
