import React from 'react';
import { Marker, InfoWindow } from 'react-google-maps';
import { compose, withState, lifecycle } from 'recompose';
import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { MinParty } from '../container/Parties';
import locationPoint from '../img/location-point-small.png';
import { writeLocalState } from '../utils/index';


const markersQuery = gql`{
  markers_closed @client
}`;


const Container = ({ party, infoOpened, setInfoOpened, isPartyPage }) => (
  <Marker position={party.location} icon={locationPoint} onClick={() => setInfoOpened(true)}>
    {infoOpened && <InfoWindow onCloseClick={() => setInfoOpened(false)}>
      <MinParty alwaysDisplaySingleDate={false} isPartyPage={isPartyPage} {...party}/>
    </InfoWindow>}
  </Marker>
);

export const PartyMarker = compose(
  graphql(markersQuery),
  withState('infoOpened', 'setInfoOpened', false),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { props } = this;

      // this trick is needed to make all InfoWindows closed when the user clicks on the map
      // (the corresponding mutation is bound to Map's onClick event (module/Map/Map.js))
      if (props.data.markers_closed && !prevProps.data.markers_closed) {
        props.setInfoOpened(false);
      }

      if (props.infoOpened && !prevProps.infoOpened) {
        writeLocalState({ markers_closed: false });
      }
    }
  })
)(Container);
