import React from 'react';
import { Transition, H1 } from 'firefly/component';
import {ButtonBack} from "../module/ButtonBack";

export const About = () => (
  <Transition>
    <ButtonBack goToPage="/"/>
    <H1>About service</H1>

    <div className="b-typeset">
      <p>
        You can buy or sell bitcoins on our website. We buy and sell only our bitcoins. Enter your credit card details
        and enter your wallet address. The transaction will begin immediately after the withdrawal of money from your
        card. The term of enrollment bitcoins does not depend on us. This can last from 1 second to 24 hours. On
        average, it is 10 minutes. We send bitcoin on priority transaction.
      </p>
    </div>
  </Transition>
);
