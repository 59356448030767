import { compose, withProps, branch, renderComponent } from 'recompose';
import { graphql } from 'react-apollo';
import { SpinnerGlobal } from 'firefly/component/Spinner';
import PropTypes from 'prop-types';
import { createParty, party, partyAdmin } from '../../query';
import { CreateEditParty } from './CreateEditParty';

const CreateParty = compose(
  graphql(createParty)
)(CreateEditParty);

const EditParty = compose(
  graphql(partyAdmin, {
    options: props => ({
      variables: {
        input: {
          party_id: props.partyId
        }
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(party),
  withProps(props => ({
    ...props,
    party: props.data.partyAdmin
  })),
  branch(props => props.data.loading || !props.party, renderComponent(SpinnerGlobal))
)(CreateEditParty);

if (PropTypes.number.required) {
  EditParty.propTypes = {
    partyId: PropTypes.number.required
  };
}

export {
  EditParty,
  CreateParty
};
