import { compose, withProps, branch, renderComponent } from 'recompose';
import { graphql } from 'react-apollo';
import { SpinnerGlobal } from 'firefly/component/Spinner';
import PropTypes from 'prop-types';
import { createFestival, festival, festivalAdmin } from '../../query';
import { CreateEditFestival } from './CreateEditFestival';

const CreateFestival = compose(
  graphql(createFestival)
)(CreateEditFestival);

const EditFestival = compose(
  graphql(festivalAdmin, {
    options: props => ({
      variables: {
        input: {
          festival_id: props.festivalId
        }
      },
      fetchPolicy: 'network-only'
    })
  }),
  graphql(festival),
  withProps(props => ({
    ...props,
    festival: props.data.festivalAdmin
  })),
  branch(props => props.data.loading || !props.festival, renderComponent(SpinnerGlobal))
)(CreateEditFestival);

EditFestival.propTypes = {
  festivalId: PropTypes.number.required
};

export {
  EditFestival,
  CreateFestival
};
