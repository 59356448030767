import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import * as Icons from 'react-feather';

const ASC = 'ASC';
const DESC = 'DESC';

export default class SortableTh extends Component {
  static propTypes = {
    callback: PropTypes.func.isRequired,
    children: PropTypes.any.isRequired,
    column: PropTypes.string.isRequired,
    order: PropTypes.string
  };

  static defaultProps = {
    order: null
  };

  orderColumn() {
    const {
      order
    } = this.props;

    if (order) {
      return order.charAt(0) === '-' ? order.slice(1) : order;
    }

    return null;
  }

  onChangeOrder = e => {
    e.preventDefault();

    this.props.callback(this.getNextOrder());
  };

  isDesc() {
    const { order } = this.props;

    return order && order.charAt(0) === '-';
  }

  isNull() {
    return this.props.order === null;
  }

  getNextOrder = () => {
    const {
      column
    } = this.props;

    if (this.isDesc()) {
      return null;
    }

    if (this.isNull()) {
      return column;
    }

    return `-${column}`;
  };

  isCurrent() {
    return this.orderColumn() === this.props.column;
  }

  getCurrentOrder() {
    const { order } = this.props;

    if (!order || !this.isCurrent()) {
      return null;
    }

    return order.charAt(0) === '-' ? DESC : ASC;
  }

  render() {
    const { children } = this.props;

    const cls = cx('b-sort', {
      'b-sort--asc': this.getCurrentOrder() === ASC,
      'b-sort--desc': this.getCurrentOrder() === DESC
    });

    return (
      <div className="b-pane" onClick={this.onChangeOrder}>
        <div>{children}</div>
        <Icons.ArrowDown className={cls}/>
      </div>
    );
  }
}
