import React from 'react';
import { Errors, FormRow, Segment, Transition } from 'firefly/component';
import { compose, withProps } from 'recompose';
import Header from '../../../../component/Header';
import { CitySelector } from "../../../../../module/CitySelector";
import { withCities, withUser } from '../../../../../hoc';
import { toastSuccess } from "../../../../../utils";
import { withAdminT } from "../../../../hoc";
import { withRouter } from "react-router-dom";
import { withFormState } from "firefly-hoc";
import { graphql } from "react-apollo";
import { testing } from "../../query";

const Container = ({ t, form, onChange, errors, testing, onSuccess }) => (
  <div>
    <Header pageTitle={t('pages.testing')} />
      <Transition>
        <Segment>
          <div className="b-contact-us__form__popover-picker">
            <p className="b-contact-us__form__popover-picker__label">
              {t('parties_page.create_edit.city')}
            </p>
            <CitySelector onChange={onChange('city_id', 'free')}
                          cityId={form.city_id}
                          className="b-filters__popover-picker__content"
            />
          </div>
          <Errors errors={errors.city_id} />
          <FormRow>
            <button onClick={async () => {
              const result = await testing({ variables: { input: { city_id: form.city_id } } });
              if (result) {
                onSuccess();
              }
            }} type="submit" className="b-button">
              {t('testing_settings_page.posting')}
            </button>
          </FormRow>
        </Segment>
      </Transition>
  </div>
);

export const Testing = compose(
  withAdminT,
  withCities,
  withUser,
  withRouter,
  withProps(props => ({
    ...props,
    onSuccess() {
      toastSuccess('Success!');
    }
  })),
  withFormState,
  graphql(testing, { name: "testing",
    options: props => ({
      variables: {
        city_id: Number(props.form)
      }
    })
  })
)(Container);