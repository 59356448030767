import React from 'react';
import { H1, H2, Transition } from 'firefly/component';
import { ButtonBack } from '../module/ButtonBack';
import { withT } from '../hoc';

export const Terms = withT(({ t }) => (
  <Transition>
    <ButtonBack goToPage="/"/>
    <H1>{t('Terms.title')}</H1>

    <div className="b-typeset">
      <p>{t('Terms.mainDesc')}<a href="https://w2dance.com" target={'_blank'}>https://w2dance.com</a> {t('Terms.reduction')}</p>
    </div>

    <H2>1.{t('Terms.1')}</H2>
    <div className="b-typeset">
      <p>1.1. {t('Terms.1_1')}</p>

      <p>1.2. {t('Terms.1_2')}</p>

      <p>1.3. {t('Terms.1_3')}</p>
    </div>

    <H2>2. {t('Terms.2')}</H2>
    <div className="b-typeset">
      <p>2.1. {t('Terms.2_1')}</p>

      <p>2.2. {t('Terms.2_2')}</p>

      <p>2.3. {t('Terms.2_3')}</p>

      <p>2.4. {t('Terms.2_4')}</p>

      <p>2.5. {t('Terms.2_5')}</p>

      <p>2.6. {t('Terms.2_6')}</p>

      <p>2.7. {t('Terms.2_7')}</p>
    </div>

    <H2>3. {t('Terms.3')}</H2>
    <div className="b-typeset">
      <p>3.1. {t('Terms.3_1')}</p>

      <p>3.2. {t('Terms.3_2')}</p>

      <p>3.3. {t('Terms.3_3')}</p>

      <p>3.4. {t('Terms.3_4')}</p>

      <p>{t('Terms.confirm')}</p>
    </div>
  </Transition>
));
