import React from 'react';
import { compose, lifecycle } from 'recompose';
import { H1 } from 'firefly/component';
import { withT } from '../hoc';
import { writeLocalState } from '../utils/index';
import { Registration as Component } from '../component';
import bg_top from '../img/events-bg/lg.png';
import {ButtonBack} from "../module/ButtonBack";

const RegistrationScreen = withT(({ t }) => (
  <div className="b-login">
    <ButtonBack goToPage="/"/>
    <H1 className="b-parties__header">{t('registration.registration')}</H1>
    <Component/>
  </div>
));

export const Registration = compose(
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(RegistrationScreen);
