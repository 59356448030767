import { compose, withProps } from 'recompose';
import { graphql } from 'react-apollo';
import { withFormState } from 'firefly-hoc';
import { suggest_partnership } from '../../query';
import { withT } from '../../hoc';
import { toastSuccess } from '../../utils/index';
import { SuggestPartnershipForm as Container } from '../../container/ContactUs';

export const SuggestPartnershipForm = compose(
  withT,
  graphql(suggest_partnership),
  withProps(props => ({
    ...props,
    callback: form => props.mutate({ variables: form }),
    onSuccess() {
      toastSuccess('Your request has been successfully sent');
      props.onClose();
    }
  })),
  withFormState
)(Container);
