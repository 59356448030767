import React from 'react';
import { compose, lifecycle, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { Festival as Component } from '../component/Festival';
import { withT } from '../hoc';
import { writeLocalState } from '../utils/index';
import bg_top from '../img/events-bg/lg.png';

const FestivalScreen = ({ t, onBack, id }) => (
  <div className="b-event-page">
    <div className="b-event-page__back" onClick={onBack}>
      {t('festivals.all_festivals')}
    </div>
    <Component id={id} />
  </div>
);

export const Festival = compose(
  withT,
  withRouter,
  withProps(props => ({
    ...props,
    id: props.match.params.id,
    onBack: () => (props.history.location.state || {}).isFestivalPage ? props.history.goBack() : props.history.push('/festival'),
  })),
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(FestivalScreen);
