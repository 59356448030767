export default [
  'Salsa',
  'Bachata',
  'Kizomba',
  'Hustle',
  'Merenge',
  'Timba',
  'Son',
  'Latina',
  'Reggaeton',
  'Zouk'
];
