import React from 'react';
import { compose, withProps, withState } from 'recompose';
import { Popover } from 'firefly/component';
import { withT } from '../../hoc';

const months = {
  0: {
    "id": null,
    "abbreviation": "Reset",
    "name_translations": {
      "en": "Reset",
      "ru": "Сбросить"
    }
  },
  1: {
    "id": 1,
    "abbreviation": "Jan",
    "name_translations": {
      "en": "January",
      "ru": "Январь"
    }
  },
  2: {
    "id": 2,
    "abbreviation": "Feb",
    "name_translations": {
      "en": "February",
      "ru": "Февраль"
    }
  },
  3: {
    "id": 3,
    "abbreviation": "Mar",
    "name_translations": {
      "en": "March",
      "ru": "Март"
    }
  },
  4: {
    "id": 4,
    "abbreviation": "Apr",
    "name_translations": {
      "en": "April",
      "ru": "Апрель"
    }
  },
  5: {
    "id": 5,
    "abbreviation": "May",
    "name_translations": {
      "en": "May",
      "ru": "Май"
    }
  },
  6: {
    "id": 6,
    "abbreviation": "Jun",
    "name_translations": {
      "en": "June",
      "ru": "Июнь"
    }
  },
  7: {
    "id": 7,
    "abbreviation": "Jul",
    "name_translations": {
      "en": "July",
      "ru": "Июль"
    }
  },
  8: {
    "id": 8,
    "abbreviation": "Aug",
    "name_translations": {
      "en": "August",
      "ru": "Август"
    }
  },
  9: {
    "id": 9,
    "abbreviation": "Sep",
    "name_translations": {
      "en": "September",
      "ru": "Сентябрь"
    }
  },
  10: {
    "id": 10,
    "abbreviation": "Oct",
    "name_translations": {
      "en": "October",
      "ru": "Октябрь"
    }
  },
  11: {
    "id": 11,
    "abbreviation": "Nov",
    "name_translations": {
      "en": "November",
      "ru": "Ноябрь"
    }
  },
  12: {
    "id": 12,
    "abbreviation": "Dec",
    "name_translations": {
      "en": "December",
      "ru": "Декабрь"
    }
  }
};

const getMonthName = (monthEntry, i18n) => monthEntry.name_translations[i18n.language.slice(0, 2).toLowerCase()] || monthEntry.abbreviation;

const Container = ({t, i18n, monthId, onChange, className, refStore}) => (
  <Popover ref={refStore.setRef('popover')} position="bottom" popperModifiers={{arrow: false}}>
    <div className={className}>
      {months[monthId] ? getMonthName(months[monthId], i18n) : t('parties.filters.any')}
    </div>
    <div>
      {Object.values(months).map((monthEntry, key) => (
        monthEntry.id !== monthId
        && (<div key={key} className="b-popover__content__item" onClick={() => onChange(monthEntry.id)}>
          {getMonthName(monthEntry, i18n)}
        </div>)
      ))}
    </div>
  </Popover>
);

function RefStore() {
  this.refs = {};
  this.setRef = name => ref => {
    this.refs[name] = ref;
  };
  this.getRef = name => this.refs[name];
}

export const MonthSelector = compose(
  withT,
  withState('refStore', 'setRefStore', new RefStore()),
  withProps(props => ({
    ...props,
    onChange(value) {
      props.refStore.getRef('popover').close();
      props.onChange(value);
    }
  }))
)(Container);
