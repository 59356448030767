import React from 'react';
import { compose, withState, lifecycle } from 'recompose';
import { Marker, InfoWindow } from 'react-google-maps';
import { getCurrentPositionAsync } from '../../utils/index';
import { withT } from '../../hoc';


const Container = ({ t, latLng, infoOpened, setInfoOpened }) => (
  latLng && <Marker position={latLng} onClick={() => setInfoOpened(true)}>
    {infoOpened && <InfoWindow onCloseClick={() => setInfoOpened(false)}>
      <p>{t('you_are_here')}</p>
    </InfoWindow>}
  </Marker>
);

export const CurrentLocationMarker = compose(
  withT,
  withState('infoOpened', 'setInfoOpened', false),
  withState('latLng', 'setLatLng', null),
  lifecycle({
    async componentDidMount() {
      const { coords: { latitude: lat, longitude: lng } } = await getCurrentPositionAsync();
      this.props.setLatLng({ lat, lng });
    }
  })
)(Container);
