import React from 'react';
import { FormRow, Segment, SpinnerOverlay, Group, Transition } from 'firefly/component';
import Header from '../../../../component/Header';

const Container = ({ t, errors, onSubmit, formLoading, form, onChange }) => (
  <SpinnerOverlay show={formLoading}>
    <Header pageTitle={t('pages.basic_settings')} />
    <form noValidate={true} onSubmit={onSubmit}>
      <Transition>
        <Segment>
          <Group
            errors={errors}
            loading={formLoading}
            values={form}
            onChange={onChange}
            fields={[
              {
                name: 'google_analytics_id',
                label: t('basic_settings_page.google_analytics_id'),
                type: 'text',
                required: false
              },
              {
                name: 'vk_client_id',
                label: t('basic_settings_page.vk_client_id'),
                type: 'text',
                required: false
              },
              {
                name: 'vk_client_secret',
                label: t('basic_settings_page.vk_client_secret'),
                type: 'text',
                required: false
              },
              {
                name: 'fb_client_id',
                label: t('basic_settings_page.fb_client_id'),
                type: 'text',
                required: false
              },
              {
                name: 'fb_client_secret',
                label: t('basic_settings_page.fb_client_secret'),
                type: 'text',
                required: false
              },
              {
                name: 'fb_app_id',
                label: 'fb_app_id',
                type: 'text',
                required: false
              },
              {
                name: 'leftBorder',
                label: 'leftBorder',
                type: 'text',
                required: false
              },
              {
                name: 'rightBorder',
                label: 'rightBorder',
                type: 'text',
                required: false
              },
              {
                name: 'hashtag_social',
                label: t('basic_settings_page.hashtag_social'),
                type: 'textarea',
                required: false
              },
            ]}/>

          <FormRow>
            <button type="submit" disabled={formLoading} className="b-button">
              {t('basic_settings_page.save')}
            </button>
          </FormRow>
        </Segment>
      </Transition>
    </form>
  </SpinnerOverlay>
);

export default Container;
