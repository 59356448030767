import { compose, withProps } from 'recompose';
import { graphql } from 'react-apollo';
import { citiesFestival } from '../query';

export const withCitiesFestivals = compose(
  graphql(citiesFestival),
  withProps(props => ({
    ...props,
    cities: (props.data.citiesFestival || []).reduce(
      (accumulator, value) => ({ ...accumulator, [value.id]: value }),
      {}
    )
  })),
);
