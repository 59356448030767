import gql from 'graphql-tag';

export const publicSettings = gql`
  query publicSettings {
    publicSettings {
      google_analytics_id
      vk_client_id
      fb_client_id
      fb_app_id
    }
  }
`;
