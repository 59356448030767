import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import {compose, withProps} from 'recompose';
import { ChangeLanguage } from '../module/ChangeLanguage';
import { Popover } from 'firefly/component';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { Logo } from './Logo';
import { withT, withUser } from '../hoc/index';
import { writeLocalState } from '../utils/index';
import hamburgerIcon from '../img/hamburger.png';
import closeIcon from '../img/close.png';
import { hasPermission, logout } from '../utils/user';
import consts from '../consts';


const headerState = gql`{
  is_menu_open @client
 }
`;


const Container = ({ t, user, updateUser }) => (
  <Query query={headerState}>
    {({ data: { is_menu_open } = {} }) => (
      <div className="b-header">
        <div className="b-header__leftPart b-header__leftPart--lg">
          <NavLink exact to="/" className="link">{t('menu.home')}</NavLink>
          {/* <NavLink exact to="/about" className="link">{t('menu.about')}</NavLink> */}
          <NavLink exact to="/parties" className="link">{t('menu.events')}</NavLink>
          <NavLink exact to="/festivals" className="link">{t('menu.festivals')}</NavLink>
        </div>
        <div className="b-header__leftPart b-header__leftPart--md">
          {!is_menu_open ? (
            <div onClick={() => writeLocalState({ is_menu_open: true })} className="b-header__hamburger">
              <img className="b-header__hamburger__icon" src={hamburgerIcon} alt=""/>
              <p>{t('menu.menu')}</p>
            </div>
          ) : (
            <div onClick={() => writeLocalState({ is_menu_open: false })} className="b-header__hamburger">
              <img className="b-header__hamburger__icon" src={closeIcon} alt=""/>
              <p>{t('menu.close')}</p>
            </div>
          )}
        </div>

        <Logo className="b-header__logo"/>

        <div className="b-header__rightPart b-header__rightPart--lg">
          <NavLink exact to="/contacts" className="link">{t('menu.contact_us')}</NavLink>
          {/*<NavLink exact to="/development" className="link">{t('menu.mobile_app')}</NavLink>*/}
          <ChangeLanguage refName="change_language"/>
          {!user
            ? (<NavLink exact to="/login" className="link">{t('menu.login')}</NavLink>)
            : (<Popover popperModifiers={{ arrow: false }}>
              <div className="link b-header__rightPart__email">{user.first_name
                ? (<div>{user.last_name ? user.first_name + ' ' + user.last_name : user.first_name }</div>)
                : (user.email)
              }</div>
              <div>
                {hasPermission(user, consts.USER_HAS_EVENTS)
                && (
                  <Link to="/admin/parties" className="b-popover__content__item">
                    {t('menu.admin_panel')}
                  </Link>
                )}
                {
                  <div onClick={logout(updateUser)} className={'b-popover__content__item'}>
                    {t('menu.logout')}
                  </div>
                }
              </div>
            </Popover>)}
        </div>

        <div className="b-header__rightPart b-header__rightPart--md">
          <ChangeLanguage refName="change_language_mobile"/>
        </div>
      </div>
    )}
  </Query>
);

Container.propTypes = {

  // from HOCs
  'user': PropTypes.object.isRequired,
  'updateUser': PropTypes.func.isRequired,
  't': PropTypes.func.isRequired,
};


export const Header = compose(
  withT,
  withUser,
  withProps(props => ({
    ...props
  }))
)(Container);
