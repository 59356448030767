import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { companySettings } from './query';

export const withSettings = compose(
  graphql(companySettings),
  withProps(props => ({
    ...props,
    settings: props.data.settings || {}
  }))
);
