import React from 'react';
import { Group, Button } from 'firefly/component';
import { LoginModal } from '../../component/LoginModal';

export const SuggestPartnershipForm = ({ t, onSubmit, onChange, form, formLoading, errors }) => (
  <form onSubmit={onSubmit} className="b-contact-us__form">
    <LoginModal/>

    <Group
      errors={errors}
      loading={formLoading}
      values={form}
      onChange={onChange}
      fields={[
        {
          name: 'name',
          label: t('contact_us.suggest_partnership_modal.your_name'),
          type: 'text',
          required: false
        },
        {
          name: 'contact_information',
          label: t('contact_us.suggest_partnership_modal.contact_information'),
          type: 'text',
          required: false
        },
        {
          name: 'letter',
          label: t('contact_us.suggest_partnership_modal.your_offer'),
          type: 'textarea',
          required: false
        }
      ]}
    />

    <Button className="b-button__main">{t('contact_us.suggest_partnership_modal.submit')}</Button>
  </form>
);
