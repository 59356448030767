import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Divider, FormRow } from 'firefly/component';
import consts from '../../../../../consts';
import { hasPermission } from '../../../../../utils/user';
import BaseFilterForm from '../../../../component/FilterForm';
import { CityCheckboxSelector } from '../../../../../module/CityCheckboxSelector';


export default class FilterForm extends BaseFilterForm {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      form,
    } = this.state;
    const { t, allAllowedCities, user } = this.props;

    const renderCityPicker = () => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name b-admin-filters__group__name-white">{t('festivals_page.filters.cities')}</span>
        <CityCheckboxSelector classNameItem="b-admin-filters__group__name-white b-popover__content__item__theme-dark" classNameGroup="b-admin-filters__popover__theme-dark" allAllowedCities={allAllowedCities} onChange={this.change('cities')} value={form.cities || []} />
      </div>
    );

    return (
      <form onSubmit={this.submit} className="b-admin-filters__popover b-admin-filters__popover__theme-dark">
        <FormRow>
          {
            hasPermission(user, consts.USER_HAS_CITIES) && allAllowedCities.length > 1
            && renderCityPicker()
          }
        </FormRow>
        <FormRow>
          <div className="b-page-admin--width-full b-page-admin--flex--center">
            <Button className="b-admin-filters__form b-button--flex--center b-button--color-white" type="submit">
              {t('festivals_page.apply')}
            </Button>
          </div>
          <Divider modifiers='small' />
          <button onClick={this.onReset} className='b-link b-link--center' type="button">
            {t('festivals_page.reset_filters')}
          </button>
        </FormRow>
      </form>
    );
  }
}
