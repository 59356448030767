import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Group, Button, H2, Popover, Errors } from 'firefly/component';
// eslint-disable-next-line import/no-extraneous-dependencies
// eslint-disable-next-line import/no-extraneous-dependencies
import { DateRangePicker } from 'firefly/component/DateTime';

import moment from 'moment';

import {formatDate, getFullImgUrl} from '../../../../../utils/index';
import { CitySelector } from '../../../../../module/CitySelector';
import { Map as GoogleMap } from '../../../../../module/Map';
import { CheckboxGroup } from '../../../../../module/CheckboxGroup';
import { FilePreviewInput } from '../../../../../module/FilePreviewInput';
import allFestivalTypes from '../../../../../component/Festivals/festivalTypesMock';
import Sidebar from "../../../../component/Sidebar";
import hamburgerIcon from "../../../../../img/hamburger.png";
import defaultLogo from '../../../../../img/default-party-logo.png';
import {ChangeLanguage} from "../../../../component/ChangeLanguage";
import consts from "../../../../../consts";
import {hasPermission} from "../../../../../utils/user";

const festivalListToString = (festivalList, t) => festivalList.length ? festivalList.join(', ') : t('not_chosen');
const formatDatePeriod = (since, till) => (!since || !till) ? '' : `${since} - ${till}`;

export const CreateEditFestivalContainer = (
  {
    t,
    onSubmit,
    onChange,
    form,
    formLoading,
    errors,
    cities,
    is_edit,
    allAllowedCities,
    setMenuOpened,
    menuOpened,
    onSelected,
    refStore,
    user
  }
) => {
  const pay = [
    {
      name: 'price',
      label:  <p>{t('pay.price')}</p>,
      type: 'number',
      required: false
    }
  ];
  const descriptionFields = [
    {
      name: 'name',
      label: t('festivals_page.create_edit.name'),
      type: 'text',
      required: false
    },
    {
      name: 'description',
      label: t('festivals_page.create_edit.description'),
      type: 'textarea',
      required: false
    }
  ];
  const linksFields = [
    {
      name: 'vk_link',
      label: t('festivals_page.create_edit.vk_link'),
      type: 'text',
      required: false
    },
    {
      name: 'fb_link',
      label: t('festivals_page.create_edit.fb_link'),
      type: 'text',
      required: false
    },
    {
      name: 'tg_link',
      label: t('festivals_page.create_edit.tg_link'),
      type: 'text',
      required: false
    },
    {
      name: 'website_link',
      label: t('festivals_page.create_edit.website_link'),
      type: 'text',
      required: false
    }
  ];
  const addressFields = [
    {
      name: 'address',
      label: t('festivals_page.create_edit.address'),
      type: 'text',
      required: false
    }
  ];

  const renderPay = () => (
    <div>
      <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={pay}
      />

      <div className="b-admin-filters__width--fit-content">
        <Popover ref={refStore.setRef('currency')} popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            { (form.currency && consts.CURRENCY[form.currency])
            || consts.CURRENCY.RUB
            }
          </div>
          <div>
            {
              Object.keys(consts.CURRENCY).map(
                (value, index) => (<div onClick={() => {
                  onSelected('currency')
                  onChange('currency', 'free')(value)
                }}
                                        className="b-popover__content__item"
                                        key={index}>
                  {value}
                </div>)
              )
            }
          </div>
        </Popover>
        <Errors errors={errors.currency} />
      </div>
    </div>
  )

  const renderMap = () => (
    <div>
      <p className="b-contact-us__form__popover-picker__label">
        {t('festivals_page.create_edit.pick_location')}
      </p>
      <GoogleMap center={form.location || cities[form.city_id].center_location}
                 onCenterChange={onChange('location', 'free')}
                 scrollwheel={false}
                 locationPicker={true} />
      <Errors errors={errors.location} />

    </div>
  );

  const renderDancePicker = () => (
    <div className="b-admin-filters__width--fit-content">
      <Popover position="bottom" popperModifiers={{ arrow: false }}>
        <div className="b-filters__popover-picker__content">
          {festivalListToString(form.dance_styles || [], t)}
        </div>
        <div>
          <CheckboxGroup
            value={form.dance_styles || []}
            onChange={onChange('dance_styles', 'free')}
            allPossibleValues={allFestivalTypes}
          />
        </div>
      </Popover>
    </div>
  );

  const renderImagePicker = () => (
    <div className="b-contact-us__form__centered-content">
      <FilePreviewInput
        name="logo"
        label={t('festivals_page.create_edit.logo')}
        description={ 'test' }
        onChange={onChange}
        errors={errors}
        defaultImage={form.logo_path ? getFullImgUrl(form.logo_path) : defaultLogo} />
      <p className="b-contact-us__form__centered-content__description">
        {t('festivals_page.create_edit.logo_upload')}
      </p>
      <p className="b-contact-us__form__centered-content__description">
        {t('festivals_page.create_edit.logo_description')}
      </p>
      <p className="b-contact-us__form__centered-content__description">
        {t('festivals_page.create_edit.logo_info')}
      </p>
    </div>
  );

  const renderDatePicker = () => (
    <div className="b-contact-us__form__row-group">

      <div className="b-contact-us__form__popover-picker">
        <p className="b-contact-us__form__popover-picker__label">
          {t('festivals_page.create_edit.date_range')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            {form.dateRange !== undefined && (form.dateRange || [])[1] !== null
              ? typeof form.dateRange[1] === "string"
                ? formatDatePeriod(form.dateRange[0], form.dateRange[1])
                : formatDatePeriod(formatDate(moment(form.dateRange[0])), formatDate(moment(form.dateRange[1])))
              : t('festivals_page.create_edit.not_chosen')}
          </div>
          <DateRangePicker
            minDate={moment().startOf('day').toDate()}
            maxDate={moment().add(1, 'year').endOf('day').toDate()}
            shortcuts={false}
            onChange={onChange('dateRange', 'date')}
            contiguousCalendarMonths={false}
          />
        </Popover>
        <Errors errors={errors.date_range} />
      </div>
    </div>
  );

  const renderCityPicker = () => (
    <div className="b-contact-us__form__popover-picker">
      <p className="b-contact-us__form__popover-picker__label">
        {t('festivals_page.create_edit.city')}
      </p>
      <CitySelector onlyAllowedCities={true}
                    allowedCities={allAllowedCities}
                    onChange={onChange('city_id', 'free')}
                    cityId={form.city_id}
                    className="b-filters__popover-picker__content"
      />

      <Errors errors={errors.city_id} className="test" />
    </div>
  );

  return <>
    <div className="b-header__leftPart b-header__leftPart--md b-sidebar__mobile-enabled">
      {!menuOpened ? (
        <div onClick={() => { setMenuOpened(true) }} className="b-header__hamburger">
          <Sidebar mobileMenuOpened={menuOpened} />
          <img className="b-header__hamburger__icon" src={hamburgerIcon} alt=""/>
        </div>
      ) : (
        <div onClick={() => { setMenuOpened(false) }} className="b-sidebar__mobile__bg_open">
          <Sidebar mobileMenuOpened={menuOpened} />
        </div>
      )}
    </div>
    <div className="b-header-admin__part">
      <div>
        <ChangeLanguage/>
      </div>
    </div>
    <form onSubmit={onSubmit} className="b-contact-us__form">
      <H2 className="b-contact-us__form__header">
        {is_edit ? t('festivals_page.create_edit.header_edit') : t('festivals_page.create_edit.header_create')}
      </H2>

      <p className="b-contact-us__form__subheader">{t('festivals_page.create_edit.basic_info')}</p>
      { renderImagePicker() }
      <br/>

      <p className="b-filters__popover-picker__label">
        {t('festivals_page.create_edit.dance_style')}
      </p>
      { renderDancePicker() }
      <br/>

      <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={descriptionFields}
      />

      <p className="b-contact-us__form__subheader">{t('festivals_page.create_edit.links')}</p>
      <div>
        <Group
          errors={errors}
          loading={formLoading}
          values={form}
          onChange={onChange}
          className="checkbox"
          fields={linksFields} />
      </div>
      <br/>

      <p className="b-contact-us__form__subheader">{t('festivals_page.create_edit.time')}</p>
      <br/>

      { renderDatePicker() }

      <p className="b-contact-us__form__subheader">{t('pay.title')}</p>
      { renderPay() }

      <p className="b-contact-us__form__subheader">{t('festivals_page.create_edit.location')}</p>
      { renderCityPicker() }

      <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={addressFields}
      />

      {form.city_id && renderMap()}
      <br/>

      {hasPermission(user, consts.USER_HAS_EVENTS) && (
        <>
          <p className="b-contact-us__form__subheader">{t('output_priority')}</p>
          <Group
            errors={errors}
            loading={formLoading}
            values={form}
            onChange={onChange}
            fields={[{
              name: 'output_priority',
              label: t('output_priority'),
              type: 'number',
              required: false,
              help: t('festivals_page.create_edit.help_output_priority')
            }]}
          />
        </>
      )}

      <Button>{t(`festivals_page.create_edit.${is_edit ? 'submit_edit' : 'submit_create'}`)}</Button>
    </form>
  </>;
};

CreateEditFestivalContainer.propTypes = {
  't': PropTypes.func.isRequired,
  'onSubmit': PropTypes.func.isRequired,
  'onChange': PropTypes.func.isRequired,
  'form': PropTypes.object.isRequired,
  'formLoading': PropTypes.bool.isRequired,
  'errors': PropTypes.object.isRequired,
  'cities': PropTypes.object.isRequired,
  'is_edit': PropTypes.bool.isRequired,
  'allAllowedCities': PropTypes.arrayOf(PropTypes.number).isRequired,
};
