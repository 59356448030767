import {compose, withProps, withState} from "recompose";
import { withRouter } from "react-router-dom";
import { withT } from "../hoc";
import { graphql } from "react-apollo";
import { restore } from "../query";
import { PasswordRecovery as Container } from '../container/PasswordRecovery';
import { withFormState } from "firefly-hoc";

export const PasswordRecovery =  compose(
  withRouter,
  withT,
  graphql(restore),
  withState('showSuccessMessage', 'setShowSuccessMessage', false),
  withProps(props => ({
    ...props,
    callback: form => props.mutate({
      variables: { email: form.email }
    }),
    onSuccess() {
      props.setShowSuccessMessage(true);
    },
  })),
  withFormState,
)(Container);