import React from 'react';
import { compose, withProps, withState } from 'recompose';
import { Popover } from 'firefly/component';
import { withT } from '../../hoc';

const Container = ({t, yearChosen, listYears=[new Date().getFullYear()], onChange, className, refStore}) => (
  <Popover ref={refStore.setRef('popover')} position="bottom" popperModifiers={{arrow: false}}>
    <div className={className}>
      {yearChosen ? yearChosen : t('parties.filters.any')}
    </div>
    <div>
      <div key={0} className="b-popover__content__item" onClick={() => onChange(null)}>
        {t('reset')}
      </div>
      {listYears.map((year) => (
        year !== yearChosen
        && (<div key={year} className="b-popover__content__item" onClick={() => onChange(year)}>
          {year}
        </div>)
      ))}
    </div>
  </Popover>
);

function RefStore() {
  this.refs = {};
  this.setRef = name => ref => {
    this.refs[name] = ref;
  };
  this.getRef = name => this.refs[name];
}

export const YearSelector = compose(
  withT,
  withState('refStore', 'setRefStore', new RefStore()),
  withProps(props => ({
    ...props,
    onChange(value) {
      props.refStore.getRef('popover').close();
      props.onChange(value);
    }
  }))
)(Container);