import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Divider, FormRow, Group, Popover } from 'firefly/component';
import consts from '../../../../../consts';
import { hasPermission } from '../../../../../utils/user';
import BaseFilterForm from '../../../../component/FilterForm';
import { CityCheckboxSelector } from '../../../../../module/CityCheckboxSelector';
import { CheckboxGroup } from '../../../../../module/CheckboxGroup';
import partyStyles from '../../../../../component/Parties/partyTypesMock';
import moment from 'moment';

const getAllWeekdays = () => [1, 2, 3, 4, 5, 6, 7].map(num => moment.weekdaysShort(num));

export default class FilterForm extends BaseFilterForm {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      form,
    } = this.state;
    const { t, allAllowedCities, user } = this.props;

    const partyStatusFields = [
      {
        name: 'disabled',
        label: t('parties_page.create_edit.disabled'),
        type: 'checkbox',
        required: false
      },
      {
        name: 'cancelled',
        label: t('parties_page.create_edit.cancelled'),
        type: 'checkbox',
        required: false
      },
    ];

    const partyTypes = Object.keys(consts.TYPES_OF_PARTIES);
    const partyTypesLabelsMap = {
      'INDOOR': t('parties_page.create_edit.types_of_parties.indoor'),
      'OUTDOOR': t('parties_page.create_edit.types_of_parties.outdoor'),
    };

    const renderSelectedCheckboxGroupComponent = (propertyName, valuesLabelsMap) => {
      if (propertyName === 'dance_styles' || propertyName === 'days_of_week') {
        return (form[propertyName] || []).length > 0 ? form[propertyName].join(', ') : t('not_chosen');
      } else {
        const translateName = (form[propertyName] || []).map(value =>  {
          return valuesLabelsMap[value];
        });
        return (translateName || []).length > 0 ? translateName.join(', ') : t('not_chosen');
      }
    };

    const renderSelectedCheckboxGroup = (fields) => {
      const selected = fields.filter(value => {
        return form[value.name];
      });
      const translateSelected = Object.values(selected).map(select => {
        return select.label;
      });
      return (translateSelected || []).length > 0 ? translateSelected.join(', ') : t('not_chosen');
    };

    const renderCityPicker = () => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name-white">{t('parties_page.filters.cities')}</span>
        <CityCheckboxSelector classNameItem="b-admin-filters__group__name-white b-popover__content__item__theme-dark" classNameGroup="b-admin-filters__popover__theme-dark" allAllowedCities={allAllowedCities} onChange={this.change('cities')} value={form.cities || []} />
      </div>
    );

    const renderCheckboxGroupComponent = (
      { propertyName, validValues, valuesLabelsMap, label } = {}
    ) => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name-white">{label}</span>
        <Popover>
          <div className="b-popover__content__item__theme-dark b-filters__popover-picker__content__admin b-popover__content__item b-admin-filters__group__name-white">
            {renderSelectedCheckboxGroupComponent(propertyName, valuesLabelsMap)}
          </div>
          <div className="b-admin-filters__popover__theme-dark b-checkbox-group">
            <CheckboxGroup
              value={form[propertyName] || []}
              onChange={value => this.change(propertyName)({ value })}
              allPossibleValues={validValues}
              valuesLabelsMap={valuesLabelsMap}
            />
          </div>
        </Popover>
      </div>
    );

    const renderCheckboxGroup = ({ fields, label } = {}) => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name-white">{label}</span>
        <Popover>
          <div className="b-filters__popover-picker__content__admin b-popover__content__item b-popover__content__item__theme-dark b-admin-filters__group__name-white">
            {renderSelectedCheckboxGroup(fields)}
          </div>
          <div className="b-admin-filters__group__checkbox-group b-admin-filters__popover__theme-dark">
            <Group
              values={form}
              onChange={this.change}
              className="checkbox"
              fields={fields} />
          </div>
        </Popover>

      </div>
    );

    return (
      <form onSubmit={this.submit} className="b-admin-filters__popover b-admin-filters__popover__theme-dark">
        <FormRow>
          {
            renderCheckboxGroupComponent({
              propertyName: 'days_of_week',
              validValues: getAllWeekdays(),
              valuesLabelsMap: getAllWeekdays(),
              label: t('parties_page.create_edit.days_of_week')
            })
          }
          {
            renderCheckboxGroupComponent({
              propertyName: 'partyType',
              validValues: partyTypes,
              valuesLabelsMap: partyTypesLabelsMap,
              label: t('parties_page.filters.party_type')
            })
          }
          { renderCheckboxGroup({ property: form, fields: partyStatusFields, label: t('parties_page.filters.party_status') }) }
          {
            hasPermission(user, consts.USER_HAS_CITIES) && allAllowedCities.length > 1
            && renderCityPicker()
          }
          {
            renderCheckboxGroupComponent({
              propertyName: 'dance_styles',
              validValues: partyStyles,
              label: t('parties_page.filters.dance_styles'),
            })
          }
        </FormRow>
        <FormRow>
          <div className="b-page-admin--width-full b-page-admin--flex--center">
            <Button className="b-admin-filters__form b-button--flex--center b-button--color-white" type="submit">
              {t('parties_page.apply')}
            </Button>
          </div>
          <Divider modifiers='small' />
          <button onClick={this.onReset} className='b-link b-link--center' type="button">
            {t('parties_page.reset_filters')}
          </button>
        </FormRow>
      </form>
    );
  }
}
