import { withHandlers, withState, compose, lifecycle } from 'recompose';
import {
  safeInvoke,
  isAxiosError,
  isGraphQLError,
  getAxiosErrors,
  getGraphQLErrors
} from 'firefly/component';

export const getAutoDetectValue = e => {
  if (e instanceof Date) {
    return e;
  } else if (e instanceof Object && typeof e.target !== 'undefined') {
    const { target } = e;
    const type = target.getAttribute('type');

    let { value } = target;

    if (type === 'checkbox') {
      return target.checked;
    } else if (type === 'file') {
      return target.getAttribute('multiple')
        ? target.files
        : target.files[0];
    } else {
      return value;
    }
  } else if (typeof e.target === 'undefined') {
    return e.value;
  } else {
    console.warn('Unknown value type');
  }
};

export const getValue = (type, e) => {
  if (type) {
    switch (type) {
      case 'wysiwyg':
      case 'ckeditor':
      case 'tinymce':
      case 'date-range':
      case 'daterange':
      case 'datetime':
      case 'date':
      case 'free':
        return e;

      case 'checkbox':
      case 'radio':
        return e.target.checked;

      case 'file':
        return e.target.getAttribute('multiple')
          ? e.target.files
          : e.target.files[0];

      case 'react-select':
      case 'reactselect':
        return e.value;

      case 'text':
      case 'textarea':
      case 'email':
      case 'search':
      default:
        return e.target.value;
    }
  }

  return getAutoDetectValue(e);
};

export const withFormState = compose(
  withState('form', 'setForm', {}),
  withState('formLoading', 'setFormLoading', false),
  withState('errors', 'setErrors', {}),
  lifecycle({
    componentDidMount() {
      const {
        setForm,
        initialForm
      } = this.props;

      setForm(initialForm || {});
    }
  }),
  withHandlers({
    onChange: props => (name, type = 'text') => e => {
      props.setForm({
        ...props.form,
        [name]: getValue(type, e)
      });
    },
    onSubmit: props => e => {
      e.preventDefault();
      e.stopPropagation();

      props.setFormLoading(true);
      props.callback(props.form).then(response => {
        props.setFormLoading(false);
        props.setErrors({});
        safeInvoke(props.onSuccess, { response, form: props.form })
      }).catch(e => {
        props.setFormLoading(false);

        if (isAxiosError(e)) {
          props.setErrors(getAxiosErrors(e));
        } else if (isGraphQLError(e)) {
          const errors = getGraphQLErrors(e);
          props.setErrors(errors);
          safeInvoke(props.onValidationError, errors)
        } else {
          safeInvoke(props.onError, e);
        }
      });
    }
  })
);
