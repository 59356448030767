import React from 'react';
import PropTypes from 'prop-types';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Group, Button, H2, Popover, Errors } from 'firefly/component';
// eslint-disable-next-line import/no-extraneous-dependencies
import { TimePicker } from 'firefly/component/DateTime/TimePicker';
// eslint-disable-next-line import/no-extraneous-dependencies
import { DatePicker } from 'firefly/component/DateTime';

import moment from 'moment';
import consts from '../../../../../consts';

import { formatDate, getFullImgUrl } from '../../../../../utils/index';
import { CitySelector } from '../../../../../module/CitySelector';
import { Map as GoogleMap } from '../../../../../module/Map';
import { CheckboxGroup } from '../../../../../module/CheckboxGroup';
import { FilePreviewInput } from '../../../../../module/FilePreviewInput';
import allPartyTypes from '../../../../../component/Parties/partyTypesMock';
import Sidebar from "../../../../component/Sidebar";
import hamburgerIcon from "../../../../../img/hamburger.png";
import defaultLogo from "../../../../../img/default-party-logo.png";
import rubIcon from "../../../../../img/currencies/rub-gold-color.svg";
import {ChangeLanguage} from "../../../../component/ChangeLanguage";
import {hasPermission} from "../../../../../utils/user";

const getAllWeekdays = () => [1, 2, 3, 4, 5, 6, 7].map(num => moment.weekdaysShort(num));
const partyListToString = (partyList, t) => partyList.length ? partyList.join(', ') : t('not_chosen');

export const CreateEditPartyContainer = (
  {
    t,
    onSubmit,
    onChange,
    form,
    formLoading,
    errors,
    cities,
    is_edit,
    allAllowedCities,
    setMenuOpened,
    menuOpened,
    user
  }
) => {
  const pay = [
    {
      name: 'price',
      label: <p>{t('pay.price')}<img style={{ width: '0.7rem' }} className="b-price--icon" src={rubIcon} alt=""/></p>,
      type: 'number',
      required: false
    }
  ];
  const descriptionFields = [
    {
      name: 'name',
      label: t('parties_page.create_edit.name'),
      type: 'text',
      required: false
    },
    {
      name: 'description',
      label: t('parties_page.create_edit.description'),
      type: 'textarea',
      required: false
    }
  ];
  const linksFields = [
    {
      name: 'vk_link',
      label: t('parties_page.create_edit.vk_link'),
      type: 'text',
      required: false
    },
    {
      name: 'fb_link',
      label: t('parties_page.create_edit.fb_link'),
      type: 'text',
      required: false
    },
    {
      name: 'tg_link',
      label: t('parties_page.create_edit.tg_link'),
      type: 'text',
      required: false
    },
    {
      name: 'website_link',
      label: t('parties_page.create_edit.website_link'),
      type: 'text',
      required: false
    }
  ];
  const addressFields = [
    {
      name: 'address',
      label: t('parties_page.create_edit.address'),
      type: 'text',
      required: false
    }
  ];
  const statusFields = [
    {
      name: 'disabled',
      label: t('parties_page.create_edit.disabled'),
      type: 'checkbox',
      help: t('parties_page.create_edit.disabled_help'),
      required: false
    },
    {
      name: 'cancelled',
      label: t('parties_page.create_edit.cancelled'),
      type: 'checkbox',
      help: t('parties_page.create_edit.cancelled_help'),
      required: false
    }
  ];

  const renderMap = () => (
    <div>
      <p className="b-contact-us__form__popover-picker__label">
        {t('parties_page.create_edit.pick_location')}
      </p>
      <GoogleMap center={form.location || cities[form.city_id].center_location}
        onCenterChange={onChange('location', 'free')}
        scrollwheel={false}
        locationPicker={true} />
      <Errors errors={errors.location} />

    </div>
  );

  const renderDancePicker = () => (
    <div className="b-admin-filters__width--fit-content">
      <Popover position="bottom" popperModifiers={{ arrow: false }}>
        <div className="b-filters__popover-picker__content">
          {partyListToString(form.dance_styles || [], t)}
        </div>
        <div>
          <CheckboxGroup
            value={form.dance_styles || []}
            onChange={onChange('dance_styles', 'free')}
            allPossibleValues={allPartyTypes}
          />
        </div>
      </Popover>
    </div>
  );

  const renderImagePicker = () => (
    <div className="b-contact-us__form__centered-content">
      <FilePreviewInput
        name="logo"
        label={t('parties_page.create_edit.logo')}
        description={ 'test' }
        onChange={onChange}
        errors={errors}
        defaultImage={form.logo_path ? getFullImgUrl(form.logo_path) : defaultLogo} />
      <p className="b-contact-us__form__centered-content__description">
        {t('parties_page.create_edit.logo_upload')}
      </p>
      <p className="b-contact-us__form__centered-content__description">
        {t('parties_page.create_edit.logo_description')}
      </p>
      <p className="b-contact-us__form__centered-content__description">
        {t('parties_page.create_edit.logo_info')}
      </p>
    </div>
  );

  const renderDatePicker = () => (
    <div className="b-contact-us__form__row-group">
      {form.is_one_time_event && <div className="b-contact-us__form__popover-picker">
        <p className="b-contact-us__form__popover-picker__label">
          {t('parties_page.create_edit.date')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            {form.single_date ? formatDate(moment(form.single_date)) : t('parties_page.create_edit.not_chosen')}
            <Errors errors={errors.single_date} />
          </div>
          <DatePicker
            minDate={moment().toDate()}
            shortcuts={false}
            onChange={onChange('single_date', 'date')}
          />
        </Popover>
      </div>}

      {!form.is_one_time_event && <div className="b-contact-us__form__popover-picker">
        <p className="b-contact-us__form__popover-picker__label">
          {t('parties_page.create_edit.days_of_week')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            {form.days_of_week ? form.days_of_week : t('parties_page.create_edit.not_chosen')}
          </div>
          <div>
            {
              getAllWeekdays().map(
                (value, index) =>
                  (<div onClick={() => onChange('days_of_week', 'free')(value)}
                        className="b-popover__content__item"
                        key={index}>
                  {value}
                </div>)
              )
            }
          </div>
        </Popover>
        <Errors errors={errors.days_of_week} />
      </div>}

      <div className="b-contact-us__form__popover-picker">
        <p className="b-contact-us__form__popover-picker__label">
          {t('parties_page.create_edit.start_time')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            {form.start_time
              ? moment(form.start_time).format('HH:mm')
              : moment(new Date(0, 0, 0, 0, 0, 0, 0)).format('HH:mm')}
          </div>
          <TimePicker
            value={form.start_time
              ? moment(form.start_time).toDate()
              : new Date(0, 0, 0, 0, 0, 0, 0)}
            onChange={onChange('start_time', 'free')}
          />
        </Popover>
        <Errors errors={errors.start_time} />
      </div>

      <div className="b-contact-us__form__popover-picker">
        <p className="b-contact-us__form__popover-picker__label">
          {t('parties_page.create_edit.finish_time')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            {form.finish_time
              ? moment(form.finish_time).format('HH:mm')
              : moment(new Date(0, 0, 0, 0, 0, 0, 0)).format('HH:mm')}
          </div>
          <TimePicker
            value={form.finish_time
              ? moment(form.finish_time).toDate()
              : new Date(0, 0, 0, 0, 0, 0, 0)}
            onChange={onChange('finish_time', 'free')}
          />
        </Popover>
        <Errors errors={errors.finish_time} />
      </div>
    </div>
  );

  const renderCityPicker = () => (
    <div className="b-contact-us__form__popover-picker">
      <p className="b-contact-us__form__popover-picker__label">
        {t('parties_page.create_edit.city')}
      </p>
      <CitySelector onlyAllowedCities={true}
        allowedCities={allAllowedCities}
        onChange={onChange('city_id', 'free')}
        cityId={form.city_id}
        className="b-filters__popover-picker__content"
      />

      <Errors errors={errors.city_id} className="test" />
    </div>
  );

  const partyTypePicker = () => (
    <div className="b-admin-filters__width--fit-content">
      <Popover popperModifiers={{ arrow: false }}>
        <div className="b-filters__popover-picker__content">
          { (form.partyType && t(`parties_page.create_edit.types_of_parties.${form.partyType.toLowerCase()}`))
            || t('parties_page.create_edit.types_of_parties.indoor')
          }
        </div>
        <div>
          {
            Object.keys(consts.TYPES_OF_PARTIES).map(
              (value, index) => (<div onClick={() => onChange('partyType', 'free')(value)}
                className="b-popover__content__item"
                key={index}>
                {t(`parties_page.create_edit.types_of_parties.${value.toLowerCase()}`)}
              </div>)
            )
          }
        </div>
      </Popover>
      <Errors errors={errors.partyType} />
    </div>
  );
  
  return <>
    <div className="b-header__leftPart b-header__leftPart--md b-sidebar__mobile-enabled">
      {!menuOpened ? (
        <div onClick={() => { setMenuOpened(true) }} className="b-header__hamburger">
          <Sidebar mobileMenuOpened={menuOpened} />
          <img className="b-header__hamburger__icon" src={hamburgerIcon} alt=""/>
        </div>
      ) : (
        <div onClick={() => { setMenuOpened(false) }} className="b-sidebar__mobile__bg_open">
          <Sidebar mobileMenuOpened={menuOpened} />
        </div>
      )}
    </div>
    <div className="b-header-admin__part">
      <div>
        <ChangeLanguage/>
      </div>
    </div>
    <form onSubmit={onSubmit} className="b-contact-us__form">
      <H2 className="b-contact-us__form__header">
        {is_edit ? t('parties_page.create_edit.header_edit') : t('parties_page.create_edit.header_create')}
      </H2>

      <p className="b-contact-us__form__subheader">{t('parties_page.create_edit.basic_info')}</p>
      { renderImagePicker() }
      <br/>

      <p className="b-filters__popover-picker__label">
        {t('parties_page.create_edit.dance_style')}
      </p>
      { renderDancePicker() }
      <br/>

      <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={descriptionFields}
      />

      <p className="b-contact-us__form__subheader">{t('parties_page.create_edit.links')}</p>
      <div>
        <Group
          errors={errors}
          loading={formLoading}
          values={form}
          onChange={onChange}
          className="checkbox"
          fields={linksFields} />
      </div>
      <br/>

      <p className="b-filters__popover-picker__label">{t('parties_page.create_edit.party_type')}</p>
      { partyTypePicker() }
      <br/>

      <p className="b-contact-us__form__subheader">{t('parties_page.create_edit.party_status')}</p>
      <div className="checkbox">
        <Group
          errors={errors}
          loading={formLoading}
          values={form}
          onChange={onChange}
          className="checkbox"
          fields={statusFields} />
      </div>
      <br/>

      <p className="b-contact-us__form__subheader">{t('parties_page.create_edit.time')}</p>
      <div className="checkbox">
        <Group
          errors={errors}
          loading={formLoading}
          values={form}
          onChange={onChange}
          className="checkbox"
          fields={[
            {
              name: 'is_one_time_event',
              label: t('parties_page.create_edit.is_one_time'),
              type: 'checkbox',
              required: false
            }
          ]} />
      </div>
      <br/>

      { renderDatePicker() }

      <p className="b-contact-us__form__subheader">{t('pay.title')}</p>
      <div>
        <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={pay}/>

        <div></div>
      </div>

      <p className="b-contact-us__form__subheader">{t('parties_page.create_edit.location')}</p>
      { renderCityPicker() }

      <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={addressFields}
      />

      {form.city_id && renderMap()}
      <br/>

      {hasPermission(user, consts.USER_HAS_CITIES) && (
        <>
          <p className="b-contact-us__form__subheader">{t('output_priority')}</p>
          <Group
            errors={errors}
            loading={formLoading}
            values={form}
            onChange={onChange}
            fields={[{
              name: 'output_priority',
              label: t('output_priority'),
              type: 'number',
              required: false,
              help: t('parties_page.create_edit.help_output_priority')
            }]}
        />
        </>
      )}

      <Button>{t(`parties_page.create_edit.${is_edit ? 'submit_edit' : 'submit_create'}`)}</Button>
    </form>
  </>;
};

CreateEditPartyContainer.propTypes = {
  't': PropTypes.func.isRequired,
  'onSubmit': PropTypes.func.isRequired,
  'onChange': PropTypes.func.isRequired,
  'form': PropTypes.object.isRequired,
  'formLoading': PropTypes.bool.isRequired,
  'errors': PropTypes.object.isRequired,
  'cities': PropTypes.object.isRequired,
  'is_edit': PropTypes.bool.isRequired,
  'allAllowedCities': PropTypes.arrayOf(PropTypes.number).isRequired,
};
