import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import objectPath from 'object-path-value';
import MediaQuery from 'react-responsive';
import * as Icons from 'react-feather';
import SortableTh from './SortableTh';

export default class Table extends Component {
  static propTypes = {
    expand: PropTypes.func,
    onSort: PropTypes.func,
    order: PropTypes.string,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        style: PropTypes.object,
        title: PropTypes.any,
        key: PropTypes.oneOfType([PropTypes.func, PropTypes.string])
      })
    ).isRequired,
    className: PropTypes.string,
    data: PropTypes.array
  };

  static defaultProps = {
    onSort: () => null,
    expand: null,
    order: null,
    className: '',
    data: []
  };

  state = {
    open: {}
  };

  onExpand = i => e => {
    e.preventDefault();

    this.setState({
      open: {
        ...this.state.open,
        [i]: !this.state.open[i]
      }
    });
  };

  renderThContent(col) {
    const {
      order,
      onSort
    } = this.props;

    const content = typeof col.title === 'function' ? col.title() : col.title;

    return col.sortable ? (
      <SortableTh
        column={col.sortable}
        order={order}
        callback={onSort}>
        {content}
      </SortableTh>
    ) : content;
  }

  static renderTdContent(col, item, open) {
    if (typeof col.key === 'function') {
      return col.key(item, open);
    }

    return objectPath(item, col.key, col.key);
  }

  render() {
    const {
      className,
      columns,
      expand,
      data
    } = this.props;

    const {
      open
    } = this.state;

    const header = columns.map((col, i) => (
      <th
        onClick={col.onClick || undefined}
        className={cx('b-table__th b-table__th--transparent', col.className)}
        style={col.style || {}}
        key={i}>
        {this.renderThContent(col)}
      </th>
    ));

    const body = data.map((item, i) => {
      const isOpen = open[i];

      const nodes = columns.map((col, t) => (
        <td
          onClick={expand ? this.onExpand(i) : undefined}
          className={cx('b-table__td b-table__td--transparent', { 'b-table__td--expandable': !!expand })}
          key={`${i}-${t}`}>
          {Table.renderTdContent(col, item, isOpen)}
        </td>
      ));

      return [
        <tr className='b-table__tr' key={i}>
          {expand && (
            <td
              style={{ width: 40 }}
              onClick={expand ? this.onExpand(i) : undefined}
              className={cx('b-table__td b-table__td--transparent', { 'b-table__td--expandable': !!expand })}>
              <Icons.ArrowDown className={cx('b-expand-icon', { 'b-expand-icon_open': isOpen })}/>
            </td>
          )}
          {nodes}
        </tr>,
        isOpen ? (
          <tr className="b-table__tr b-table__tr--expand" key={`${i}_expand`}>
            <td
              className="b-table__td b-table__td--transparent b-table__td--expand"
              colSpan={columns.length + 1}>
              {expand(item)}
            </td>
          </tr>
        ) : null
      ];
    });

    const mobileBody = data.map((item, i) => {
      const isOpen = open[i];

      const nodes = columns.map(col => {
        return (
          <tr className='b-table__tr' key={i}>
            <td className={cx('b-table__td b-table__td--transparent', { 'b-table__td--expandable': !!expand })}>
              {this.renderThContent(col)}
            </td>
            <td
              onClick={expand ? this.onExpand(i) : undefined}
              className={cx('b-table__td b-table__td--transparent', { 'b-table__td--expandable': !!expand })}
              key={i}>
              {Table.renderTdContent(col, item, isOpen)}
            </td>
          </tr>
        );
      });

      return [
        nodes,
        isOpen ? (
          <tr className="b-table__tr b-table__tr--expand" key={`${i}_expand`}>
            <td
              className="b-table__td b-table__td--transparent b-table__td--expand"
              colSpan={columns.length + 1}>
              {expand(item)}
            </td>
          </tr>
        ) : (
          <tr className="b-table__tr" key={`${i}_divider`}>
            <td
              colSpan={columns.length + 1}
              className="b-table__td b-table__td--transparent b-table__td--space"/>
          </tr>
        )
      ];
    });

    return (
      <div className="b-table__wrapper">
        <table className={cx('b-table', className)}>
          <MediaQuery minWidth={769}>
            <thead className="b-table__thead">
              <tr className="b-table__tr">
                {expand && (
                  <th
                    className={cx('b-table__th b-table__th--transparent')}
                    style={{ width: 40 }}/>
                )}
                {header}
              </tr>
            </thead>
            <tbody className="b-table__tbody">{body}</tbody>
          </MediaQuery>
          <MediaQuery maxWidth={768}>
            <tbody className="b-table__tbody">{mobileBody}</tbody>
          </MediaQuery>
        </table>
      </div>
    );
  }
}
