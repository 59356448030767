import React from 'react';
import { withRouter } from 'react-router-dom';
import {compose, lifecycle, pure, withState} from 'recompose';
import { SidebarLink } from './SidebarLink';
import { hasPermission } from '../../../utils/user';
import Logo from '../Logo';
import cx from 'classnames';
import {withOpenFeedback} from "../../hoc";
import consts from "../../../consts";

const SidebarContainer = ({ t, selected, setOpen, setSelected, menu, isOpened, history, user, mobileMenuOpened }) => (
  <div className={cx('b-sidebar', { 'b-sidebar__mobile': mobileMenuOpened, 'b-sidebar__mobile--hidden': !mobileMenuOpened })}>
    <Logo/>
    <br/>
    {menu.map((item, i) => (
      hasPermission(user, item.minPermission) && <SidebarLink
        key={i}
        index={i}
        isOpened={isOpened}
        selected={selected}
        setSelected={setSelected}
        setOpen={setOpen}
        pathname={history.location.pathname}
        t={t}
        {...item}/>
    ))}
  </div>
);

export const Sidebar = compose(
  withState('selected', 'setSelected', null),
  withState('isOpened', 'setOpen', null),
  withState('menu', 'setMenu', [
    {
      text: 'pages.parties',
      icon: 'FileText',
      to: '/admin/parties',
      minPermission: consts.USER_HAS_EVENTS
    },
    {
      text: 'pages.festivals',
      icon: 'FileText',
      to: '/admin/festivals',
      minPermission: consts.USER_HAS_EVENTS
    },
    {
      text: 'pages.users',
      icon: 'Users',
      to: '/admin/users/list',
      minPermission: consts.USER_MAIN_ADMIN
    },
    {
      text: 'pages.feedback',
      icon: 'FileText',
      to: '/admin/feedback',
      minPermission: consts.USER_MAIN_ADMIN
    },
    {
      text: 'pages.settings',
      icon: 'Settings',
      minPermission: consts.USER_MAIN_ADMIN,
      sublinks: [
        { text: 'pages.basic_settings', to: '/admin/settings/basics' },
        { text: 'pages.testing', to: '/admin/settings/testing' },
      ]
    }
  ]),
  withRouter,
  withOpenFeedback,
  lifecycle({
    componentDidUpdate() {
      if (this.props.feedbackOpenAdminList && this.props.menu[3].count === undefined && !this.props.loading) {
        // кол-во непрочитанных обращений на стр feedback
        const newMenu = this.props.menu;
        newMenu[3].count = this.props.feedbackOpenAdminList.meta.total;
        this.props.setMenu(newMenu);
      }
    }
  }),
  pure
)(SidebarContainer);
