import gql from 'graphql-tag';

export const feedbackAdminList = gql`
  query FeedbackAdminList($input: FeedbackAdminList!) {
    feedbackAdminList(input: $input) {
      meta {
        page
        page_size
        page_count
        total
      }
      objects
    }
  }
`;