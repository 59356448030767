import React from 'react'
import {
  NavLink, withRouter,
} from 'react-router-dom'
import qs from 'qs';
import {compose, lifecycle, withProps, withState} from 'recompose'
import { registrationConfirm } from '../query'
import { graphql } from 'react-apollo';
import { withT } from "../hoc";


const Container = ({ registrationConfirm, t }) => {
  if (registrationConfirm) {
    return (
      <div className="b-confirm">
        <div className="b-confirm--text">{t('registration_confirm.successfull_error')}</div>
        <div className="b-confirm--text"><NavLink to="/login" className="link">{t('login.log_in')}</NavLink></div>
      </div>
    )
  }

  if (registrationConfirm === null) {
    return (
      <div className="b-confirm">
        <div className="b-confirm--error b-confirm--text">{t('registration_confirm.verify_error')}</div>
      </div>
    )
  }

  return (
    <div className="b-confirm">
      <div className="b-confirm--text">{t('registration_confirm.progress_error')}</div>
    </div>
  )

};

export const RegistrationConfirm =  compose(
  withRouter,
  withT,
  withState('registrationConfirm', 'setRegistrationConfirm', null),
  graphql(registrationConfirm, { name: "sendRegistrationConfirm" }),
  lifecycle({
    async componentWillMount() {
      const { props } = this;
      const tokenConfirm = (await props.sendRegistrationConfirm({ variables: { token: qs.parse(props.location.search.slice(1)).token } })).data.registrationConfirm.jwt;
      props.setRegistrationConfirm(tokenConfirm);
    }
  }),
  withProps(props => ({
    ...props,
  })),
)(Container);
