import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { H1, Divider, Button } from 'firefly/component';
import { compose, lifecycle } from 'recompose';
import { writeLocalState } from '../utils/index';
import bg_top from '../img/main-bg/lg-top.png';
import bg_bottom from '../img/main-bg/lg-bottom.png';
import block1Img from '../img/main-page/block1.png';
import block2Img from '../img/main-page/block2.png';
import block3Img from '../img/main-page/block3.png';
import mobileImg from '../img/main-page/mobile.png';
import appStoreIcon from '../img/appstore.svg';
import googlePlayIcon from '../img/googleplay.svg';
import { withT } from '../hoc';


const Container = ({ t }) => (
  <div className="b-homepage">
    <div className="b-homepage__first-block">
      <div className="b-homepage__first-block__part">
        <H1>{t('homepage.main_block.header1')} <br/> {t('homepage.main_block.header2')}</H1>
        <p className="b-homepage__first-block__part__text">
          {t('homepage.main_block.description')}
        </p>
      </div>
      <img className="b-homepage__first-block__part b-homepage__first-block__part__img" src={block1Img} alt=""/>
    </div>

    <div className="b-homepage__twin-block">
      <img className="b-homepage__twin-block__part b-homepage__twin-block__part__img" src={block2Img} alt=""/>
      <div className="b-homepage__twin-block__part">
        <H1 className="b-homepage__twin-block__part__header">{t('homepage.announcements_block.header')}</H1>
        <p className="b-homepage__twin-block__part__text">
          {t('homepage.announcements_block.description')}
        </p>
        <Link to="parties">
          <Button className="b-homepage__twin-block__part__button b-button--outline">
            {t('homepage.announcements_block.more')}
          </Button>
        </Link>
      </div>
    </div>

    <div className="b-homepage__twin-block">
      <img className="b-homepage__twin-block__part b-homepage__twin-block__part__img" src={block3Img} alt=""/>
      <div className="b-homepage__twin-block__part">
        <H1 className="b-homepage__twin-block__part__header">{t('homepage.events.header')}</H1>
        <p className="b-homepage__twin-block__part__text">
          {t('homepage.events.description')}
        </p>
        <Link to="/contacts">
          <Button className="b-homepage__twin-block__part__button b-button--outline">
            {t('homepage.events.more')}
          </Button>
        </Link>
      </div>
    </div>

    <div className="b-homepage__mobile-block">
      <div className="b-homepage__mobile-block__container">
        <div className="b-homepage__mobile-block__img-part">
          <img className="b-homepage__mobile-block__img-part__img" src={mobileImg} alt=""/>
        </div>
        <div className="b-homepage__mobile-block__content-part">
          <H1>{t('homepage.mobile_block.header')}</H1>
          <p className="b-homepage__twin-block__part__text">
            {t('homepage.mobile_block.description')}
          </p>
          <Divider/>
          <div className="b-homepage__mobile-block__content-part__promo">
            <div>
              <p className="b-homepage__mobile-block__content-part__promo__text">IOS</p>
              <NavLink exact to="/development"><img src={appStoreIcon} alt=""/></NavLink>
            </div>
            <div className="b-divider--vertical"/>
            <div>
              <p className="b-homepage__mobile-block__content-part__promo__text">ANDROID</p>
              <NavLink exact to="/development"><img src={googlePlayIcon} alt=""/></NavLink>
            </div>
          </div>

        </div>
      </div>
    </div>

    {/*<div className="b-homepage__twin-block">*/}
      {/*<div>*/}
        {/*<H1>Portal <br/> for Dancers</H1>*/}
        {/*<p>*/}
          {/*We know all about latin-american parties in Europe! With the app*/}
          {/*"Where to dance"*/}
          {/*every day you will be informed of the best dance parties.*/}
        {/*</p>*/}
      {/*</div>*/}
      {/*<div>*/}
        {/*<img src={block1Img} alt=""/>*/}
      {/*</div>*/}
    {/*</div>*/}
  </div>
);

export const Homepage = compose(
  withT,
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom, bg_top_position: 'top left', bg_bottom_position: 'bottom right' });
    }
  })
)(Container);
