import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Button, Divider, FormRow, Group, Popover } from 'firefly/component';
import consts from '../../../../../consts';
import { hasPermission } from '../../../../../utils/user';
import BaseFilterForm from '../../../../component/FilterForm';
import { CityCheckboxSelector } from '../../../../../module/CityCheckboxSelector';
import { CheckboxGroup } from '../../../../../module/CheckboxGroup';
import festivalStyles from '../../../../../component/Festivals/festivalTypesMock';


export default class FilterForm extends BaseFilterForm {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      form,
    } = this.state;
    const { t, allAllowedCities, user } = this.props;

    const festivalStatusFields = [
      {
        name: 'disabled',
        label: t('festivals_page.create_edit.disabled'),
        type: 'checkbox',
        required: false
      },
    ];

    const renderSelectedCheckboxGroupComponent = (propertyName, valuesLabelsMap) => {
      if (propertyName === 'dance_styles') {
        return (form[propertyName] || []).length > 0 ? form[propertyName].join(', ') : t('not_chosen');
      } else {
        const translateName = (form[propertyName] || []).map(value =>  {
          return valuesLabelsMap[value];
        });
        return (translateName || []).length > 0 ? translateName.join(', ') : t('not_chosen');
      }
    };

    const renderCityPicker = () => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name b-admin-filters__group__name-white">{t('festivals_page.filters.cities')}</span>
        <CityCheckboxSelector classNameItem="b-admin-filters__group__name-white b-popover__content__item__theme-dark" classNameGroup="b-admin-filters__popover__theme-dark" allAllowedCities={allAllowedCities} onChange={this.change('cities')} value={form.cities || []} />
      </div>
    );

    const renderCheckboxGroupComponent = (
      { propertyName, validValues, valuesLabelsMap, label } = {}
    ) => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name b-admin-filters__group__name-white">{label}</span>
        <Popover>
          <div className="b-filters__popover-picker__content__admin b-popover__content__item b-popover__content__item__theme-dark b-admin-filters__group__name-white">
            {renderSelectedCheckboxGroupComponent(propertyName, valuesLabelsMap)}
          </div>
          <div className="b-checkbox-group b-admin-filters__popover__theme-dark">
            <CheckboxGroup
              value={form[propertyName] || []}
              onChange={value => this.change(propertyName)({ value })}
              allPossibleValues={validValues}
              valuesLabelsMap={valuesLabelsMap}
            />
          </div>
        </Popover>
      </div>
    );

    const renderCheckboxGroup = ({ fields, label } = {}) => (
      <div className="b-admin-filters__group">
        <span className="b-admin-filters__group__name b-admin-filters__group__name-white">{label}</span>
        <div className="b-admin-filters__group__checkbox-group b-admin-filters__popover__theme-dark">
          <Group
            values={form}
            onChange={this.change}
            className="checkbox"
            fields={fields} />
        </div>
      </div>
    );

    return (
      <form onSubmit={this.submit} className="b-admin-filters__popover b-admin-filters__popover__theme-dark">
        <FormRow>
          { renderCheckboxGroup({ property: form, fields: festivalStatusFields, label: t('festivals_page.filters.festival_status') }) }
          {
            hasPermission(user, consts.USER_HAS_CITIES) && allAllowedCities.length > 1
            && renderCityPicker()
          }
          {
            renderCheckboxGroupComponent({
              propertyName: 'dance_styles',
              validValues: festivalStyles,
              label: t('festivals_page.filters.dance_styles'),
            })
          }
        </FormRow>
        <FormRow>
          <div className="b-page-admin--width-full b-page-admin--flex--center">
            <Button className="b-admin-filters__form b-button--flex--center b-button--color-white" type="submit">
              {t('festivals_page.apply')}
            </Button>
          </div>
          <Divider modifiers='small' />
          <button onClick={this.onReset} className='b-link b-link--center' type="button">
            {t('festivals_page.reset_filters')}
          </button>
        </FormRow>
      </form>
    );
  }
}
