import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { getFullImgUrl } from '../../utils/index';
import calendarIcon from '../../img/parties-page/calendar-icon.png';
import locationIcon from '../../img/parties-page/location-icon.png';
import rubIcon from '../../img/currencies/rub.png';
import eurIcon from '../../img/currencies/euro.svg';
import usdIcon from '../../img/currencies/usd.svg';
import defaultPartyLogo1 from '../../img/default-party-logo-short.svg';
import defaultPartyLogo2 from '../../img/default-party-logo2-short.svg';
import { withT } from '../../hoc';

const defaultLogos = [defaultPartyLogo1, defaultPartyLogo2];
const getRandomArrayValue = array => array[Math.floor(Math.random() * array.length)];
const currencyIcon = {
  RUB: rubIcon,
  EUR: eurIcon,
  USD: usdIcon
}

const InfoItem = ({ children, img, Wrapper = Fragment }) => (
  <div className="b-party__info__item">
    <div className="b-party__info__item__icon"><img src={img} alt=""/></div>
    <span className="b-party__info__item__text"><Wrapper>{children}</Wrapper></span>
  </div>
);

const sliceString = str => str.length > 19 ? str.slice(0,19) + '...' : str;

const addTimeToDate = (start_date, finish_date) => start_date + ' - ' + finish_date;

export const MinFestivals = withT(({ t, start_date, finish_date, address, price, currency, cancelled, logo_path, dance_styles, id, isFestivalPage }) => (
  <Link className="no-decoration" to={{ pathname: `/festival/${id}`, state: { isFestivalPage } }}>
    <div className="b-party">
      <div className="b-party__img-container">
        <img className="b-party__img-container__img"
             src={logo_path ? getFullImgUrl(logo_path) : getRandomArrayValue(defaultLogos)} alt=""
        />
        {cancelled ? (
          <div className="b-event-page__info__cancelled">
            <p className="b-event-page__info__cancelled-text b-event-page__info__cancelled-text--flex-center">{t('cancelled_event')}</p>
          </div>
        ) : (
          ''
        )}
        <div className="b-party__img-container__styles">
          {dance_styles.map((styleName, key) => (
            <div key={key} className="b-party__img-container__styles__item b-party__img-container__styles__item__preview">{styleName}</div>
          ))}
        </div>
      </div>
      <div className="b-party__info">
        <InfoItem img={calendarIcon}>{
          addTimeToDate(start_date, finish_date)
        }</InfoItem>
        <InfoItem img={locationIcon}>{sliceString(address)}</InfoItem>
        { (price > 0) && <InfoItem img={currency ? currencyIcon[currency] : currencyIcon.RUB}><div className="b-price--min"><div className="b-price-full">{price}</div><p className="b-price-cashback">cashback 100</p></div></InfoItem> }
      </div>
    </div>
  </Link>
));
