import consts from '../consts';


export const isMainAdmin = user => user.type === consts.USER_MAIN_ADMIN;

export const hasCities = user => user.ownedCity.length > 0;

export const hasParites = user => user.ownedParty.length > 0 || hasCities(user);

export const hasFestivals = user => user.ownedFestival.length > 0 || hasCities(user);

export const hasPermission = (user, minPermission) => {
  if (isMainAdmin(user)) {
    return true;
  }

  switch (minPermission) {
    case consts.USER_REGULAR:
      return true;
    case consts.USER_HAS_PARTIES:
      return hasParites(user);
    case consts.USER_HAS_FESTIVALS:
      return hasFestivals(user);
    case consts.USER_HAS_EVENTS:
      return hasFestivals(user) || hasParites(user);
    case consts.USER_HAS_CITIES:
      return hasCities(user);
    default:
      return false;
  }
};

export const logout = update => () => {
  localStorage.removeItem('jwt');
  return (update && update()) || (window && window.location.reload());
};
