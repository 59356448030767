import gql from 'graphql-tag';

export const partyList = gql`
  query partyList($input: PartyListInput!) {
    partyList(input: $input) {
      meta {
        total
      }
      objects
    }
  }
  `;

export const party = gql`
  query party($id: Int) {
    party(id: $id)
  }
`;
