import qs from 'qs';
import { withFormState } from 'firefly-hoc';
import { withRouter } from 'react-router-dom';
import { compose, withProps, withState } from 'recompose';
import Table from './Table';
import { graphql } from 'react-apollo/index';
import { partyAdminList, deleteParty } from '../../query';
import { withAdminT } from '../../../../hoc';
import { withCities, withUser } from '../../../../../hoc';
import moment from 'moment';

export default compose(
  withFormState,
  withAdminT,
  withCities,
  withUser,
  withRouter,
  withState('isOpenDeleteModal', 'setIsOpenDeleteModal', false),
  withState('delParty', 'setDelParty', {}),
  withProps(props => ({
    ...props,
    filter: {
      ...(props.filter || {}),
      page: '1',
      page_size: '10',
      ...qs.parse(props.location.search.slice(1))
    },
  })),
  graphql(partyAdminList, {
    options: props => ({
      variables: {
        input: { ...props.filter, days_of_week: (props.filter.days_of_week || []).map(day => Number(moment.weekdaysShort().indexOf(day))) }
      },
      fetchPolicy: 'network-only',
    }),
  }),
  graphql(deleteParty, { name: "deleteParty" }),
  withProps(props => ({
    ...props,
    refetch: filter => props.data.refetch({ input: {...filter, days_of_week: (filter.days_of_week || []).map(day => Number(moment.weekdaysShort().indexOf(day))) } }),
    loading: props.data.loading,
    ...(props.data.partyAdminList || {})
  }))
)(Table);
