import React from 'react';
import { H2, DialogBody, Dialog } from 'firefly/component';
import { Link } from "react-router-dom";

export const LoginModal = ({ loginModal, t, setLoginModal }) => (
  <Dialog isOpen={loginModal} onClose={() => setLoginModal(false)}>
    <DialogBody>
      <H2>{t('contact_us.login_modal.text')} <br/> <Link to="/login" className="b-contact-us__link">{t('contact_us.login_modal.link_text')}</Link></H2>
    </DialogBody>
  </Dialog>
);