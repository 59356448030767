import React, { Fragment } from 'react';
import { MinParty } from '.';

export const Grid = ({ parties }) => (
  <Fragment>
    <div className="b-parties__grid">
      <div className="b-parties__grid__container">
        {parties.map((party, key) => (
          <div className="b-parties__grid__container__party" key={key} >
            <MinParty {...party} isPartyPage={true} />
          </div>))}
      </div>
    </div>
  </Fragment>
);
