import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import qs from 'qs';


export const withQSStorage = compose(
  withRouter,
  withProps(props => ({
    ...props,
    currentQSParams: props.history.location.search
      ? {
        ...(props.nullQSValues || {}),
        ...(qs.parse(props.history.location.search.slice(1)))
      }
      : { ...(props.defaultQSValues || {}) },
    toPath: value => `${props.history.location.pathname}?${qs.stringify(value)}`
  })),
  withProps(props => ({
    ...props,
    setQSValue: name => value => props.history.push(props.toPath({
      ...props.currentQSParams,
      [name]: value
    })),
    getQSValue: name => props.currentQSParams[name]
  })),
);
