import React, { Fragment } from 'react';
import { bem } from 'firefly/component';
import { SidebarRow } from './SidebarRow';

export const SidebarLink = ({ t, setSelected, setOpen, pathname, history, selected, isOpened, index, icon, badge, to, text, sublinks, count }) => {
  const nodes = (sublinks || []).map((link, i) => (
    <SidebarRow
      key={i}
      count={count}
      className={bem('b-sidebar__item', { 'sub': true, 'selected': `${index}-${i}` === selected })}
      onClick={() => setSelected(`${index}-${i}`)}
      text={t(link.text)}
      to={link.to}/>
  ));

  return (
    <Fragment>
      <SidebarRow
        to={to}
        className={bem('b-sidebar__item', { 'selected': index === selected })}
        onClick={() => to ? setSelected(index) : setOpen(index)}
        icon={icon}
        count={count}
        text={t(text)}/>
      {index === isOpened && (
        <div className="b-sidebar__sublinks">
          {nodes}
        </div>
      )}
    </Fragment>
  );
};
