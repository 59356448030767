import React from 'react';
import qs from 'qs';
import { compose, lifecycle, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo/index';
import { SpinnerGlobal } from 'firefly/component/Spinner';
import { vkAuth } from '../query';
import { withUser } from '../hoc';
import { toastSuccess } from '../utils';


export const VkAuth = compose(
  withRouter,
  withUser,
  graphql(vkAuth, { name: 'vkAuth' }),
  withProps(props => ({
    ...props,
    onSuccess({ data: { vkAuth: result } }) {
      localStorage.setItem('jwt', result.jwt);
      props.updateUser();
    },
    args: qs.parse(props.location.search.slice(1))
  })),
  lifecycle({
    componentDidMount() {
      const { props } = this;

      props.vkAuth({
        variables: {
          code: props.args.code,
          url: `${window.location.origin}${window.location.pathname}`
        }
      }).then(props.onSuccess).catch(() => props.history.push('/login'));
    },
    componentDidUpdate() {
      if (this.props.user) {
        toastSuccess('Successfully authenticated');
        this.props.history.push('/');
      }
    }
  }),
)(() => <SpinnerGlobal />);
