import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { SpinnerGlobal } from 'firefly/component';
import { branch, compose, pure, renderComponent, withState, withProps, lifecycle } from 'recompose';
import { withFormState, getValue } from 'firefly-hoc';
import { restorePassword, checkEmailConfirmationToken } from '../query';
import { toastError, toastSuccess } from '../utils/index';
import { PasswordForm as Container } from '../container';
import { withT } from '../hoc';

export const RestorePassword = compose(
  withT,
  graphql(restorePassword),
  withRouter,
  graphql(checkEmailConfirmationToken, { name: "checkEmailConfirmationToken",
    options: props => ({
      variables: {
        token: props.match.params.token
      }
    })
  }),
  withState('isLoading', 'setLoading', false),
  withState('success', 'setSuccess', null),
  withProps(props => ({
    ...props,
    callback(form) {
      props.setLoading(true);
      return props.mutate({
        variables: {
            ...form,
            token: props.match.params.token,
        }
      });
    },

    onSuccess() {
      const { t } = props;
      toastSuccess(t('auth.password_was_changed'));
      props.setSuccess(true);
      props.setLoading(false);
    },

    onValidationError() {
      const { t } = props;
      toastError(t('common_error_message'));
      props.setSuccess(null);
      props.setLoading(false);
    }
  })),
  withFormState,
  withProps(props => ({
    onInputChange: (name, type = 'text') => e => {
      const value = getValue(type, e);
      props.setForm({
        ...props.form,
        [name]: value
      });
    }
  })),
  lifecycle({
    async componentWillMount() {
      const { props } = this;
      const tokenConfirm = (await props.checkEmailConfirmationToken()).data.restoreCheck;
      props.setSuccess(tokenConfirm ? null : tokenConfirm);
    }
  }),
  branch(props => props.isLoading || props.order === null, renderComponent(SpinnerGlobal)),
  pure
)(Container);
