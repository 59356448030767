import React from 'react';
import { H1, Transition } from 'firefly/component';
import {ButtonBack} from "../module/ButtonBack";

export const Agreements = () => (
  <Transition>
    <ButtonBack goToPage="/"/>
    <H1>Agreement on the Storage of the Cardholder’s Credentials</H1>

    <div className="b-typeset">
      <p>Last update: february 09, 2019</p>
      <p>1. This Agreement on the Storage of the
        Cardholder’s Credentials (hereinafter – the “Agreement”)
        is entered into between you (hereinafter also referred to as – the “Cardholder”)
        and KSExchange OU (hereinafter – the “Merchant”).
      </p>
      <p>2. By clicking the “Deposit/Withdrawal” button, you express full consent to this
        Agreement and authorize the Merchant and its payment service provider to store your name,
        surname, card expiry date and PAN number (hereinafter – the Credentials on File (COF))
        and to initiate transactions on your behalf (hereinafter
        – Merchant-Initiated COF Transactions) and/or to process transactions
        initiated by you (hereinafter
        – Cardholder-Initiated COF Transactions),
        both by using the Credentials of File, as indicated below.</p>
      <p>3. Disclosures of the Merchant:</p>
      <p>3.1 Your Credentials will be used for: Cardholder-Initiated COF Transactions</p>
      <p>3.2 Description of services:</p>
      <ul>
        <li>purchase of cryptocurrency for further trading;</li>
        <li>storage of funds in cryptocurrency.</li>
      </ul>
      <p>3.3 Fixed date or interval of the Transaction: N/A</p>
      <p>3.4 Event that will prompt the Transaction: N/A</p>
      <p>4. The following details in relation to this Agreement
        shall be included in the transaction confirmation: card number,
        transaction amount, surcharges (if applicable), transaction currency,
        transaction date, authorisation code, the Merchant’s location.</p>
      <p>5. You will be notified of any changes to the present Agreement 7
        (seven) business days before such changes become effective in relation
        to scheduled (with fixed, regular intervals) Merchant-Initiated
        COF Transactions, and 2 (two) business days before such changes
        become effective in relation to Merchant-Initiated COF Transactions
        which are unscheduled or in relation to Cardholder-Initiated
        COF Transactions.</p>
      <p>6. You agree to notify the Merchant of any changes in the Credentials
        on File at least 7 (seven) business days prior to the next Transaction date.</p>
      <p>7. The Agreement shall enter into force after successful authorisation
        (or account verification) by your card issuer and receipt of your
        consent to this Agreement by the Merchant, and shall be valid until terminated
        by you or by the Merchant according to the Cancellation policy.</p>
      <p>8. The Cancellation and refund policies of the Merchant are accessible following
        the link below: <a href="https://crypto-card.exchange/refund-policy">https://crypto-card.exchange/refund-policy</a>.</p>
      <p>9. In case of any inquiries related to this Agreement,
        please contact: support@crypto-card.exchange
      </p>
    </div>
  </Transition>
);
