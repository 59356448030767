import gql from 'graphql-tag';


export const authenticateAdmin = gql`
  mutation authenticateAdmin($input: AuthenticateAdminInput!) {
    authenticateAdmin(input: $input) {
      token
    }
  }
`;
