import { compose, withState, withProps, lifecycle } from 'recompose';
import moment from 'moment';
import { graphql } from 'react-apollo';
import { formatDate, formatedDateToMoment } from '../../utils/index';
import { partyList } from '../../query';
import { Parties as Container } from '../../container/Parties';
import { withCities, withCurrentCity, withT, withUser, withQSStorage } from '../../hoc';

const getDefaultSetting = settingName => Number(localStorage.getItem(`setting_${settingName}`)) === 0 && settingName === 'city'
  ? 5
  : localStorage.getItem(`setting_${settingName}`) ;
const setDefaultSetting = (settingName, value) => localStorage.setItem(`setting_${settingName}`, value);

const correctDateRange = ({ since: originalSince, till: originalTill }) => {
  let since = originalSince;
  let till = originalTill;
  if (formatedDateToMoment(originalSince).diff(moment(), 'days') < 0) {
    since = formatDate(moment());
  }
  if (formatedDateToMoment(originalTill).diff(moment(), 'days') < 0) {
    till = formatDate(moment());
  }
  return { since, till };
};


export const Parties = compose(
  withT,
  withCurrentCity,
  withUser,
  withCities,
  withProps(props => ({
    ...props,
    nullQSValues: {
      partyTypes: []
    },
    defaultQSValues: {
      city: getDefaultSetting('city') || 5,
      dateRange: { since: formatDate(moment()), till: formatDate(moment()) },
      partyTypes: getDefaultSetting('partyTypes') ? JSON.parse(getDefaultSetting('partyTypes')) : [],
      layoutStyle: 'grid'
    }
  })),
  withQSStorage,
  withState('suggestedCity', 'setSuggestedCity', null),
  graphql(partyList, { options: props => ({
    variables: {
      input: {
        city_id: +props.getQSValue('city'),
        since: props.getQSValue('dateRange').since,
        till: props.getQSValue('dateRange').till,
        dance_styles: props.getQSValue('partyTypes') || []
      }
    }
  })
  }),
  withProps(props => ({
    ...props,
    parties: ((props.data.partyList || {}).objects || []),
    setDateRange(range) {
      const [since, till] = range;
      if (!since || !till) {
        return;
      }
      props.setQSValue('dateRange')({
        since: formatDate(moment(since)),
        till: formatDate(moment(till))
      });
    },

  })),
  lifecycle({
    componentDidMount() {
      const { props } = this;
      const dateRange = props.getQSValue('dateRange');
      const correctedDateRange = correctDateRange(dateRange);
      if (JSON.stringify(dateRange) !== JSON.stringify(correctedDateRange)) {
        props.setQSValue('dateRange')(correctedDateRange);
      }
    },

    componentDidUpdate(prevProps) {
      const { props } = this;
      if (prevProps.currentQSParams.city !== props.currentQSParams.city) {
        setDefaultSetting('city', props.currentQSParams.city);
      } else if (prevProps.currentQSParams.partyTypes !== props.currentQSParams.partyTypes) {
        setDefaultSetting('partyTypes', JSON.stringify(props.currentQSParams.partyTypes));
      }

      if (!prevProps.currentCity && props.currentCity && !getDefaultSetting('city')) {
        if (props.defaultQSValues.city === props.getQSValue('city')) { // not mutated from original value
          props.setSuggestedCity(props.currentCity);
        }
      }
    }
  }),
)(Container);
