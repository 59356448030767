import { compose, withProps, lifecycle } from 'recompose';
import { graphql } from 'react-apollo';
import { me } from '../query';

export const withUser = compose(
  graphql(me),
  withProps(props => ({
    ...props,
    user: props.data.me,
    updateUser: () => props.data.refetch()
  })),

  lifecycle({
    componentDidUpdate() {
    }
  })
);
