import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from 'apollo-link-context';
import fetch from 'isomorphic-fetch';
import moment from 'moment';
import config from './config';
import { cache, errorLink } from './apollo';
const timezoneOffset = moment().utcOffset();

const uploadLink = createUploadLink({
  uri: config.endpoint,
  fetch
});

const headersMiddleware = setContext(({ headers = {} }) => ({
  headers: {
    ...headers,
    timezoneOffset,
    authorization: localStorage.getItem('jwt')
  }
}));


const client = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    headersMiddleware,
    uploadLink,
  ]),
  resolvers: {},
  errorLink,
  cache
});

export default client;
