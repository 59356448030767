import React from 'react';
import { Button, H4 } from 'firefly/component';
import { Link } from 'react-router-dom';

export const Summary = ({ t }) => (
  <div className="b-contact-us__summary">
    <div className="b-contact-us__summary__container">
      <div className="b-contact-us__summary__container__block">
        <H4 className="b-contact-us__summary__container__block__header">
          {t('contact_us.summary.suggest_party.header')}
        </H4>
        <p className="b-contact-us__summary__container__block__description">
          {t('contact_us.summary.suggest_party.description')}
        </p>
        <Link
          className="b-button--outline b-contact-us__summary__container__block__button"
          to={{ pathname: '/contacts/suggest_party'}}
        >
          <Button
            className="b-button--outline b-contact-us__summary__container__block__button">
            {t('contact_us.summary.button')}
          </Button>
        </Link>
      </div>

      <div className="b-contact-us__summary__container__block">
        <H4 className="b-contact-us__summary__container__block__header">
          {t('contact_us.summary.manage_party.header')}
        </H4>
        <p className="b-contact-us__summary__container__block__description">
          {t('contact_us.summary.manage_party.description')}
        </p>
        <Link
          className="b-button--outline b-contact-us__summary__container__block__button"
          to={{ pathname: '/contacts/edit_party'}}
        >
          <Button
            className="b-button--outline b-contact-us__summary__container__block__button">
            {t('contact_us.summary.button')}
          </Button>
        </Link>
      </div>

      <div className="b-contact-us__summary__container__block">
        <H4 className="b-contact-us__summary__container__block__header">
          {t('contact_us.summary.manage_city.header')}
        </H4>
        <p className="b-contact-us__summary__container__block__description">
          {t('contact_us.summary.manage_city.description')}
        </p>
        <Link
          className="b-button--outline b-contact-us__summary__container__block__button"
          to={{ pathname: '/contacts/become_admin'}}
        >
          <Button
            className="b-button--outline b-contact-us__summary__container__block__button">
            {t('contact_us.summary.button')}
          </Button>
        </Link>
      </div>

      <div className="b-contact-us__summary__container__block">
        <H4 className="b-contact-us__summary__container__block__header">
          {t('contact_us.summary.suggest_partnership.header')}
        </H4>
        <p className="b-contact-us__summary__container__block__description">
          {t('contact_us.summary.suggest_partnership.description')}
        </p>
        <Link
          className="b-button--outline b-contact-us__summary__container__block__button"
          to={{ pathname: '/contacts/cooperation'}}
        >
          <Button
            className="b-button--outline b-contact-us__summary__container__block__button">
            {t('contact_us.summary.button')}
          </Button>
        </Link>
      </div>
    </div>
  </div>
);
