import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setPropTypes, branch, renderComponent } from 'recompose';
import { SpinnerGlobal, Group, Popover } from 'firefly/component';
import { getValue } from 'firefly-hoc';
import { withCities, withT } from '../../hoc';
import cx from 'classnames';

const getCityName = (cityEntry, i18n) => cityEntry.name_translations[i18n.language.slice(0, 2).toLowerCase()] || cityEntry.name;

const getNameSelectedCities = (internalValues, cities, i18n, t) => {
  const selectedCities = Object.values(cities).filter((cityEntry) => {
    return internalValues[cityEntry.id];
  });
  const translateNameCity = Object.values(selectedCities).map((cityEntry) => {
    return getCityName(cityEntry, i18n);
  });
  return translateNameCity.length > 0 ? translateNameCity.join(', ') : t('not_chosen');
};

const Container = ({ internalValues, onChange, cities, i18n, t, classNameGroup='', classNameItem='' }) => (
  <Popover>
    <div className={cx("b-filters__popover-picker__content__admin b-popover__content__item", classNameItem)}>
      {getNameSelectedCities(internalValues, cities, i18n, t)}
    </div>
    <div className={cx("b-checkbox-group", classNameGroup)}>
      <Group
        values={internalValues}
        onChange={onChange}
        fields={
          Object.keys(internalValues).map(value => ({
            name: value,
            label: getCityName(cities[value], i18n),
            type: 'checkbox',
            required: false
          }))
        }
      />
      <br/>
    </div>
  </Popover>
);

export const CityCheckboxSelector = compose(
  withT,
  withCities,
  setPropTypes({
    value: PropTypes.array.isRequired,
    allAllowedCities: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  }),
  withProps(props => ({
    ...props,
    value: (props.value || []).map(Number)
  })),
  withProps(props => ({
    ...props,
    internalValues: props.allAllowedCities.reduce(
      (accumulator, value) => ({
        ...accumulator,
        [value]: Boolean(~(props.value.indexOf(value)))
      }), {}
    )
  })),

  withProps(props => ({
    ...props,
    onChange: (name, type = 'checkbox') => e => {
      const value = getValue(type, e);
      const newInternalValue = {
        ...props.internalValues,
        [name]: value
      };
      const newExternalValue = props.allAllowedCities.filter(value => newInternalValue[value]);
      props.onChange({ value: newExternalValue });
    }
  })),
  branch(props => props.data.loading, renderComponent(SpinnerGlobal))
)(Container);
