import React from 'react';
import qs from 'qs';
import { compose, lifecycle, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo/index';
import FacebookAuth from 'react-facebook-auth';
import { fbAuth, fbAuthMobile } from '../query';
import { withUser } from '../hoc';
import fbIcon from '../img/facebook.svg';
import { toastSuccess } from '../utils';
import { getFBAuthUrl } from '../utils';

const MyFacebookButton = ({ onClick }) => (
  <div onClick={onClick}>
    <img className="b-social__icon b-social__icon__login" src={fbIcon} alt="fb"/>
  </div>
);


const Container = ({ authenticate, appId, isMobile }) => (
  <div>
    {!isMobile && (
      <FacebookAuth
        appId={appId}
        callback={authenticate}
        component={MyFacebookButton}
        reAuthenticate={true}
        version="5.0"
      />
    )}
    {isMobile && (
      <a href={getFBAuthUrl({ fb_client_id: appId })}>
        <img className="b-social__icon b-social__icon__login" src={fbIcon} alt="fb"/>
      </a>
    )}
  </div>
);


export const FbAuth = compose(
  withRouter,
  withUser,
  graphql(fbAuth, { name: 'fbAuth' }),
  graphql(fbAuthMobile, { name: 'fbAuthMobile' }),
  withProps(props => ({
    ...props,
    onSuccess({ data: { fbAuthMobile: result } }) {
      localStorage.setItem('jwt', result.jwt);
      props.updateUser();
    },
    args: qs.parse(props.location.search.slice(1)),
    isMobile: !!((window.navigator && window.navigator.standalone) || navigator.userAgent.match('CriOS') || navigator.userAgent.match(/mobile/i)),
    authenticate: (response) => {
      // Api call to server so we can validate the token
      props.fbAuth({
        variables: {
          user: {...response}
        }
      }).then((res) => {
        localStorage.setItem('jwt', res.data.fbAuth.jwt);
        props.updateUser();
      }).catch(() => props.history.push('/login'));
    },
  })),
  lifecycle({
    componentDidMount() {
      const { props } = this;
      if (props.args.code) {
        props.fbAuthMobile({
          variables: {
            code: props.args.code,
            url: `${window.location.origin}${window.location.pathname}`
          } }).then(props.onSuccess).catch(() => props.history.push('/login'));
      }
    },
    componentDidUpdate() {
      if (this.props.user) {
        toastSuccess('Successfully authenticated');
        this.props.history.push('/');
      }
    }
  })
)(Container);
