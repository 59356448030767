import React from 'react';
import { Map as GoogleMap } from '../../module/Map';

import { FestivalMarker } from '../../component';
import { withCities } from '../../hoc';


/* Parties are duplicated when they are queried from server if they happen periodically
 But we don't need this duplication when drawing them on map.
 We need to draw each one only once.
*/
const cleanDuplicates = festivals => {
  const usedIds = new Set();
  return festivals.filter(festival => {
    if (usedIds.has(festival.id)) {
      return false;
    }
    usedIds.add(festival.id);
    return true;
  });
};


const addMinorOffsetToSameLocations = festivals => {
  const existingLocations = new Set();
  const stringifyLocation = ({ lat, lng }) => `${lat},${lng}`;
  const addMinorOffsetToLocation = ({ lat, lng }) => ({
    lat: lat + (Math.random() - 0.5) / 1500,
    lng: lng + (Math.random() - 0.5) / 1500
  });
  return festivals.map(festival => {
    let { location } = festival;
    while (existingLocations.has(stringifyLocation(location))) {
      location = addMinorOffsetToLocation(location);
    }
    existingLocations.add(stringifyLocation(location));
    return {
      ...festival,
      location
    };
  });
};


const Container = ({ festivals, cities, city }) => (
  <div className="b-parties__map">
    <GoogleMap fullWidth={true}
               zoom={1}
               center={
      (cities[city] || {}).center_location
    }>
      {addMinorOffsetToSameLocations(
        cleanDuplicates(festivals)
      ).map((item, i) => <FestivalMarker key={i} festival={item} isFestivalPage = {true} />)}
    </GoogleMap>
  </div>
);

export const Map = withCities(Container);
