import { pure, compose, withProps, withState, lifecycle } from 'recompose';
import { withFormState } from 'firefly-hoc';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { login } from '../query/index';
import { toastSuccess } from '../utils/index';
import { Login as Container } from '../container/Login';
import { withT, withUser, withSettings } from '../hoc';

export const Login = compose(
  withT,
  withRouter,
  withSettings,
  withUser,
  graphql(login, { name: 'login' }),
  withProps(props => ({
    ...props,
    callback: form => props.login({ variables: form }),
    onSuccess({ response: { data: { login: { jwt } } } }) {
      localStorage.setItem('jwt', jwt);
      props.updateUser();
    }
  })),
  withState('authenticateTimeout', 'setAuthenticateTimeout'),
  withFormState,
  lifecycle({
    componentDidUpdate() {
      if (this.props.user) {
        toastSuccess('Successfully authenticated');
        this.props.history.push('/');
      }
    }
  }),
  pure
)(Container);
