import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Group, Button, ButtonType } from 'firefly/component';
import { getVKAuthUrl } from '../utils';
import vkIcon from '../img/vk.svg';
// import { FbAuth } from '../component/FbAuth';

export const Login = ({ t, onSubmit, errors, formLoading, form, onChange, user, settings }) => {
  return (
    <Fragment>
      <form onSubmit={onSubmit} className="">
        <Group
          errors={errors}
          loading={formLoading}
          values={form}
          onChange={onChange}
          fields={[
            {
              name: 'email',
              label: t('login.email'),
              type: 'text',
              required: false
            },
            {
              name: 'password',
              label: t('login.password'),
              type: 'password',
              required: false
            },
          ]}/>

        <Button
          type='submit'
          className="b-button__main"
          state={formLoading ? ButtonType.LOADING : null}>{t('login.login')}</Button>
        <br/> <br/>
        <div>
          <p className="b-social__text">{t('social_auth')}</p>
          <div className="b-social__auth">
            <a href={getVKAuthUrl(settings)}><img className="b-social__icon b-social__icon__login" src={vkIcon} alt="vk"/></a>
              {/*{settings.fb_app_id && <FbAuth appId={settings.fb_app_id}/>}*/}
          </div>
        </div>
        <p className="b-confirm">
          <NavLink className="b-login__dont-have-account b-login__dont-have-account__link b-login__dont-have-account__link" to="/password_recovery">{t('login.restore')}</NavLink>
        </p>
        <p className="b-login__dont-have-account">
          {t('login.dont_have_account')} <NavLink className="b-login__dont-have-account__link" to="/registration">{t('login.sign_up')}</NavLink>
        </p> <br/>
      </form>
    </Fragment>
  );
};
