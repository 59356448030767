import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, setPropTypes } from 'recompose';
import { Group } from 'firefly/component';
import { getValue } from 'firefly-hoc';

const Container = ({ internalValues, onChange, valuesLabelsMap }) => (
  <div className="b-checkbox-group">
    <Group
      values={internalValues}
      onChange={onChange}
      fields={
        Object.keys(internalValues).map(value => ({
          name: value,
          label: (valuesLabelsMap && valuesLabelsMap[value]) || value,
          type: 'checkbox',
          required: false
        }))
      }
    />
    <br/>
  </div>
);

// this component accepts array as its value and converts it into an object to display
// a group of checkboxes. when the state changes, it does reversed convertation to array
export const CheckboxGroup = compose(
  setPropTypes({
    value: PropTypes.array.isRequired,
    allPossibleValues: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired
  }),
  withProps(props => ({
    ...props,
    internalValues: props.allPossibleValues.reduce(
      (accumulator, value) => ({
        ...accumulator,
        [value]: Boolean(~(props.value.indexOf(value)))
      }), {}
    )
  })),

  withProps(props => ({
    ...props,
    onChange: (name, type = 'checkbox') => e => {
      const value = getValue(type, e);
      const newInternalValue = {
        ...props.internalValues,
        [name]: value
      };
      const newExternalValue = props.allPossibleValues.filter(v => newInternalValue[v]);
      props.onChange(newExternalValue);
    }
  }))
)(Container);
