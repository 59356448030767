import React, { Fragment } from 'react';
import { Redirect } from 'react-router-dom';
import { ButtonType, Button, Group } from 'firefly/component';

export const PasswordForm = ({ t, callback, errors, exchange, onSubmit, formLoading, form, onInputChange, success, headers }) => (
  <Fragment>
    {success === null
      ? <form className="b-password-form" onSubmit={onSubmit} >
        <div className="b-confirm-password">
          {headers}
          <br/>
          <Group
            errors={errors}
            loading={formLoading}
            values={form}
            onChange={onInputChange}
            fields={[
              {
                name: 'password',
                label: t('registration.password'),
                type: 'password',
                required: false
              },
              {
                name: 'password_confirm',
                label: t('registration.confirm_password'),
                type: 'password',
                required: false
              },
            ]}/>
        </div>
        <Button
          type='submit'
          state={formLoading ? ButtonType.LOADING : null}
          className="b-button b-button__main"
          >{t('auth.confirm')}</Button>
      </form>
      : <div className="b-confirm">
          <p className="b-confirm--text">{success ? <Redirect to="/"/> : t('auth.email_already_activated')}</p>
        </div>}
  </Fragment>
);
