import React, { PureComponent } from 'react';
import classNames from 'classnames';

export const generateTabPanelId = (parentId, tabId) => `b-tab-panel_${parentId}_${tabId}`;

export const generateTabTitleId = (parentId, tabId) => `b-tab-title_${parentId}_${tabId}`;

export class TabTitle extends PureComponent {
  handleClick = (e) => this.props.onClick(this.props.id, e);

  render() {
    const {
      disabled,
      id,
      title,
      parentId,
      className,
      selected
    } = this.props;

    return (
      <div
        aria-controls={generateTabPanelId(parentId, id)}
        aria-disabled={disabled}
        aria-expanded={selected}
        aria-selected={selected}
        className={classNames('b-tab', className)}
        data-tab-id={id}
        id={generateTabTitleId(parentId, id)}
        onClick={disabled ? undefined : this.handleClick}
        role="tab"
        tabIndex={disabled ? undefined : 0}>
        {title}
      </div>
    );
  }
}
