import gql from 'graphql-tag';

export const festivalList = gql`
  query festivalList($input: FestivalListInput!) {
    festivalList(input: $input) {
      meta {
        total
      }
      objects
    }
  }
  `;

export const festival = gql`
  query festival($id: Int) {
    festival(id: $id)
  }
`;
