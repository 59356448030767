import gql from 'graphql-tag';

export const partyAdminList = gql`
  query partyAdminList($input: PartyListAdminInput!) {
    partyAdminList(input: $input) {
      meta {
        page
        page_size
        page_count
        total
      }
      objects
    }
  }
`;

export const partyAdmin = gql`
  query partyAdmin($input: PartyQueryAdminInput!) {
    partyAdmin(input: $input)
  }
`;
