import React from 'react';
// import { NavLink } from 'react-router-dom';
import { withT } from '../hoc';
import facebookIcon from '../img/social-media-icons/facebook.svg';
import vkIcon from '../img/social-media-icons/vk.svg';
import telegramIcon from '../img/social-media-icons/telegram.svg';
import instargamIcon from '../img/social-media-icons/instagram.svg';
import { Link } from 'react-router-dom';

const Container = ({ t }) => (
  <div className="b-footer">
    <div className="b-footer__part">
      <Link className="b-footer__part__link" to="/doc/privacy">{t('footer.privacy_policy')}</Link>
      <Link className="b-footer__part__link" to="/doc/terms">{t('footer.terms_of_use')}</Link>
    </div>

    <div className="b-footer__part">
      <a href="/" target="_blank"><img className="b-footer__part__icon" src={telegramIcon} alt=""/></a>
      <a href="https://vk.com/moscowdance88" rel="noopener noreferrer" target="_blank"><img className="b-footer__part__icon" src={vkIcon} alt=""/></a>
      <a href="https://www.facebook.com/moscowdance88/" rel="noopener noreferrer" target="_blank"><img className="b-footer__part__icon" src={facebookIcon} alt=""/></a>
      <a target="_blank" href="https://www.instagram.com/panichmax/" rel="noopener noreferrer"><img className="b-footer__part__icon" src={instargamIcon} alt=""/></a>
    </div>

    <div className="b-footer__part">
      © 2018 All Rights Reserved by w2dance.com
    </div>
  </div>
);

export const Footer = withT(Container);
