import gql from 'graphql-tag';

export const cities = gql`
  query cities {
    cities {
      id
      name
      name_translations
      country
      center_location
    }
  }
  `;
export const citiesFestival = gql`
  query citiesFestival {
    citiesFestival {
      id
      name
      name_translations
      country
      center_location
    }
  }
  `;
export const citiesParty = gql`
  query citiesParty {
    citiesParty {
      id
      name
      name_translations
      country
      center_location
    }
  }
  `;
