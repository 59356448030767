import React, { Fragment } from 'react';
import { MinFestivals } from '.';

export const Grid = ({ festivals }) => (
  <Fragment>
    <div className="b-grid-events">
      <div className="b-grid-events__container">
        {festivals.map((festival, key) => (
          <div className="b-grid-events__container__party" key={key} >
            <MinFestivals {...festival} isFestivalPage={true} />
          </div>))}
      </div>
    </div>
  </Fragment>
);
