import React, { Fragment } from 'react';
import PartiesTable from '../../component/Table';
import Header from '../../../../component/Header';
import { withAdminT } from '../../../../hoc';


const Screen = props => (
  <Fragment>
    <Header pageTitle={props.t('pages.parties')}/>
    <PartiesTable {...props}/>
  </Fragment>
);

export default withAdminT(Screen);
