import React from 'react';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { EditParty as Component } from '../component/CreateEditParty';

export const EditParty = compose(
  withRouter,
  withProps(props => ({
    ...props,
    ...(
        props.match && props.match.params.partyId
            ? { partyId: +(props.match.params.partyId) }
            : { })
  }))
)(props => (
  <div>
    <Component {...props} />
  </div>
));
