import React from 'react';
import { Group, Button } from 'firefly/component';

export const OwnPartyForm = ({ t, onSubmit, onChange, form, formLoading, errors }) => (
  <form onSubmit={onSubmit} className="b-contact-us__form">
    <Group
      errors={errors}
      loading={formLoading}
      values={form}
      onChange={onChange}
      fields={[
        {
          name: 'party_id',
          label: t('contact_us.own_party_modal.party_link'),
          type: 'text',
          required: false
        },
        {
          name: 'contact_information',
          label: t('contact_us.own_party_modal.contact_information'),
          type: 'text',
          required: false
        },
        {
          name: 'letter',
          label: t('contact_us.own_party_modal.describe_yourself'),
          type: 'textarea',
          required: false
        }
      ]}
    />
    <p className="b-contact-us__form__help">{t('contact_us.own_party_modal.help')}</p>

    <Button className="b-button__main">{t('contact_us.own_party_modal.submit')}</Button>
  </form>
);
