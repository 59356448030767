import React, { Component } from 'react';
import PropTypes from 'prop-types';
import hamburgerIcon from "../../../img/hamburger.png";
import Sidebar from "../Sidebar";
import { ChangeLanguage } from "../ChangeLanguage";

function RefStore() {
  this.refs = {};
  this.setRef = name => ref => { this.refs[name] = ref; };
  this.getRef = name => this.refs[name];
}

export default class Header extends Component {
  static propTypes = {
    pageTitle: PropTypes.string.isRequired,
    elementsCount: PropTypes.number,
    history: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
  };

  static defaultProps = {
    counter: true,
    elementsCount: null
  };

  refStore = new RefStore();

  state = {
    active: false,
    is_menu_open: false
  };

  changeLanguage(language) {
    this.props.i18n.changeLanguage(language);
    this.refStore.getRef('popover').close();
  }

  render() {
    const {
      pageTitle,
      elementsCount,
    } = this.props;


    return (
      <div className="b-header-admin">
        <div>
          <div className="b-header-admin__container">
            <div className="b-header__leftPart b-header__leftPart--md b-sidebar__mobile-enabled">
              {!this.state.is_menu_open ? (
                <div onClick={() => {this.setState({is_menu_open: true})}} className="b-header__hamburger">
                  <Sidebar mobileMenuOpened={this.state.is_menu_open} />
                  <img className="b-header__hamburger__icon" src={hamburgerIcon} alt=""/>
                </div>
              ) : (
                <div onClick={() => {this.setState({is_menu_open: false})}} className="b-sidebar__mobile__bg_open">
                  <Sidebar mobileMenuOpened={this.state.is_menu_open} />
                </div>
              )}
            </div>

            <div className="b-header-admin__title">
              <span>{pageTitle}</span>
              {elementsCount !== null && (
                <div className="b-header-admin__number">
                  ({elementsCount})
                </div>
              )}
            </div>
            <div className="b-header-admin__part">
              <div>
                <ChangeLanguage/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
