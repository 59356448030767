import React, { Fragment } from 'react';
import { H1, Dialog, DialogBody, Button } from 'firefly/component';
import { Grid, Map, Filters } from '.';

const getCityName = (cityObj, i18n) => cityObj.name_translations[i18n.language.toLowerCase().slice(0, 2)];

export const Parties = ({ t, i18n, getQSValue, setQSValue, parties, suggestedCity, setSuggestedCity, cities, setDateRange }) => (
  <Fragment>
    <Dialog className="b-parties__dialog"
      isOpen={suggestedCity && suggestedCity !== +getQSValue('city')}
      onClose={() => setSuggestedCity(null)}>
      {cities[suggestedCity] && <DialogBody>
        <H1 className="b-parties__dialog__header">
          {t('parties.is_name_your_city', { name: getCityName(cities[suggestedCity], i18n) })}
        </H1>
        <div className="b-parties__dialog__buttons">
          <Button className="b-parties__dialog__buttons__button b-button__main" onClick={() => setQSValue('city')(suggestedCity)}>{t('yes')}</Button>
          <Button className="b-parties__dialog__buttons__button b-button__main" onClick={() => setSuggestedCity(null)}>{t('no')}</Button>
        </div>

      </DialogBody>}
    </Dialog>
    <Filters
      setQSValue={setQSValue}
      getQSValue={getQSValue}
      setDateRange={setDateRange}/>
    {parties.length === 0 && <H1 className="b-parties__not-found">{t('parties.not_found')}</H1>}
    {getQSValue('layoutStyle') === 'grid' ? <Grid parties={parties}/> : <Map parties={parties} city={getQSValue('city')}/>}
  </Fragment>
);
