import gql from 'graphql-tag';

export const settingsSave = gql`
  mutation settingsSave($input: SettingsInput!) {
    settingsSave(input: $input)
  }
`;

export const testing = gql`
  mutation testing($input: TestingQueryAdminInput) {
    testing(input: $input)
  }
`;
