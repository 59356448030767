import React from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import client from '../apolloClient';
import { Routes } from './Routes';
import history from '../historyStore';

export const Root = () => (
  <ApolloProvider client={client}>
    <Router history={history}>
      <Routes/>
    </Router>
  </ApolloProvider>
);
