import {compose, withProps, withState} from 'recompose';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withFormState } from 'firefly-hoc';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withCities, withUser } from '../../../../../hoc';
import { toastSuccess, formatDate, filterValues } from '../../../../../utils/index';
import { CreateEditFestivalContainer as Container } from './CreateEditFestivalContainer';
import { withAdminT } from '../../../../hoc';
import { isMainAdmin } from '../../../../../utils/user';
import consts from "../../../../../consts";

const getIds = objects => objects.map(object => object.id);

function RefStore() {
  this.refs = {};
  this.setRef = name => ref => { this.refs[name] = ref; };
  this.getRef = name => this.refs[name];
}

const prepareFestivalToLoad = festival => {
  const festival_id = festival.id;
  const festivalClone = { ...festival };

  const {
    vk: vk_link,
    fb: fb_link,
    tg: tg_link,
    website: website_link
  } = festival.links;

  delete festivalClone.created_at;
  delete festivalClone.updated_at;
  delete festivalClone.id;
  delete festivalClone.owner_id;


  return {
    ...festivalClone,
    festival_id,
    vk_link,
    fb_link,
    tg_link,
    website_link,
    dateRange: [festivalClone.start_date, festivalClone.finish_date]
  };
};


export const CreateEditFestival = compose(
  withAdminT,
  withCities,
  withUser,
  withRouter,
  withState('refStore', 'setRefStore', new RefStore()),
  withState('menuOpened', 'setMenuOpened', false),
  withProps(props => ({
    ...props,
    allAllowedCities: isMainAdmin(props.user)
      ? Object.keys(props.cities).map(Number)
      : getIds(props.user.ownedCity),
    initialForm: props.festival ? prepareFestivalToLoad(props.festival) : {},
    is_edit: Boolean(props.festival),
    callback: form => props.mutate({ variables: {
        input: filterValues({
          ...form,
          logo_path: undefined,
          links: {
            vk: form.vk_link,
            fb: form.fb_link,
            tg: form.tg_link,
            website: form.website_link
          },
          vk_link: undefined,
          fb_link: undefined,
          tg_link: undefined,
          dateRange: undefined,
          website_link: undefined,
          del: undefined,
          price: parseFloat(form.price),
          output_priority: +form.output_priority,
          currency: form.currency ? form.currency : consts.CURRENCY.RUB,
          location: form.location || ((props.cities || {})[form.city_id] || {}).center_location,
          start_date: form.dateRange[0]
            ? typeof form.dateRange[0] === "string"
              ? form.dateRange[0]
              : formatDate(moment(form.dateRange[0]))
            : null,
          finish_date: form.dateRange[1]
            ? typeof form.dateRange[1] === "string"
              ? form.dateRange[1]
              : formatDate(moment(form.dateRange[1]))
            : null,
        })
      } }),
  })),
  withProps(props => ({
    onSuccess() {
      toastSuccess('Success!');
      if (!props.is_edit) {
        props.history.goBack();
      }
    },
    onSelected(refName) {
      props.refStore.getRef(refName).close();
    }
  })),
  withFormState,
)(Container);
