import moment from 'moment';
import React, { Component } from 'react';
import shallowequal from 'shallowequal';
import cx from 'classnames';
import qs from 'qs';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import * as Icons from 'react-feather';
import {
  Pagination,
  Segment,
  SpinnerOverlay
} from 'firefly/component';
import BaseTable from '../../../../component/Table';
import TableSearch from '../Search';
import { filterValues } from '../../../../../utils';

export default class Table extends Component {
  popover = undefined;

  static propTypes = {
    search: PropTypes.bool,
    settings: PropTypes.object,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    filter: PropTypes.object,
    loading: PropTypes.bool,
    objects: PropTypes.array,
    meta: PropTypes.object,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    search: true,
    loading: true,
    filter: {},
    objects: [],
    settings: {},
    meta: {}
  };

  refHandlers = {
    popover: ref => {
      this.popover = ref;
    }
  };

  state = {
    user: null,
    isOpen: false,
    search: false
  };


  componentDidUpdate(prevProps, prevState) {
    if (false === shallowequal(this.props.filter, prevProps.filter)) {
      this.props.refetch(this.props.filter);
    }
  }

  removeEmptyParameters(params = {}) {
    return filterValues({ ...this.props.filter, ...params });
  }

  redirect(params = {}) {
    const queryString = qs.stringify(this.removeEmptyParameters(params));
    const uri = `${this.props.location.pathname}?${queryString}`;
    this.props.history.push(uri);
  }

  refresh() {
    this.redirect({ _t: new Date().getTime() });
  }

  onChangeOrder = order => {
    this.redirect({ order });
  };


  toggle = user => e => {
    e.preventDefault();

    this.setState({
      user,
      isOpen: !this.state.isOpen
    });
  };

  getColumns() {
    moment.locale('ru');
    return [
      {
        key: x => (
          x.first_name ? `${x.first_name} ${x.last_name}` : 'не указано'
        ),
        sortable: 'name',
        title: 'name'
      },
      {
        key: x => (
          <Link to={`/admin/users/${x.id}`}>
            {x.email || 'не указан'}
          </Link>
        ),
        sortable: 'email',
        title: 'email'
      },
      {
        key: x => x.is_active && (
          <span className={cx('b-currency-link', { 'b-currency-link_enabled': x.is_active })}>
            <Icons.Check/>
          </span>
        ),
        sortable: 'is_active',
        title: 'is_active'
      },
      // {
      //   key: x => (
      //     <button
      //       onClick={this.toggleAdmin(x)}
      //       className={cx('b-currency-link', { 'b-currency-link_enabled': x.is_admin })}>
      //       {x.is_admin ? (
      //         <Icons.Check/>
      //       ) : (
      //         <Icons.Minus className='m-color m-color--inactive'/>
      //       )}
      //     </button>
      //   ),
      //   sortable: 'is_admin',
      //   title: t('user_list.table.is_admin')
      // },
      {
        key: x => moment(x.created_at).format('lll'),
        sortable: 'created_at',
        title: 'registration_date'
      },
      // {
      //   key: x => !x.is_active && (
      //     <Popover ref={this.refHandlers.popover} modifiers='minimal'>
      //       <button className='b-more'>
      //         <Icon className='b-more__icon' icon='MoreVertical'/>
      //       </button>
      //       <Menu>
      //         <MenuItem
      //           text="Удалить"/>
      //       </Menu>
      //     </Popover>
      //   ),
      // }
    ];
  }

  // onRemove = x => () => {
  //   const {
  //     t,
  //     remove,
  //     fetch
  //   } = this.props;
  //
  //   if (window.confirm(t('confirm_remove'))) {
  //     this.popover.close();
  //     remove(x.id).then(() => {
  //       fetch();
  //     });
  //   }
  // };

  onSuccess = () => {
    this.setState({
      isOpen: false
    }, () => this.refresh());
  };

  render() {
    const {
      search,
      loading,
      meta,
      objects,
      filter,
      t
    } = this.props;


    return (
      <SpinnerOverlay show={loading}>
        {search && (
          <Segment>
            <TableSearch
              t={t}
              filter={filter}
              onSuccess={filter => this.redirect(filter)}/>
          </Segment>
        )}
        <Segment>
          <BaseTable
            order={filter.order}
            onSort={this.onChangeOrder}
            columns={this.getColumns().map(column => ({ ...column, title: t(`users_page.${column.title}`) }))}
            data={objects}/>
          {meta.page_count > 1 && (
            <Pagination
              scrollToTop={false}
              pageSize={meta.page_size}
              onPageSizeChange={/* eslint-disable */ page_size /* eslint-enable */ => {
                return this.redirect({ page_size });
              }}
              onPageChange={page => this.redirect({ page })}
              pageCount={meta.page_count || 1}
              page={meta.page || 1}
              total={meta.total || 1}/>
          )}
        </Segment>
      </SpinnerOverlay>
    );
  }
}
