import { compose, withState, lifecycle } from 'recompose';
import { graphql } from 'react-apollo';
import { findUsersCityId } from '../query';
import { getCurrentPositionAsync } from '../utils/index';

export const withCurrentCity = compose(
  graphql(findUsersCityId, { name: 'findUsersCityID' }),
  withState('currentCity', 'setCurrentCity', null),
  lifecycle({
    async componentDidMount() {
      const { coords: { latitude: lat, longitude: lng } } = await getCurrentPositionAsync();
      const { data: { findUsersCityID: currentCity } } = await this.props.findUsersCityID({
        variables: {
          lat,
          lng
        }
      });

      if (currentCity) {
        this.props.setCurrentCity(currentCity);
      }
    }
  })
);
