import BigNumber from 'bignumber.js';
import moment from 'moment';
import { toaster } from '../root/Notifications';
import client from '../apolloClient';
import config from '../config';

BigNumber.config({
  DECIMAL_PLACES: 20,
  ROUNDING_MODE: 4
});

export const bigNumberify = value => new BigNumber(value);

export const toNumber = value => value instanceof BigNumber ? value.toNumber() : value;

export const toastError = message => toaster.show({
  message: message || 'Ошибка',
  modifiers: ['error']
});

export const toastSuccess = message => toaster.show({
  message: message || 'Выполнено',
  modifiers: ['success']
});

export const filterValues = obj => {
  const newObj = {};

  Object.keys(obj).forEach(key => {
    if (obj[key] || obj[key] === false) {
      newObj[key] = obj[key];
    }
  });

  return newObj;
};

export const stringifyValues = obj => {
  return Object.keys(obj).reduce((prevState, key) => {
    if (!Array.isArray(obj)) {
      return {
        ...prevState,
        [key]: typeof obj[key] === 'object' ? stringifyValues(obj[key]) : String(obj[key])
      };
    }
    return [
      ...prevState,
      typeof obj[key] === 'object' ? stringifyValues(obj[key]) : String(obj[key])
    ];
  }, Array.isArray(obj) ? [] : {});
};

export const formatDate = m => m.format('DD.MM.YY');
export const formatedDateToMoment = string => moment(string, 'DD.MM.YY');

export const writeLocalState = data => process.nextTick(() => client.writeData({ data }));

export const getFullImgUrl = path => `${config.s3url}${path}`;

export const stringToMoment = dateString => (
  moment(dateString).startOf('day')
);

export const extractPartyIdFromLink = link => {
  const pattern = new RegExp(`${window.location.host}/party/([0-9]+)`);
  const result = pattern.exec(link);
  return result && result.length === 2 && +result[1];
};

export const getCurrentPositionAsync = () => new Promise(
  (resolve, reject) => window.navigator.geolocation.getCurrentPosition(resolve, reject)
);

export const isUrlCorrect = url => (/[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&/=]*)?/gi).test(url);

export const isHttpUrlCorrect = url => (/^https?:\/\//).test(url);

export const getVKAuthUrl = settings => `https://oauth.vk.com/authorize?client_id=${settings.vk_client_id}&display=page&redirect_uri=${window.location.protocol}//${window.location.host}/vkAuth&scope=email&response_type=code&v=5.103`;

export const getFBAuthUrl = settings => `https://graph.facebook.com/oauth/authorize?client_id=${settings.fb_client_id}&scope=email&display=page&redirect_uri=${window.location.protocol}//${window.location.host}/login`;
