import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose, withProps, withState } from 'recompose';
import { withT } from '../../hoc';
import flags from "../../img/flags";
import { Popover } from "firefly/component/Popover";

function RefStore() {
  this.refs = {};
  this.setRef = name => ref => { this.refs[name] = ref; };
  this.getRef = name => this.refs[name];
}

const Container = ({ changeLanguage, i18n, refStore, refName }) => (
  <Popover ref={refStore.setRef(refName)} popperModifiers={{ arrow: false }}>
    <p className="b-popover--content b-header__rightPart__lang">
      <img className="b-popover--content b-header__rightPart__lang__icon" src={flags[i18n.language.toLowerCase().slice(0, 2)]} alt=""/>
      {i18n.language.slice(0, 2)}
    </p>
    <div>
      {Object.keys(i18n.store.data).map((language, key) => (
        <p className="b-popover__content__item" key={key} onClick={() => changeLanguage(language, refName)}>
          <img className="b-popover--content b-header__rightPart__lang__icon" src={flags[language.toLowerCase()]} alt=""/>
          {language.toUpperCase()}
        </p>
      ))}
    </div>
  </Popover>
);

export const ChangeLanguage = compose(
  withRouter,
  withT,
  withState('refStore', 'setRefStore', new RefStore()),
  withProps(props => ({
    ...props,
    changeLanguage(language, refName) {
      props.refStore.getRef(refName).close();
      props.i18n.changeLanguage(language);
    },
  })),
)(Container);
