import { compose, withProps, withState } from 'recompose';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withFormState } from 'firefly-hoc';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withCities, withUser } from '../../../../../hoc';
import { toastSuccess, formatedDateToMoment, formatDate, filterValues } from '../../../../../utils/index';
import { CreateEditPartyContainer as Container } from './CreateEditPartyContainer';
import { withAdminT } from '../../../../hoc';
import { isMainAdmin } from '../../../../../utils/user';

const getIds = objects => objects.map(object => object.id);

const preparePartyToLoad = party => {
  const party_id = party.id;
  const partyClone = { ...party };
  const {
    vk: vk_link,
    fb: fb_link,
    tg: tg_link,
    website: website_link
  } = party.links;

  delete partyClone.created_at;
  delete partyClone.updated_at;
  delete partyClone.id;
  delete partyClone.owner_id;

  return {
    ...partyClone,
    party_id,
    vk_link,
    fb_link,
    tg_link,
    website_link,
    single_date: partyClone.single_date ? formatedDateToMoment(partyClone.single_date) : null,
    days_of_week: moment.weekdaysShort(
      (partyClone && partyClone.days_of_week) ? partyClone.days_of_week[0] : []
    ),
    start_time: moment(partyClone.start_time, 'hh:mm'),
    ...(partyClone.finish_time ? { finish_time: moment(partyClone.finish_time, 'hh:mm') } : {})
  };
};


export const CreateEditParty = compose(
  withAdminT,
  withCities,
  withUser,
  withRouter,
  withState('menuOpened', 'setMenuOpened', false),
  withProps(props => ({
    ...props,
    allAllowedCities: isMainAdmin(props.user)
      ? Object.keys(props.cities).map(Number)
      : getIds(props.user.ownedCity),
    initialForm: props.party ? preparePartyToLoad(props.party) : {},
    is_edit: Boolean(props.party),
    callback: form => props.mutate({ variables: {
      input: filterValues({
        ...form,
        logo_path: undefined,
        links: {
          vk: form.vk_link,
          fb: form.fb_link,
          tg: form.tg_link,
          website: form.website_link
        },
        vk_link: undefined,
        fb_link: undefined,
        tg_link: undefined,
        website_link: undefined,
        del: undefined,
        price: parseFloat(form.price),
        output_priority: +form.output_priority,
        partyType: form.partyType || 'INDOOR',
        is_one_time_event: form.is_one_time_event || false,
        location: form.location || ((props.cities || {})[form.city_id] || {}).center_location,
        single_date: (form.single_date && formatDate(moment(form.single_date))),
        days_of_week: form.is_one_time_event
          ? moment.weekdaysShort().indexOf(moment.weekdaysShort(form.single_date.getDay()))
          : (form.days_of_week || []).split(' ').map(day => moment.weekdaysShort().indexOf(day)),
        start_time: (form.start_time
          ? moment(form.start_time).format('HH:mm')
          : moment(new Date(0, 0, 0, 0, 0, 0, 0)).format('HH:mm')),
        ...(form.finish_time
          ? { finish_time: moment(form.finish_time).format('HH:mm') }
          : { finish_time: moment(new Date(0, 0, 0, 0, 0, 0, 0)).format('HH:mm') })
      })
    } }),
  })),
  withProps(props => ({
    onSuccess() {
      toastSuccess('Success!');
      if (!props.is_edit) {
        props.history.goBack();
      }
    }
  })),
  withFormState,
)(Container);
