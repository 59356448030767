import { Dialog, DialogBody, H2 } from "firefly/component";
import { Link } from 'react-router-dom';
import React from "react";


export const NotAuthorized = ({ modalProfileEdit, setModalProfileEdit, t }) => (
    <Dialog isOpen={modalProfileEdit} onClose={() => setModalProfileEdit(false)}>
        <DialogBody>
          <H2>{t('not_authorized')}</H2>
          <p><Link to="/login">{t('please_authorized')}</Link></p>
        </DialogBody>
    </Dialog>
);