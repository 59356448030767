import gql from 'graphql-tag';

export const companySettings = gql`
  query settings {
    settings {
      google_analytics_id
      vk_client_id
      vk_client_secret
      fb_client_id
      fb_client_secret
      fb_app_id
      hashtag_social
      leftBorder
      rightBorder
    }
  }
`;
