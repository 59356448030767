import gql from 'graphql-tag';

export const me = gql`
  query me {
    me {
      id
      email
      first_name
      last_name
      avatar_path
      is_active
      type
      ownedCity
      ownedParty
      ownedFestival
      dance_style
      agree_terms
    }
  }
  `;
