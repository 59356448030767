import React, { Component, Fragment } from 'react';
import shallowequal from 'shallowequal';
import moment from 'moment';
import qs from 'qs';
import * as Icons from 'react-feather';
import PropTypes from 'prop-types';
import { Pagination, Segment, SpinnerOverlay, Button, DialogBody, H2, Dialog } from 'firefly/component';
import BaseTable from '../../../../component/Table';
import TableSearch from '../Search';
import consts from '../../../../../consts';
import { filterValues } from '../../../../../utils';
import { hasPermission } from '../../../../../utils/user';

const getCityName = (cityEntry, i18n) => cityEntry.name_translations[i18n.language.slice(0, 2).toLowerCase()] || cityEntry.name;


export default class Table extends Component {
  static propTypes = {
    refetch: PropTypes.func.isRequired,
    setIsOpenDeleteModal: PropTypes.func.isRequired,
    deleteParty: PropTypes.func.isRequired,
    setDelParty: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    filter: PropTypes.object,
    search: PropTypes.bool,
    loading: PropTypes.bool,
    objects: PropTypes.array,
    meta: PropTypes.object,
    delParty:  PropTypes.object,
    isOpenDeleteModal: PropTypes.bool,
    user: PropTypes.object.isRequired,
    i18n: PropTypes.object.isRequired,
    cities: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    search: true,
    loading: true,
    filter: {},
    objects: [],
    meta: {},
    delParty: {},
    isOpenDeleteModal: false
  };

  state = {
    search: false,
    createPartyOpened: false,
    editPartyOpened: false,
    editPartyId: null,
  };

  componentDidUpdate(prevProps) {
    if (false === shallowequal(this.props.filter, prevProps.filter)) {
      this.refetch(this.props.filter);
    }
  }

  removeEmptyParameters(params = {}) {
    return filterValues({
      ...this.props.filter,
      ...params
    });
  }

  redirect(params = {}) {
    const queryString = qs.stringify(this.removeEmptyParameters(params));

    const { history, location } = this.props;

    history.push(`${location.pathname}?${queryString}`);
  }

  refetch(params = {}) {
    this.props.refetch(this.removeEmptyParameters(params));
  }

  onChangeOrder = order => {
    this.redirect({ order });
  };

  getColumns() {
    const { i18n, cities } = this.props;

    if (this.props.user.type === "user_main_admin") {
      return  this.getColumnsMainAdmin();
    }
    return [
      {
        key: x => (x.name || <Icons.Minus />),
        sortable: 'name',
        title: 'name',
      },
      {
        key: x => x.address,
        title: 'address',
      },
      {
        key: x => getCityName(cities[x.city_id], i18n),
        title: 'city',
      },
      {
        key: x => moment(x.createdAt).format('lll'),
        sortable: 'created_at',
        title: 'creation_date'
      },
      {
        key: x => <Button target="_blank" className="b-button--flex--center" to={`/admin/parties/edit/${x.id}`}>open</Button>,
        title: 'edit'
      }
    ];
  }

  getColumnsMainAdmin() {
    const { i18n, cities } = this.props;
    return [
      {
        key: x => (x.name || <Icons.Minus />),
        sortable: 'name',
        title: 'name',
      },
      {
        key: x => x.address,
        title: 'address',
      },
      {
        key: x => getCityName(cities[x.city_id], i18n),
        title: 'city',
      },
      {
        key: x => moment(x.createdAt).format('lll'),
        sortable: 'created_at',
        title: 'creation_date'
      },
      {
        key: x => <Button target="_blank" className="b-button--flex--center" to={`/admin/parties/edit/${x.id}`}>open</Button>,
        title: 'edit'
      },
      {
        key: x => <Button className="b-button--red b-button--flex--center" onClick={() => {
          this.props.setDelParty(x);
          this.props.setIsOpenDeleteModal(true)
        }}>DEL</Button>,
        title: 'delete'
      }
    ];
  }

  render() {
    const { search, filter, objects, meta, loading, user, t, delParty, isOpenDeleteModal, setIsOpenDeleteModal, deleteParty } = this.props;

    moment.locale('ru');
    return (
      <Fragment>

        <SpinnerOverlay show={loading}>
          {search && (
            <Segment>
              <div className="b-input-group">
                <TableSearch
                  t={t}
                  filter={filter}
                  onSuccess={filter => this.redirect(filter)}
                />

                {hasPermission(user, consts.USER_HAS_CITIES)
                && <Button style={{ marginLeft: 'auto' }} className="b-button--flex--center" to='/admin/parties/create'>{t('parties_page.create')}</Button>
                }
              </div>
            </Segment>)}
          {objects.length > 0 ? (<Segment>
            <BaseTable
              order={filter.order}
              onSort={this.onChangeOrder}
              columns={this.getColumns().map(column => ({ ...column, title: t(`parties_page.${column.title}`) }))}
              data={objects}
            />
            {meta.page_count > 1 && (
              <Pagination
                scrollToTop={false}
                pageSize={parseInt(filter.page_size, 10)}
                onPageSizeChange={page_size => this.redirect({ page_size })}
                onPageChange={page => this.redirect({ page })}
                pageCount={meta.page_count || 1}
                page={parseInt(filter.page || 1, 10)}
                total={meta.total || 1}
              />
            )}
            {user.type === "user_main_admin" && (
              <Dialog isOpen={isOpenDeleteModal} onClose={() => setIsOpenDeleteModal(false)}>
                <DialogBody>
                  <H2>{delParty.name}?</H2>
                  <Button
                    onClick={() => {
                      deleteParty({ variables: { input: { party_id: delParty.id } } });
                      setIsOpenDeleteModal(false);
                    }}
                    type='submit'
                    modifiers={['flex--center', 'full-width', 'color-white']}>{t('delete')}</Button>
                </DialogBody>
              </Dialog>
            )}
          </Segment>) : (
            <Segment>
              {t('orders_page.payments_not_found')}
            </Segment>
          )}
        </SpinnerOverlay>
      </Fragment>
    );
  }
}
