import { onError } from 'apollo-link-error';
import { toastError } from '../utils/index';

export const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (let i = 0; i < graphQLErrors.length; i++) {
      console.log('[GraphQL error]: ', graphQLErrors[i]);

      if (graphQLErrors[i].message !== 'Validation error') {
        toastError(graphQLErrors[i].message);
      }
    }
  } else if (networkError) {
    toastError(networkError.toString());
    console.log(`[Network error]: ${networkError}`);
  } else {
    toastError();
  }
});
