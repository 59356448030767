import React from 'react';
import { compose, lifecycle, withState } from 'recompose';
import { Summary } from '../component/ContactUs';
import { withT } from '../hoc';
import { writeLocalState } from '../utils/index';
import bg_top from '../img/main-bg/lg-top.png';
import bg_bottom from '../img/main-bg/lg-bottom.png';
import { ButtonBack } from '../module/ButtonBack';

const ContactUsScreen = () => (
  <div className="b-contact-us">
    <ButtonBack goToPage="/"/>
    <Summary/>
  </div>
);

export const ContactUs = compose(
  withT,
  withState('ownCityModal', 'setOwnCityModal', false),
  withState('ownPartyModal', 'setOwnPartyModal', false),
  withState('suggestPartyModal', 'setSuggestPartyModal', false),
  withState('suggestPartnershipModal', 'setSuggestPartnershipModal', false),
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom, bg_top_position: 'top left', bg_bottom_position: 'bottom right' });
    }
  })
)(ContactUsScreen);
