import React from 'react';
import { Link } from 'react-router-dom';
import logoImg from '../../img/dance_icon.png';

export default function logo() {
  return (
    <Link to="/" className="b-sidebar__logo__container">
      <img className="b-sidebar__logo" src={logoImg} alt=""/>
    </Link>
  );
}
