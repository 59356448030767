import { compose, withState, withProps, lifecycle } from 'recompose';
import { graphql } from 'react-apollo';
import { festivalList } from '../../query';
import { Festivals as Container } from '../../container/Festivals';
import { withCities, withCurrentCity, withT, withUser, withQSStorage } from '../../hoc';

const getDefaultSetting = settingName => localStorage.getItem(`setting_${settingName}`);
const setDefaultSetting = (settingName, value) => localStorage.setItem(`setting_${settingName}`, value);

export const Festivals = compose(
  withT,
  withCurrentCity,
  withUser,
  withCities,
  withProps(props => ({
    ...props,
    nullQSValues: {
      festivalTypes: []
    },
    defaultQSValues: {
      city: getDefaultSetting('city') || 0,
      year: getDefaultSetting('year') || null,
      month: getDefaultSetting('month') || null,
      festivalTypes: getDefaultSetting('festivalTypes') ? JSON.parse(getDefaultSetting('festivalTypes')) : [],
      layoutStyle: 'grid'
    }
  })),
  withQSStorage,
  withState('suggestedCity', 'setSuggestedCity', null),
  graphql(festivalList, { options: props => ({
      variables: {
        input: {
          city_id: +props.getQSValue('city'),
          month: +props.getQSValue('month'),
          year: +props.getQSValue('year'),
          dance_styles: props.getQSValue('festivalTypes') || []
        }
      }
    })
  }),
  withProps(props => ({
    ...props,
    festivals: ((props.data.festivalList || {}).objects || []),
  })),
  lifecycle({
    componentDidUpdate(prevProps) {
      const { props } = this;
      if (prevProps.currentQSParams.city !== props.currentQSParams.city) {
        setDefaultSetting('city', props.currentQSParams.city);
      } else if (prevProps.currentQSParams.festivalTypes !== props.currentQSParams.festivalTypes) {
        setDefaultSetting('festivalTypes', JSON.stringify(props.currentQSParams.festivalTypes));
      }

      if (!prevProps.currentCity && props.currentCity && !getDefaultSetting('city')) {
        if (props.defaultQSValues.city === props.getQSValue('city')) { // not mutated from original value
          props.setSuggestedCity(props.currentCity);
        }
      }
    }
  }),
)(Container);
