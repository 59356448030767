import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withFormState } from 'firefly-hoc';
import shallowequal from 'shallowequal';
import { compose, withProps, lifecycle } from 'recompose';
import { companySettings, settingsSave } from '../../query';
import { toastSuccess, toastError, filterValues, stringifyValues } from '../../../../../utils';
import Settings from './Settings';


import {
  // CoinpaymentsForm,
  CompanyForm,
  Testing
} from '../../component';
import { withAdminT } from '../../../../hoc';


const mapping = {
  basics: CompanyForm,
  testing: Testing,
  // coinpayments: CoinpaymentsForm,
};


export default compose(
  withAdminT,
  withRouter,
  graphql(companySettings, {
    options: () => ({
      fetchPolicy: 'network-only'
    })
  }),
  graphql(settingsSave),
  withProps(props => ({
    ...props,
    mapping,
    callback(form) {
      return props.mutate({
        variables: {
          input: stringifyValues(filterValues(form))
        }
      });
    },

    onSuccess({ response: { data } }) {
      const { t } = props;
      if (data.settingsSave) {
        toastSuccess(t('basic_settings_page.successfully_saved'));
      }
      props.data.refetch();
    },
    onValidationError(errors) {
      Object.keys(errors)
        .forEach(fieldName => errors[fieldName]
          .forEach(error => toastError(error)));
    },
  })),
  withFormState,
  lifecycle({
    componentDidUpdate(prevProps) {
      const { props } = this;
      if (!shallowequal(props.data.settings, prevProps.data.settings)) {
        props.setForm(props.data.settings);
      }
    }
  })
)(Settings);
