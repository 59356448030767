import React from 'react';
import moment from 'moment/moment';
import { Part, Popover } from 'firefly/component';
import { DateRangePicker } from 'firefly/component/DateTime';
import { CheckboxGroup } from '../../module/CheckboxGroup';
import allPartyTypes from '../../component/Parties/partyTypesMock';
import { formatDate, formatedDateToMoment } from '../../utils';
import { CitySelectorParties } from '../../module/CitySelectorParties';

import { Toggle } from '../../module/Toggle';
import { withT } from '../../hoc';

const formatDatePeriod = (since, till, t) => since.diff(till, 'days') !== 0
  ? `${formatDate(since)} - ${formatDate(till)}`
  : `${t(moment.weekdaysShort(since.weekday()))} ${formatDate(since)}`;

const partyListToString = (partyList, t) => partyList.length ? partyList.join(', ') : t('parties.filters.any');

export const Filters = withT(({ t, getQSValue, setQSValue, setDateRange }) => (
  <div className="b-parties__filters">
    <div className="b-parties__filters__part">
      <div className="b-parties__filters__popover-picker b-parties__filters__popover-picker--city">
        <p className="b-parties__filters__popover-picker__label">
          {t('parties.filters.city')}
        </p>
        <CitySelectorParties onChange={setQSValue('city')} cityId={getQSValue('city')} className="b-parties__filters__popover-picker__content"/>
      </div>

      <div className="b-parties__filters__popover-picker">
        <p className="b-parties__filters__popover-picker__label">
          {t('parties.filters.when')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-parties__filters__popover-picker__content">
            {formatDatePeriod(formatedDateToMoment(getQSValue('dateRange').since), formatedDateToMoment(getQSValue('dateRange').till), t)}
          </div>
          <DateRangePicker
            minDate={moment().startOf('day').toDate()}
            maxDate={moment().add(6, 'days').endOf('day').toDate()}
            shortcuts={false}
            allowSingleDayRange={true}
            defaultValue={[formatedDateToMoment(getQSValue('dateRange').since).toDate(), formatedDateToMoment(getQSValue('dateRange').till).toDate()]}
            onChange={setDateRange}
            contiguousCalendarMonths={false}
          />
        </Popover>
      </div>

      <div className="b-parties__filters__popover-picker">
        <p className="b-parties__filters__popover-picker__label">
          {t('parties.filters.party_types')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-parties__filters__popover-picker__content">
            {partyListToString(getQSValue('partyTypes'), t)}
          </div>
          <div>
            <CheckboxGroup
              value={getQSValue('partyTypes')}
              onChange={setQSValue('partyTypes')}
              allPossibleValues={allPartyTypes}
            />
          </div>
        </Popover>
      </div>
    </div>

    <div className = "b-parties__filters__part">
      <div className="b-parties__filters__toggle">
        <Toggle value={getQSValue('layoutStyle')}
          onChange={setQSValue('layoutStyle')}
          items={[{ value: 'grid', text: t('parties.filters.grid') }, { value: 'map', text: t('parties.filters.map') }]} />
      </div>
    </div>
  </div>
));
