import React, { Component } from 'react';
export default class Settings extends Component {
  render() {
    const {
      mapping,
      match: {
        params: { name }
      }
    } = this.props;

    const Form = mapping[name] || null;

    return (
      <div>
        {Form ? (
          <Form {...this.props}/>
        ) : <div>Not found</div>}
      </div>
    );
  }
}
