import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { publicSettings } from './query';
import apolloClient from './apolloClient';

import { writeLocalState } from './utils/index';

const historyInstance = createBrowserHistory();
historyInstance.listen(() => {
  window.scrollTo(0, 0); // scroll to top every history update
  writeLocalState({ is_menu_open: false }); // try to close mobile menu every history update
});


if (process.env.NODE_ENV === 'production') {
  apolloClient.query({ query: publicSettings })
    .then(({ data: { publicSettings: { google_analytics_id } } }) => {
      if (google_analytics_id) {
        ReactGA.initialize(google_analytics_id);
        ReactGA.pageview(window.location.pathname + window.location.search);
        historyInstance.listen(location => ReactGA.pageview(location.pathname + location.search));
      }
    });
}

export default historyInstance;
