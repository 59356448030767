import {compose, withProps} from 'recompose';
// eslint-disable-next-line import/no-extraneous-dependencies
import { withAdminT } from '../../../../hoc';
import { FeedbackModal as Container } from './ViewFeedbackModal';

export const FeedbackModal = compose(
  withAdminT,
  withProps(props => ({
    ...props,
  })),
)(Container);
