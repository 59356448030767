import React from 'react';
import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { EditFestival as Component } from '../component/CreateEditFestival';

export const EditFestival = compose(
  withRouter,
  withProps(props => ({
    ...props,
    festivalId: +(props.match.params || {}).festivalId
  }))
)(props => (
  <div>
    <Component {...props} />
  </div>
));
