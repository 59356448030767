import React, { Fragment } from 'react';
import moment from 'moment';
import { getFullImgUrl, isUrlCorrect, isHttpUrlCorrect } from '../utils/index';
import { ExternalLink } from 'react-feather';
import { PartyMarker } from '../component';
import rubIcon from '../img/currencies/rub-gold-color.svg';
import rubCashbackIcon from '../img/currencies/rub-cashback.svg';
import calendarIcon from '../img/calendar-icon.svg';
import noteIcon from '../img/note-icon.svg';
import locationIcon from '../img/location-icon.svg';
import defaultPartyLogo from '../img/default-party-logo.png';


import { Map } from '../module/Map';
import { ModalEditProfile } from '../component/ModalEditProfile';
import { NotAuthorized } from '../component/NotAuthorized';
import {Button} from "firefly/component/Button";

const addTimeToDate = (date, start_time, finish_time) => [date, [start_time, ...(finish_time ? [finish_time] : [])].join(' - ')].join('; ');

const MaybeRenderLinks = ({ name, links }) => {
  if (isUrlCorrect((links || {}).website)) {
    return (<p className="b-event-page__info__content__header">
      <a className="b-event-page__info__content__header__name-link"
         href={isHttpUrlCorrect(links.website) ? links.website: `http://${links.website}`}
         rel="noopener noreferrer"
         target="_blank">{name}</a>
      <a className="b-event-page__external-link"
         href={isHttpUrlCorrect(links.website) ? links.website: `http://${links.website}`}
         rel="noopener noreferrer"
         target="_blank">
        <ExternalLink/>
      </a>
    </p>)
  }
  if (isUrlCorrect((links || {}).fb)) {
    return (<p className="b-event-page__info__content__header">
      <a className="b-event-page__info__content__header__name-link"
         href={isHttpUrlCorrect(links.fb) ? links.fb: `http://${links.fb}`}
         rel="noopener noreferrer"
         target="_blank">{name}</a>
      <a className="b-event-page__external-link"
         href={isHttpUrlCorrect(links.fb) ? links.fb: `http://${links.fb}`}
         rel="noopener noreferrer"
         target="_blank">
        <ExternalLink/>
      </a>
    </p>)
  }
  if (isUrlCorrect((links || {}).vk)) {
    return (<p className="b-event-page__info__content__header">
      <a className="b-event-page__info__content__header__name-link"
         href={isHttpUrlCorrect(links.vk) ? links.vk: `http://${links.vk}`}
         rel="noopener noreferrer"
         target="_blank">{name}</a>
      <a className="b-event-page__external-link"
         href={isHttpUrlCorrect(links.vk) ? links.vk: `http://${links.vk}`}
         rel="noopener noreferrer"
         target="_blank">
        <ExternalLink/>
      </a>
    </p>)
  }
  return <p className="b-event-page__info__content__header">{name}</p>;
};

const checkRequireProfile = (user) => {
  return (!user.email || !user.first_name || !user.last_name || !user.dance_style || !user.agree_terms);
}

export const Party = ({ party, t, user, setModalProfileEdit, modalProfileEdit, create_pass_ticket }) => {
  const { name, price, logo_path, links, description, dance_styles, address, is_one_time_event, days_of_week, single_date, start_time, finish_time, cancelled } = party;
  return (
    <Fragment>
      {user && (
        <ModalEditProfile modalProfileEdit={modalProfileEdit} setModalProfileEdit={setModalProfileEdit}/>
      )}
      {(!user && modalProfileEdit) && (
        <NotAuthorized modalProfileEdit={modalProfileEdit} setModalProfileEdit={setModalProfileEdit}/>
      )}
      <div className="b-event-page__info">
        <div className="b-event-page__info__content">
          <p className="b-event-page__info__content__header__mobile">{name} <MaybeRenderLinks links={links}/> </p>
        </div>
        <div className="b-event-page__info__image">
          <img className="b-event-page__info__image__img"
            src={logo_path ? getFullImgUrl(logo_path) : defaultPartyLogo} alt=""
          />
          {cancelled ? (
            <div className="b-event-page__info__cancelled">
              <p className="b-event-page__info__cancelled-text b-event-page__info__cancelled-text--flex-center">{t('cancelled_event')}</p>
            </div>
            ) : (
            ''
          )}
        </div>

        <div className="b-event-page__info__content">
          <MaybeRenderLinks name={name} links={links}/>
          <div className="b-event-page__info__content__crumbs">
            <div className="b-event-page__info__content__crumbs__item">
              <img className="b-event-page__info__content__crumbs__item__img" src={calendarIcon} alt=""/>
              {is_one_time_event ? (
                addTimeToDate(single_date, start_time, finish_time) //todo
              ) : (
                addTimeToDate(days_of_week.map(day => t(moment.weekdaysShort(day))).join(', '), start_time, finish_time)
              )}
            </div>

            {dance_styles.length > 0 && <div className="b-event-page__info__content__crumbs__item">
              <img className="b-event-page__info__content__crumbs__item__img" src={noteIcon} alt=""/>
              {dance_styles.map((style, id) => <div className="b-event-page__info__content__crumbs__item__style"
                key={id}>{style}</div>)}
            </div>}

            <div className="b-event-page__info__content__crumbs__item b-event-page__info__content__crumbs__item--no-wrap">
              <a rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/?q=${party.location.lat},${party.location.lng}`}>
                <img className="b-event-page__info__content__crumbs__item__img" src={locationIcon} alt=""/>
              </a>
              <a rel="noopener noreferrer" target="_blank" href={`https://maps.google.com/?q=${party.location.lat},${party.location.lng}`}>
                <p>{address}</p>
              </a>
            </div>
          </div>
          <p className="b-event-page__info__content__text">{description}</p>
        </div>
      </div>

      <div className="b-event-page__map">
        <Map fullWidth={true} center={party.location}>
          <PartyMarker party={party}/>
        </Map>
      </div>
    </Fragment>);
};
