const USER_REGULAR = 'user_regular';
const USER_MAIN_ADMIN = 'user_main_admin';
const USER_HAS_PARTIES = 'user_has_parties';
const USER_HAS_FESTIVALS = 'user_has_festivals';
const USER_HAS_EVENTS = 'user_has_events';
const USER_HAS_CITIES = 'user_has_cities';
const OWN_CITY_TICKET = 'own_city_ticket';
const OWN_PARTY_TICKET = 'own_party_ticket';
const SUGGEST_PARTY_TICKET = 'suggest_party_ticket';
const SUGGEST_PARTNERSHIP_TICKET = 'suggest_partnership_ticket';

const TYPES_OF_PARTIES = {
  'INDOOR': 10,
  'OUTDOOR': 20,
};

const CURRENCY = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD'
}

export default {
  USER_REGULAR,
  USER_MAIN_ADMIN,
  USER_HAS_PARTIES,
  USER_HAS_CITIES,
  OWN_CITY_TICKET,
  OWN_PARTY_TICKET,
  SUGGEST_PARTY_TICKET,
  SUGGEST_PARTNERSHIP_TICKET,
  TYPES_OF_PARTIES,
  USER_HAS_FESTIVALS,
  USER_HAS_EVENTS,
  CURRENCY
};
