import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { ButtonType, Button, Group, Dialog, DialogBody, H4, Popover } from 'firefly/component';
import { CheckboxGroup } from "../module/CheckboxGroup";
import allPartyTypes from "../component/Parties/partyTypesMock";

const partyListToString = (partyList, t) => partyList.length ? partyList.join(', ') : t('not_chosen');


export const Registration = ({ t, onSubmit, errors, formLoading, form, onChange, isEmailAvailable, showSuccessMessage, onCloseSuccessMessage }) => (
  <Fragment>
    <Dialog isOpen={showSuccessMessage} onClose={onCloseSuccessMessage}>
      <DialogBody>
        <H4 className="b-dialog__body__text">{t('registration.confirming_email_sent')}</H4>
        <Button className="b-button__main" onClick={onCloseSuccessMessage}>ОК</Button>
      </DialogBody>
    </Dialog>

    <form onSubmit={onSubmit} className="b-account__form">
      <div>
        <Group
          errors={errors}
          loading={formLoading}
          values={form}
          onChange={onChange}
          fields={[
            {
              name: 'email',
              label: t('registration.email'),
              type: 'text',
              required: false
            },
            {
              name: 'first_name',
              label: t('registration.first_name'),
              type: 'text',
              required: false
            },
            {
              name: 'last_name',
              label: t('registration.last_name'),
              type: 'text',
              required: false
            },
            {
              name: 'password',
              label: t('registration.password'),
              type: 'password',
              required: false
            },
            {
              name: 'password_confirm',
              label: t('registration.confirm_password'),
              type: 'password',
              required: false
            },
          ]} />
        <div className="b-form__row">
          <label className="b-label b-label--focus">
            {t('registration.dance_style')}
          </label>
          <div className="b-input">
            <Popover position="left">
              <div className="b-filters__popover-picker__content">
                {partyListToString(form.dance_style || [], t)}
              </div>
              <div>
                <CheckboxGroup
                  value={form.dance_style || []}
                  onChange={onChange('dance_style', 'free')}
                  allPossibleValues={allPartyTypes}
                />
              </div>
            </Popover>
          </div>
        </div>


        <div className="checkbox">
          <Group
            errors={errors}
            loading={formLoading}
            values={form}
            onChange={onChange}
            className="checkbox"
            fields={[
              {
                name: 'agree_terms',
                type: 'checkbox',
                label: <div> I agree to <Link target="_blank" className="link" to="/doc/terms">Terms of Use</Link> & <Link target="_blank" className="link" to="/doc/privacy">Private Policy</Link></div>,
                required: false
              }
            ]} />

        </div>


        <Button
          type='submit'
          className="b-button__main"
          state={formLoading ? ButtonType.LOADING : null}>{t('registration.sign_up')}</Button>
      </div>


    </form>
  </Fragment>
);
