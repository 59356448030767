import React from 'react';
import { Switch } from 'react-router-dom';
import { LayoutRoute } from './LayoutRoute';
import { Layout } from './Layout';
import { SidebarLayout } from '../admin/component/SidebarLayout';
import { Route } from 'react-router-dom';
import AuthPrivateRoute from './AuthPrivateRoute';
import consts from '../consts';
import * as screens from '../screens';
import * as userAdminScreens from '../admin/module/user/screens';
import * as settingsAdminScreens from '../admin/module/settings/screens';
import * as partyAdminScreens from '../admin/module/parties/screens';
import { VkAuth, FbAuth } from '../component';
import * as festivalAdminScreens from '../admin/module/festivals/screens';
import * as feedbackAdminScreens from '../admin/module/feedback/screens';


export const Routes = () => (
  <Switch>
    <Route exact path="/vkAuth" component={VkAuth} />
    <Route exact path="/fbAuth" component={FbAuth} />
    <LayoutRoute exact layout={Layout} path='/' component={screens.Homepage}/>
    <LayoutRoute exact layout={Layout} path='/parties' component={screens.Parties}/>
    <LayoutRoute exact layout={Layout} path='/party/:id' component={screens.Party}/>
    <LayoutRoute exact layout={Layout} path='/festivals' component={screens.Festivals}/>
    <LayoutRoute exact layout={Layout} path='/festival/:id' component={screens.Festival}/>
    <LayoutRoute exact layout={Layout} path='/about' component={screens.About}/>
    <LayoutRoute exact layout={Layout} path='/development' component={screens.InDevelopment}/>
    <LayoutRoute exact layout={Layout} path='/login' component={screens.Login}/>
    <LayoutRoute exact layout={Layout} path='/registration' component={screens.Registration}/>
    <LayoutRoute exact layout={Layout} path='/contacts' component={screens.ContactUs}/>
    <LayoutRoute exact layout={Layout} path='/doc/terms' component={screens.Terms}/>
    <LayoutRoute exact layout={Layout} path='/doc/privacy' component={screens.Privacy}/>
    <LayoutRoute exact layout={Layout} path='/doc/agreement' component={screens.Agreements}/>
    <LayoutRoute exact layout={Layout} path='/doc/refund' component={screens.Refund}/>
    <LayoutRoute exact layout={Layout} path='/restore-password/:token' component={screens.RestorePassword}/>
    <LayoutRoute exact layout={Layout} path='/contacts/suggest_party' component={screens.SuggestParty}/>
    <LayoutRoute exact layout={Layout} path='/contacts/become_admin' component={screens.BecomeAdmin}/>
    <LayoutRoute exact layout={Layout} path='/contacts/edit_party' component={screens.EditParty}/>
    <LayoutRoute exact layout={Layout} path='/contacts/cooperation' component={screens.Cooperation}/>
    <LayoutRoute exact layout={Layout} path="/registration/confirm" component={screens.RegistrationConfirm}/>
    <LayoutRoute exact layout={Layout} path="/password_recovery" component={screens.PasswordRecovery}/>

    <AuthPrivateRoute exact path='/admin/users/list' layout={SidebarLayout} minPermission={consts.USER_MAIN_ADMIN} component={userAdminScreens.List} />
    <AuthPrivateRoute exact path='/admin/users/:id' layout={SidebarLayout} minPermission={consts.USER_MAIN_ADMIN} component={userAdminScreens.View} />
    <AuthPrivateRoute exact path='/admin/settings/:name' layout={SidebarLayout} minPermission={consts.USER_MAIN_ADMIN} component={settingsAdminScreens.Settings}/>
    <AuthPrivateRoute exact path='/admin/parties' layout={SidebarLayout} minPermission={consts.USER_HAS_EVENTS} component={partyAdminScreens.List}/>
    <AuthPrivateRoute exact path='/admin/parties/create' layout={SidebarLayout} minPermission={consts.USER_HAS_CITIES} component={partyAdminScreens.CreateParty}/>
    <AuthPrivateRoute exact path='/admin/parties/edit/:partyId' layout={SidebarLayout} minPermission={consts.USER_HAS_EVENTS} component={partyAdminScreens.EditParty}/>
    <AuthPrivateRoute exact path='/admin/festivals' layout={SidebarLayout} minPermission={consts.USER_HAS_EVENTS} component={festivalAdminScreens.List}/>
    <AuthPrivateRoute exact path='/admin/festivals/create' layout={SidebarLayout} minPermission={consts.USER_HAS_CITIES} component={festivalAdminScreens.CreateFestival}/>
    <AuthPrivateRoute exact path='/admin/festivals/edit/:festivalId' layout={SidebarLayout} minPermission={consts.USER_HAS_EVENTS} component={festivalAdminScreens.EditFestival}/>
    <AuthPrivateRoute exact path='/admin/feedback' layout={SidebarLayout} minPermission={consts.USER_MAIN_ADMIN} component={feedbackAdminScreens.List}/>

    <LayoutRoute component={screens.NoMatch}/>
  </Switch>
);
