import React from 'react';
import { Popover } from 'firefly/component';
import { CheckboxGroup } from '../../module/CheckboxGroup';
import allFestivalTypes from '../../component/Festivals/festivalTypesMock';
import { CitySelectorFestivals } from '../../module/CitySelectorFestivals';
import { YearSelector } from '../../module/YearSelector';
import { MonthSelector } from '../../module/MonthSelector';

import { Toggle } from '../../module/Toggle';
import { withT } from '../../hoc';

const festivalListToString = (festivalList, t) => festivalList.length ? festivalList.join(', ') : t('parties.filters.any');

const listYears = [new Date().getFullYear(), new Date().getFullYear()+1];

export const Filters = withT(({ t, getQSValue, setQSValue }) => (
  <div className="b-filters">
    <div className="b-filters__part">
      <div className="b-filters__popover-picker b-filters__popover-picker--city">
        <p className="b-filters__popover-picker__label">
          {t('festivals.filters.city')}
        </p>
        <CitySelectorFestivals aroundTheWorld={true} defaultSelectValue='festivals.default_select_city' onChange={setQSValue('city')} cityId={getQSValue('city')} className="b-filters__popover-picker__content"/>
      </div>
        <div className="b-filters__popover-picker__month-and-year">
          <div className="b-filters__popover-picker">
            <p className="b-filters__popover-picker__label">
              {t('month')}
            </p>
            <MonthSelector onChange={setQSValue('month')} monthId={getQSValue('month')}
                           className="b-filters__popover-picker__content"/>
          </div>
          <div className="b-filters__popover-picker">
            <p className="b-filters__popover-picker__label">
              {t('year')}
            </p>
            <YearSelector onChange={setQSValue('year')} yearChosen={getQSValue('year')}
                          listYears={listYears}
                          className="b-filters__popover-picker__content"/>
          </div>
        </div>

      <div className="b-filters__popover-picker">
        <p className="b-filters__popover-picker__label">
          {t('festivals.filters.festival_types')}
        </p>
        <Popover position="bottom" popperModifiers={{ arrow: false }}>
          <div className="b-filters__popover-picker__content">
            {festivalListToString(getQSValue('festivalTypes'), t)}
          </div>
          <div>
            <CheckboxGroup
              value={getQSValue('festivalTypes')}
              onChange={setQSValue('festivalTypes')}
              allPossibleValues={allFestivalTypes}
            />
          </div>
        </Popover>
      </div>
    </div>

    <div className = "b-filters__part">
      <div className="b-filters__toggle">
        <Toggle value={getQSValue('layoutStyle')}
                onChange={setQSValue('layoutStyle')}
                items={[{ value: 'grid', text: t('festivals.filters.grid') }, { value: 'map', text: t('festivals.filters.map') }]} />
      </div>
    </div>
  </div>
));
