import React from 'react';
import { Icon } from 'firefly/component';
import { Link } from 'react-router-dom';
import { SidebarBadge } from './SidebarBadge';

const SidebarRowContainer = ({ className, history, icon, onClick, text, count, to }) => to ? (
  <Link
    onClick={onClick}
    to={to}
    className={className}>
    {icon && <Icon icon={icon} className='b-sidebar__icon'/>}
    <span className="b-sidebar__span">{text}</span>
    <SidebarBadge count={count}/>
  </Link>
) : (
  <div
    onClick={onClick}
    className={className}>
    {icon && <Icon icon={icon} className='b-sidebar__icon'/>}
    <span className="b-sidebar__span">{text}</span>
    <SidebarBadge count={count}/>
  </div>
);

export const SidebarRow = SidebarRowContainer;
