import React from 'react';
import { compose } from 'recompose';
import { H4 } from 'firefly/component';
import { SuggestPartyForm } from '../component/ContactUs';
import { withT } from '../hoc';
import {ButtonBack} from "../module/ButtonBack";

const SuggestPartyScreen = ({ t }) => (
  <div className="b-contact-us">
    <ButtonBack goToPage="/contacts"/>
    <div className="b-contact-us__summary">
      <div className="b-contact-us__summary__container__view">
        <div className="b-contact-us__summary__container__block__view">
          <H4 className="b-contact-us__summary__container__block__header__view">
            {t('contact_us.summary.suggest_party.header')}
          </H4>
          <p className="b-contact-us__summary__container__block__description">
            {t('contact_us.summary.suggest_party.description')}
          </p>
          <SuggestPartyForm/>
        </div>
      </div>
    </div>
  </div>
);

export const SuggestParty = compose(
  withT,
)(SuggestPartyScreen);
