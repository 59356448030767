import React from 'react';
import { Route } from 'react-router-dom';

const render = ({ user, layout: Layout, component: WrapComponent }) => props => Layout ? (
  <Layout>
    <WrapComponent {...props} />
  </Layout>
) : (
  <WrapComponent {...props} />
);

export const LayoutRoute = ({ component, layout, ...rest }) => (
  <Route {...rest} render={render({ layout, component })}/>
);
