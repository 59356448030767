import React from 'react';
import { compose, lifecycle } from 'recompose';
import { H1 } from 'firefly/component';
import { Festivals as Component } from '../component/Festivals';
import { withT } from '../hoc';
import { writeLocalState } from '../utils/index';
import bg_top from '../img/events-bg/lg.png';
import {ButtonBack} from "../module/ButtonBack";

const FestivalsScreen = withT(({ t }) => (
  <div className="b-parties">
    <ButtonBack/>
    <H1 className="b-parties__header">{t('festivals.header')}</H1>
    <Component/>
  </div>
));

export const Festivals = compose(
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(FestivalsScreen);
