import gql from 'graphql-tag';

export const own_party_ticket = gql`
  mutation own_party_ticket($party_id: Int, $contact_information: String, $letter: String)  {
    own_party_ticket(party_id: $party_id, contact_information: $contact_information, letter: $letter)
  }
  `;

export const own_city_ticket = gql`
  mutation own_city_ticket($city_id: Int, $name: String, $contact_information: String, $letter: String) {
    own_city_ticket(city_id: $city_id, name: $name, contact_information: $contact_information, letter: $letter) 
  }
`;

export const suggest_party = gql`
  mutation suggest_party($input: SuggestPartyInput!) {
    suggest_party(input: $input)
  }
`;

export const suggest_partnership = gql`
  mutation suggest_partnership($name: String, $letter: String, $contact_information: String) {
    suggest_partnership(name: $name, letter: $letter, contact_information: $contact_information)
  }
`;
