import qs from 'qs';
import { withRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import List from './List';
import { graphql } from 'react-apollo/index';
import { userList } from '../../query';
import { withSettings } from '../../../settings/withSettings';
import { withAdminT } from '../../../../hoc';


export default compose(
  withAdminT,
  withRouter,
  withSettings,
  withProps(props => ({
    ...props,
    filter: {
      page: '1',
      page_size: '10',
      ...qs.parse(props.location.search.slice(1))
    },
  })),
  graphql(userList, {
    options: props => ({
      variables: {
        input: props.filter
      },
      fetchPolicy: 'network-only',
    }),
  }),
  withProps(props => ({
    ...props,
    refetch: filter => props.data.refetch({ input: filter }),
    ...(props.data.userList || {})
  }))
)(List);
