import { compose, withProps, branch, renderComponent } from 'recompose';
import { graphql } from 'react-apollo';
import { cities } from '../query';
import { SpinnerGlobal } from 'firefly/component/Spinner';

export const withCities = compose(
  graphql(cities),
  withProps(props => ({
    ...props,
    cities: (props.data.cities || []).reduce(
      // [{name: ..., id: ..., }, ] => {id: {name: ..., id: ..., }, }
      (accumulator, value) => ({ ...accumulator, [value.id]: value }),
      {}
    )
  })),
  branch(props => props.data.loading, renderComponent(SpinnerGlobal))
);
