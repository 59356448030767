import { Dialog, DialogBody, H2 } from "firefly/component";
import { Link } from 'react-router-dom';
import React from "react";
import { Group } from "firefly/component/Form";
import { Popover } from "firefly/component/Popover";
import { CheckboxGroup } from "../module/CheckboxGroup";
import allPartyTypes from "../component/Parties/partyTypesMock";
import { Button } from "firefly/component/Button";

const partyListToString = (partyList, t) => partyList.length ? partyList.join(', ') : t('not_chosen');

export const ModalEditProfile = ({ modalProfileEdit, setModalProfileEdit, t, onChange, onSubmit, errors, user, form }) => {
  user = (user || {});
  return (
    <Dialog isOpen={modalProfileEdit} onClose={() => setModalProfileEdit(false)}>
      <DialogBody>
        <H2>{t('edit_profile')}</H2>
        <form onSubmit={onSubmit} className="b-account__form">
          <div>
            <Group
              errors={errors}
              values={form}
              onChange={onChange}
              fields={[
                {
                  name: 'email',
                  label: t('registration.email'),
                  type: 'text',
                  required: false
                },
                {
                  name: 'first_name',
                  label: t('registration.first_name'),
                  type: 'text',
                  required: false
                },
                {
                  name: 'last_name',
                  label: t('registration.last_name'),
                  type: 'text',
                  required: false
                },
              ]}/>
            <div className="b-form__row">
              <label className="b-label b-label--focus">
                {t('registration.dance_style')}
              </label>
              <div className="b-input">
                <Popover position="left">
                  <div className="b-filters__popover-picker__content">
                    {partyListToString(form.dance_style || [], t)}
                  </div>
                  <div>
                    <CheckboxGroup
                      value={form.dance_style || []}
                      onChange={onChange('dance_style', 'free')}
                      allPossibleValues={allPartyTypes}
                    />
                  </div>
                </Popover>
              </div>
            </div>
            {!user.agree_terms && (
              <div className="checkbox">
                <Group
                  errors={errors}
                  values={form}
                  onChange={onChange}
                  className="checkbox"
                  fields={[
                    {
                      name: 'agree_terms',
                      type: 'checkbox',
                      label: <div> I agree to <Link target="_blank" className="link" to="/doc/terms">Terms of
                        Use</Link> & <Link target="_blank" className="link" to="/doc/privacy">Private Policy</Link>
                      </div>,
                      required: false
                    }
                  ]}/>
              </div>
            )}

            <Button
              type='submit'
              className="b-button__main">{t('save')}</Button>
          </div>


        </form>
      </DialogBody>
    </Dialog>
  )
};