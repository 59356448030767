import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

export class Toggle extends Component {
  static propTypes = {
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired
  };


  render() {
    const { items, onChange, value: currentValue } = this.props;

    return (
      <div className="b-toggle">
        {items.map((item, key) => (
          <div onClick={() => onChange(item.value)}
            key={key}
            className={cx('b-toggle__item', { 'b-toggle__item--active': currentValue === item.value })}>
            {item.text}
          </div>
        ))}
      </div>
    );
  }
}
