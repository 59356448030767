import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { NavLink } from 'react-router-dom';
import { Divider } from 'firefly/component';
import { withT, withUser } from '../hoc';
import {hasPermission, logout} from '../utils/user';
import consts from "../consts";


const Container = ({ i18n, t, className, user, changeLanguage, updateUser }) => (
  <div className={className}>
    <NavLink exact to="/" className="link">{t('menu.home')}</NavLink>
    { /* <NavLink exact to="/about" className="link">{t('menu.about')}</NavLink> */ }
    <NavLink exact to="/parties" className="link">{t('menu.events')}</NavLink>
    <NavLink exact to="/festivals" className="link">{t('menu.festivals')}</NavLink>
    <NavLink exact to="/contacts" className="link">{t('menu.contact_us')}</NavLink>
    {user
    ? hasPermission(user, consts.USER_HAS_EVENTS) && (
      <NavLink exact to="/admin/parties" className="link">{t('menu.admin_panel')}</NavLink>
      )
      : <></>
    }

    <Divider/> <br/>
    {!user
      ? (<NavLink exact to="/login" className="link">{t('menu.login')}</NavLink>)
      : <>
          <div className="link">{user.email}</div>
          <div onClick={logout(updateUser)} className={'link'}>{t('menu.logout')}</div>
      </>
    }
  </div>
);

Container.propTypes = {
  'user': PropTypes.object.isRequired,
  'changeLanguage': PropTypes.isRequired,
  'updateUser': PropTypes.func.isRequired,
  't': PropTypes.func.isRequired,
  'i18n': PropTypes.object.isRequired,
  'className': PropTypes.string.isRequired,
};

export const MobileMenu = compose(
  withT,
  withUser
)(Container);
