import React from 'react';
import gql from 'graphql-tag';
import cx from 'classnames';
import { Query } from 'react-apollo';
import { Footer, Header, MobileMenu } from '../container';

const layoutQuery = gql`{
  bg_top  @client
  bg_top_position @client
  bg_bottom  @client
  bg_bottom_position @client
  is_menu_open @client
}`;

export const Layout = ({ children }) => (
  <Query query={layoutQuery}>
    {({ data }) => (
      <div className={cx('b-content', { 'b-content--opened': data.is_menu_open })}>
        <div className={cx('b-layout', { 'b-layout--opened': data.is_menu_open })}>
          <div
            className={cx('b-layout__container')}
            style={{
              backgroundImage: `url(${data.bg_top}), url(${data.bg_bottom})`,
              backgroundPosition: `${data.bg_top_position}, ${data.bg_bottom_position}`
            }}>
            <Header />
            <div className="b-page">
              {children}
            </div>
            <Footer/>
          </div>
        </div>

        <MobileMenu className={cx('b-mobile-menu', { 'b-mobile-menu--opened': data.is_menu_open })}/>

        <div className={cx('b-content__decorative-layer1', { 'b-content__decorative-layer1--opened': data.is_menu_open })}/>
        <div className={cx('b-content__decorative-layer2', { 'b-content__decorative-layer2--opened': data.is_menu_open })}/>
      </div>
    )}
  </Query>

);
