import { compose, withProps, withState } from 'recompose';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import { withFormState } from 'firefly-hoc';
import { registration } from '../query/index';
import { Registration as Container } from '../container/Registration';
import { withT } from '../hoc/index';

export const Registration = compose(
  withT,
  graphql(registration),
  withRouter,
  withState('showSuccessMessage', 'setShowSuccessMessage', false),
  withProps(props => ({
    ...props,
    callback: form => props.mutate({
      variables: {
        input: form
      }
    }),
    onSuccess() {
      props.setShowSuccessMessage(true);
    },
    onCloseSuccessMessage() {
      props.history.push('/');
    }
  })),
  withFormState
)(Container);
