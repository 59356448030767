import React, { Fragment } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { getFullImgUrl, formatDate, stringToMoment } from '../../utils/index';
import calendarIcon from '../../img/parties-page/calendar-icon.png';
import locationIcon from '../../img/parties-page/location-icon.png';
import rubIcon from '../../img/currencies/rub.png';
import rubCashbackIcon from '../../img/currencies/rub-cashback.svg';
import defaultPartyLogo1 from '../../img/default-party-logo-short.svg';
import defaultPartyLogo2 from '../../img/default-party-logo2-short.svg';
import { withT } from '../../hoc';

const defaultPartyLogos = [defaultPartyLogo1, defaultPartyLogo2];
const getRandomArrayValue = array => array[Math.floor(Math.random() * array.length)];

const InfoItem = ({ children, img, Wrapper = Fragment }) => (
  <div className="b-party__info__item">
    <div className="b-party__info__item__icon"><img src={img} alt=""/></div>
    <p className="b-party__info__item__text"><Wrapper>{children}</Wrapper></p>
  </div>
);

const sliceString = str => str.length > 19 ? str.slice(0,19) + '...' : str;

const buildDateString = ({ is_one_time_event, days_of_week, single_date, t }) => (
  is_one_time_event ? (
    formatDate(stringToMoment(single_date))
  ) : (
    days_of_week.map(day => t(moment.weekdaysShort(day))).join(', ')
  )
);

const addTimeToDate = (date, start_time, finish_time) => [date, [start_time, ...(finish_time ? [finish_time] : [])].join(' - ')].join(' ');


export const MinParty = withT(({ t, timestamp, start_time, finish_time, address, price, currency, cancelled, logo_path, dance_styles, is_one_time_event, days_of_week, single_date, id, alwaysDisplaySingleDate = true, isPartyPage }) => (
  <Link className="no-decoration" to={{ pathname: `/party/${id}`, state: { isPartyPage } }}>
    <div className="b-party">
      <div className="b-party__img-container">
        <img className="b-party__img-container__img"
          src={logo_path ? getFullImgUrl(logo_path) : getRandomArrayValue(defaultPartyLogos)} alt=""
        />
        {cancelled ? (
          <div className="b-event-page__info__cancelled">
            <p className="b-event-page__info__cancelled-text b-event-page__info__cancelled-text--flex-center">{t('cancelled_event')}</p>
          </div>
          ) : (
            ''
        )}
        <div className="b-party__img-container__styles">
          {dance_styles.map((styleName, key) => (
            <div key={key} className="b-party__img-container__styles__item b-party__img-container__styles__item__preview">{styleName}</div>
          ))}
        </div>
      </div>
      <div className="b-party__info">
        <InfoItem img={calendarIcon}>{
          alwaysDisplaySingleDate
            ? addTimeToDate(formatDate(moment(timestamp)), start_time, finish_time)
            : buildDateString({ is_one_time_event, days_of_week, single_date, t })
        }</InfoItem>
        <InfoItem img={locationIcon}>{sliceString(address)}</InfoItem>
        { (price > 0) && <InfoItem img={rubIcon}>
          <div className="b-price--min">
            <div className="b-price-full--min">{price}</div>
            <p className="b-price-cashback">cashback 100 <img className="b-price--icon-cashback--mini"
                   src={rubCashbackIcon}
                   alt=""/>
            </p>
          </div>
        </InfoItem> }
      </div>
    </div>
  </Link>
));
