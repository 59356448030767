import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Button, Icon, Input, Popover } from 'firefly/component';
import FilterForm from '../FilterForm';

export default class Search extends Component {
  popover = undefined;

  static propTypes = {
    className: PropTypes.string,
    filter: PropTypes.object,
    onSuccess: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
  };

  refHandlers = {
    popover: ref => {
      this.popover = ref;
    }
  };

  static defaultProps = {
    className: undefined,
    filter: {}
  };

  state = {
    filter: {}
  };

  componentDidMount() {
    this.setState({
      filter: this.props.filter
    });
  }

  onSearch = e => {
    if (e) {
      e.preventDefault();
    }
    this.props.onSuccess(this.state.filter);
    this.popover.close();
  };

  onFilterFormSuccess = ({ resp, form }) => {
    const filter = {
      ...this.state.filter,
      ...form
    };

    this.setState({ filter }, () => this.onSearch());
  };

  onChange = e => {
    const filter = {
      ...this.state.filter,
      q: e.target.value
    };
    this.setState({ filter });
  };

  render() {
    const {
      className,
      t
    } = this.props;

    const {
      filter
    } = this.state;

    return (
      <form
        className={cx('b-table-search', className)}
        onSubmit={this.onSearch}>
        <div className="b-group">
          <div className="b-input-group">
            <Input
              type='text'
              name="q"
              placeholder={t('users_page.search')}
              value={filter.q || ''}
              onChange={this.onChange}
              className='b-input' />
            <Button type='submit' modifiers='icon'>
              <Icon icon='Search' />
            </Button>
          </div>
          <div className="b-input-group">
            <Popover ref={this.refHandlers.popover}>
              <Button type='button' modifiers='icon'>
                <Icon icon='Filter' />
              </Button>
              <FilterForm t={t} form={filter} onSuccess={this.onFilterFormSuccess} />
            </Popover>
          </div>
        </div>
      </form>
    );
  }
}
