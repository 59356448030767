import React from 'react';
import { H1, H2, Transition } from 'firefly/component';
import {ButtonBack} from "../module/ButtonBack";

export const Refund = () => (
  <Transition>
    <ButtonBack goToPage="/"/>
    <H1>Refund and Return policy</H1>

    <H2>1. BACKGROUND</H2>

    <div className="b-typeset">
      <p>
        KSExchange OU guarantees to any its User his/her right for refund, in case
        if such User is not satisfied with the quality of the provided Services.
      </p>
      <p>
        Furthermore, KSExchange OU declares that its User has the right
        to change his/her mind in case if the User’s Account was already
        funded and to request his/her Funds to be returned.
      </p>
      <p>
        Every Refund and Return are treated by KSExchange OU with reasonable care and skill.
      </p>
      <p>NOTE:</p>
      <p>
        This Refund Policy concerns exclusively Transaction fees and Users’ Funds.
        This Refund Policy does not cover any transactions  related to
        purchase-sale of crypto-currencies, as well as GHS, and it does not
        refer to any refunds for purchased crypto-currencies and GHS.
        KSExchange OU undertakes to make its best efforts to assist the Users
        in case of any disputes related to refunds of purchased crypto-currencies and GHS.
        Refunds and Returns in excess of the original amount are prohibited.
      </p>
    </div>

    <H2>2. DEFINITIONS</H2>
    <div className="b-typeset">
      <p>Banking Day : ( cf. Payment Policy).</p>
      <p>Business Day : means every official working day of the week in the United Kingdom.</p>
      <p>Chargeback : means return of the User’s money by his/her Issuing Bank due to the disputed and/or fraudulent
        transaction.</p>
      <p>Eligibility Criteria : means the set of requirements that must be met for Refund or Return.</p>
      <p>Funding : ( cf. Payment Policy).</p>
      <p>Return Form : means the KSExchange OU’s form to be completed by the User, who has
        requested for his/her Funds to be returned.</p>
      <p>Funds : ( cf. Payment Policy).</p>
      <p>Issuing Bank : means the bank that has issued payment card to the User as Cardholder.</p>
      <p>Refund original amount : means the precise amount of Transaction fee deducted from the amount of certain User’s
        Transaction.</p>
      <p>Refund Form : means the KSExchange OU’s form to be completed by the User, who has requested the Refund.</p>
      <p>Refund : means giving back the amount of Transaction fee paid by the User with regard to certain
        Transaction.</p>
      <p>Return original amount : means the precise amount of the User’s Funds accumulated on his/her Account.</p>
      <p>Return : means giving back the User’s Funds transferred to his/her Account.</p>
      <p>Transaction Charges : means any fee charged by a bank/financial institution upon processing a valid refund
        request.</p>
      <p>All other terms and definitions in this Refund Policy are used in the same meaning as under the Terms of
        Use.</p>
    </div>

    <H2>3. GENERAL CONDITIONS</H2>
    <div className="b-typeset">
      <p>The User who has paid for the KSExchange OU Services, i.e. paid
        a transaction fee, or has funded his account may request a Refund
        or Return in accordance with the Eligibility Criteria as further set out herein.</p>
      <p>Refund/Return will only be considered where the User complies with the Eligibility
        Criteria in full. Where the User fails to meet any of the Eligibility Criteria,
        KSExchange OU shall have the right, in its sole discretion, to decline the User’s
        request for a Refund/Return.</p>
      <p>In order to apply for a Refund/Return, the User must request and complete a Refund
        Form/Return Form and send the respective form to the KSExchange OU support address.</p>
      <p>You can obtain the Refund/Return Form under request sent to support@crypto-card.exchange</p>
      <p>To prevent Prohibited Conduct, all payments and information related to Refund/Return may be verified by
        KSExchange OU.</p>
      <p>
        In such case, KSExchange OU may request the User to provide certain documents, including, but not limited to,
        identification documents, copy of the User’s Payment Card and Invoice or/and any other prove of the fact that
        disputed payment was made. In case if the User fails to provide appropriate documents or information within
        three
        (3) days upon the KSExchange OU request or in case of any doubts as to authenticity of provided documents,
        KSExchange OU shall be entitled to decline the User’s Refund/Return request.
      </p>
      <p>
        KSExchange OU shall process the User’s Refund Form/Return Form as soon as is reasonably practicable. Response
        times
        will vary depending on stated reasons for the request. In any case, KSExchange OU shall notify the User on the
        outcome of the request in accordance with the timescales set out herein.
      </p>
      <p>
        Refund/Return request will only be approved or declined after meticulous verification made by KSExchange OU.
      </p>
      <p>NOTE: Submission of Refund Form/Return Form does not guarantee that the User’s request will be satisfied.</p>
    </div>

    <H2>4. REFUND ELIGIBILITY CRITERIA</H2>
    <div className="b-typeset">
      <p>(i) All requests for Refund will only be accepted where notice is received by KSExchange OU no later than
        forty-eight</p>
      <p>(48) hours after made Transaction.</p>
      <p>(ii) Notice mentioned in para (i) above should be provided to KSExchange OU by completing Refund Form.</p>
      <p>(iii) Completed Refund Form should be sent to the KSExchange OU electronic address with ' Refund Request’ in the
        subject line.</p>
    </div>

    <H2>5. RETURN ELIGIBILITY CRITERIA</H2>
    <div className="b-typeset">
      <p>(i) All requests for Refund should be provided to KSExchange OU by completing Return Form.</p>
      <p>(ii) Completed Return Form should be sent to the KSExchange OU electronic address with 'Return Request’ in the
        subject line.</p>
      <p>(iii) KSExchange OU provides the Refund/Return using the same means of payment as the User used to pay, unless
        the
        User expressly agrees otherwise.</p>
    </div>

    <H2>6. PROCESSING TIMELINE</H2>
    <div className="b-typeset">
      <p>
        Within fifteen (15) Business Days as of the date of Refund From/Return Form receipt, KSExchange OU shall contact
        the
        User to request further information (if required) and the User shall provide such information as soon as
        reasonably practicable but in any case no later than fourteen (14) days following such request.
      </p>

      <p>KSExchange OU shall notify the User by e-mail on its final decision regarding Refund/Return:</p>
      <p>within fifteen (15) Business Days following the receipt of the last requested additional document or
        information;
        within twenty (20) Business Days following the receipt of Refund Form/Return Form if no further information or
        document is requested. Refund/Return will be processed without undue delay, and in any event within fourteen
        (14)
        days beginning with the day on which the KSExchange OU agrees that the User is entitled to a Refund/Return.</p>
      <p>The User will have no Refund/Return :</p>
      <p>When requested by KSExchange OU to do so, the User fails to provide KSExchange OU with information or/and
        document(s)
        within the terms set herein.</p>
      <p>If KSExchange OU suspects that the User has, or is, engaged in, or have in any way been involved in, fraudulent
        or
        illegal activity, including Prohibited Conduct.</p>
      <p>There is no ground for Refund/Return.</p>
      <p>NOTE: KSExchange OU reserves the right to lock the User’s Funds while Refund/Return investigation is in process,
        which means that the Funds could be inaccessible during such investigation.</p>
    </div>

    <H2>7. CHARGEBACKS</H2>

    <div className="b-typeset">
      <p>
        KSExchange OU expects the User to contact it using KSExchange OU contact details to resolve any problem or issue
        related to his/her payments before the User makes any Chargeback request. This Section does not affect any rights and/or
        claims, which the User may have against the bank/financial institution.
      </p>

      <p>
        KSExchange OU will investigate any Chargeback requests made by the User and in response will inform the User’s
        Issuing Bank whether any Service or Transaction has been cancelled.
      </p>

      <p>
        KSExchange OU reserves the right to suspend User's account and lock User's Funds during the chargeback investigation
        procedure.
      </p>
    </div>

    <H2>8. MISCELLANEOUS</H2>

    <div className="b-typeset">
      <p>
        Any charges, which arise upon processing Refund/Return, shall be borne solely by the User. Such charges will be
        deducted from the final amount of Refund/Return.
      </p>
      <p>
        This Refund Policy will be amended from time to time if there is any change in the legislation. Terms and
        conditions of the Refund Policy are subject to change by KSExchange OU and in the case of any amendments,
        KSExchange OU will make an appropriate announcement. The changes will apply after KSExchange OU has given notice.
      </p>
      <p>
        In case if the User does not wish to accept the revised Refund and Return Policy, he/she should not continue to
        use KSExchange OU Services. If the User continues to use the Services after the date on which the change comes into
        effect, his/her use of the Services to be bound by the new Refund Policy and Return.
      </p>
    </div>
  </Transition>
);
