import gql from 'graphql-tag';

export const feedbackOpenAdminList = gql`
  query FeedbackOpenAdminList {
    feedbackOpenAdminList {
      meta {
        total
      }
      objects
    }
  }
`;