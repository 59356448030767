import { compose, withProps, lifecycle } from 'recompose';
import { graphql } from 'react-apollo';
import { feedbackOpenAdminList } from '../query';

export const withOpenFeedback = compose(
  graphql(feedbackOpenAdminList, { notifyOnNetworkStatusChange: true,fetchPolicy: 'network-only' }),
  withProps(props => ({
    ...props,
    feedbackOpenAdminList: props.data.feedbackOpenAdminList,
    updateFeedbackOpenAdminList: () => props.data.refetch()
  })),

  lifecycle({
    componentDidMount() {
      this.props.updateFeedbackOpenAdminList();
    }
  })
);
