import { compose, withProps } from 'recompose';
import { withRouter } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { user } from '../../query';
import View from './View';
import { withAdminT } from '../../../../hoc';

export default compose(
  withAdminT,
  withRouter,
  graphql(user, {
    options: props => ({
      variables: {
        userId: +props.match.params.id
      },
      fetchPolicy: 'network-only',
    })
  }),
  withProps(props => ({
    ...props,
    loading: props.data.loading,
    fetch: userId => props.data.refetch({ userId: +userId }),
    user: props.data.user
  }))
)(View);
