import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, Transition } from 'firefly/component';
import UserTable from '../../component/Table';
import Header from '../../../../component/Header';

export default class List extends Component {
  static propTypes = {
    filter: PropTypes.object,
    meta: PropTypes.object,
    objects: PropTypes.array,
    t: PropTypes.func.isRequired
  };

  static defaultProps = {
    filter: {},
    meta: {},
    objects: []
  };

  render() {
    const { t } = this.props;
    return (
      <div>
        <Header pageTitle={t('users_page.users')}/>
        <Tabs defaultSelectedTabId='users'>
          <Tab id='users' title={t('users_page.users')}>
            <Transition>
              <UserTable {...this.props} />
            </Transition>
          </Tab>
        </Tabs>
      </div>
    );
  }
}
