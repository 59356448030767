import React from 'react';
import { Transition, H1 } from 'firefly/component';
import { compose, lifecycle } from 'recompose';
import { writeLocalState } from '../utils/index';
import bg_top from '../img/events-bg/lg.png';
import { withT } from '../hoc';
import {ButtonBack} from "../module/ButtonBack";

export const InDevelopmentScreen = ({ t }) => (
  <Transition>
    <ButtonBack goToPage="/"/>
    <H1 className="b-page__centered-header">{t('mobile_app.header1')}<br/>
      {t('mobile_app.header2')}
    </H1>
  </Transition>
);

export const InDevelopment = compose(
  withT,
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(InDevelopmentScreen);
