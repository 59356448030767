import React from 'react';
import { compose, lifecycle } from 'recompose';
import { H1 } from 'firefly/component';
import { withT } from '../hoc';
import { writeLocalState } from '../utils/index';
import { Login as Component } from '../component/Login';
import bg_top from '../img/events-bg/lg.png';
import { ButtonBack } from '../module/ButtonBack';

const LoginScreen = withT(({ t }) => (
  <div className="b-login">
    <ButtonBack goToPage="/"/>
    <H1 className="b-parties__header">{t('login.log_in')}</H1>
    <Component />
  </div>
));

export const Login = compose(
  lifecycle({
    componentDidMount() {
      writeLocalState({ bg_top, bg_bottom: '', bg_top_position: 'top right' });
    }
  })
)(LoginScreen);
