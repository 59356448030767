import gql from 'graphql-tag';

export const login = gql`
  mutation login($email: String, $password: String) {
    login(email: $email, password: $password) {
      jwt
    }
  }
`;

export const vkAuth = gql`
  mutation vkAuth($code: String, $url: String) {
    vkAuth(code: $code, url: $url) {
      jwt
      type
    }
  }
`;

export const fbAuth = gql`
  mutation fbAuth($user: JSON) {
    fbAuth(user: $user) {
      jwt
      type
    }
  }
`;

export const fbAuthMobile = gql`
  mutation fbAuthMobile($code: String, $url: String) {
    fbAuthMobile(code: $code, url: $url) {
      jwt
      type
    }
  }
`;

export const registrationConfirm = gql`
    mutation registrationConfirm($token: String) {
      registrationConfirm(token: $token) { jwt }
    }
  `;

export const restore = gql`
    mutation restore($email: String) {
      restore(email: $email)
    }
  `;

export const checkEmailConfirmationToken = gql`
    mutation restoreCheck($token: String) {
        restoreCheck(token: $token)
    }
  `;

export const restorePassword = gql`
    mutation restoreConfirm($token: String, $password: String, $password_confirm: String) {
        restoreConfirm(token: $token, password: $password, password_confirm: $password_confirm) { jwt }
    }
  `;

export const registration = gql`
    mutation registration($input: RegistrationData) {
      registration(input: $input)
    }
  `;

export const editProfile = gql`
    mutation editUserData($input: EditUserData) {
      editUserData(input: $input)
    }
`;
