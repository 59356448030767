import React from 'react';
import { Group, Button, Errors } from 'firefly/component';
import { CitySelector } from '../../module/CitySelector';

export const OwnCityForm = ({ t, onSubmit, onChange, form, formLoading, errors }) => (
  <form onSubmit={onSubmit} className="b-contact-us__form">
    <div className="b-contact-us__form__popover-picker">
      <p className="b-contact-us__form__popover-picker__label">
        {t('contact_us.own_city_modal.city')}
      </p>
      <CitySelector onChange={onChange('city_id', 'free')} cityId={form.city_id} className="b-parties__filters__popover-picker__content"/>
      <Errors errors={errors.city_id} className="test" />
    </div>
    <Group
      errors={errors}
      loading={formLoading}
      values={form}
      onChange={onChange}
      fields={[
        {
          name: 'name',
          label: t('contact_us.own_city_modal.your_name'),
          type: 'text',
          required: false
        },
        {
          name: 'contact_information',
          label: t('contact_us.own_party_modal.contact_information'),
          type: 'text',
          required: false
        },
        {
          name: 'letter',
          label: t('contact_us.own_city_modal.describe_yourself'),
          type: 'textarea',
          required: false
        }
      ]}
    />
    <p className="b-contact-us__form__help">{t('contact_us.own_city_modal.help')}</p>

    <Button className="b-button__main">{t('contact_us.own_city_modal.submit')}</Button>
  </form>
);
