import React, { Fragment } from 'react';
import {Group, Button, DialogBody, H4, Dialog} from 'firefly/component';

export const PasswordRecovery = ({ t, onSubmit, errors, form, onChange, showSuccessMessage, setShowSuccessMessage }) => (
  <Fragment>
    <Dialog isOpen={showSuccessMessage} onClose={() => setShowSuccessMessage(false)}>
      <DialogBody>
        <H4 className="b-dialog__body__text">{t('registration.confirming_email_sent')}</H4>
        <Button className="b-button__main" onClick={() => setShowSuccessMessage(false)}>ОК</Button>
      </DialogBody>
    </Dialog>

    <form onSubmit={onSubmit}>
      <Group
        errors={errors}
        values={form}
        onChange={onChange}
        fields={[
          {
            name: 'email',
            label: t('login.email'),
            type: 'text',
            required: false
          }
        ]}/>

      <Button
        type='submit'
        className="b-button__main"
        >{t('password_recovery.button_send')}</Button>
    </form>
  </Fragment>
);