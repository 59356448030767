import gql from 'graphql-tag';

export const festivalAdminList = gql`
  query FestivalAdminList($input: FestivalListAdminInput!) {
    festivalAdminList(input: $input) {
      meta {
        page
        page_size
        page_count
        total
      }
      objects
    }
  }
`;

export const festivalAdmin = gql`
  query festivalAdmin($input: FestivalQueryAdminInput!) {
    festivalAdmin(input: $input)
  }
`;
