import { compose, withProps } from 'recompose';
import { withUser, withCities } from '../../../../../hoc';
import { isMainAdmin } from '../../../../../utils/user';
import FilterForm from './FilterForm';

const getIds = objects => objects.map(object => object.id);

export default compose(
  withUser,
  withCities,
  withProps(props => ({
    ...props,
    allAllowedCities: isMainAdmin(props.user)
      ? Object.keys(props.cities).map(Number)
      : getIds(props.user.ownedCity)
  }))
)(FilterForm);
