import gql from 'graphql-tag';


export const createFestival = gql`
  mutation createFestival($input: FestivalInput!) {
    createFestival(input: $input)
  }
`;

export const festival = gql`
  mutation festival($input: FestivalInput!) {
    festival(input: $input)
  }
`;

export const deleteFestival = gql`
  mutation deleteFestival($input: DeleteFestivalInput!) {
    deleteFestival(input: $input)
  }
`;