import React, { PureComponent, createElement } from 'react';
import PropTypes from 'prop-types';
import { Part } from './Part';

export class Group extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    values: PropTypes.object,
    loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    errors: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  };

  static defaultProps = {
    loading: false,
    errors: [],
    values: {}
  };

  // eslint-disable-next-line class-methods-use-this
  getLabel({ label, name }) {
    return typeof label === 'string' ? String(label || name) : createElement('div', {}, label);
  }

  render() {
    const { fields, errors, loading, onChange, values } = this.props;

    return fields.map((field, i) => (
      <Part
        {...field}
        key={i}
        id={field.id || field.name}
        disabled={loading}
        label={typeof field.label === 'string' ? String(field.label || field.name) : (field.label || field.name)}
        value={values[field.name] || ''}
        errors={errors}
        onChange={onChange(field.name, field.type)}
      />
    ));
  }
}
