import gql from 'graphql-tag';


export const createParty = gql`
  mutation createParty($input: PartyInput!) {
    createParty(input: $input)
  }
`;

export const party = gql`
  mutation party($input: PartyInput!) {
    party(input: $input)
  }
`;

export const deleteParty = gql`
  mutation deleteParty($input: DeletePartyInput!) {
    deleteParty(input: $input)
  }
`;