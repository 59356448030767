/*global google*/
import React from 'react';
import _ from 'lodash'
import { compose, withProps, lifecycle } from 'recompose';
import cx from 'classnames';
import { SpinnerGlobal } from 'firefly/component/Spinner';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import { googleMapsApiKey } from '../../config';
import style from './style';
import { CurrentLocationMarker } from './CurrentLocationMarker';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { writeLocalState } from '../../utils/index';
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

export const Container = ({ children, center, onCenterChange = f => f, zoom = 12, scrollwheel = true, onSearchBoxMounted, bounds, onPlacesChanged }) => (
  <GoogleMap
    zoom={zoom}
    onClick={() => writeLocalState({ markers_closed: true })}
    onCenterChanged={function f() {
      const centerPosition = this.getCenter();
      const [lat, lng] = [centerPosition.lat(), centerPosition.lng()];
      onCenterChange({
        lat,
        lng
      });
    }}
    center={center}
    defaultOptions={{ styles: style, disableDefaultUI: false, scrollwheel }}
  >
    <SearchBox
      ref={onSearchBoxMounted}
      bounds={bounds}
      controlPosition={google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={onPlacesChanged}
    >
      <input
        type="text"
        placeholder="Search"
        style={{
          background: `#212121`,
          color: `#fff`,
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `40px`,
          marginTop: `10px`,
          padding: `0 12px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }}
      />
    </SearchBox>
    <CurrentLocationMarker/>
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={60}
    >
      {children}
    </MarkerClusterer>
  </GoogleMap>
);

export const Map = compose(
  withProps(props => ({
    ...props,
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${googleMapsApiKey}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <SpinnerGlobal/>,
    containerElement: <div className={cx('b-map', { 'b-map--full-width': props.fullWidth })} />,
    mapElement: <div className={cx('b-map__map', { 'b-map__map--location-picker': props.locationPicker })} />
  })),
  lifecycle({
    componentDidMount() {

    },
    componentWillMount() {
      const refs = {};

      this.setState({
        bounds: null,
        markers: [],
        onMapMounted: ref => {
          refs.map = ref;
        },
        onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new google.maps.LatLngBounds();

          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
          });
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);

          this.setState({
            center: nextCenter,
            markers: nextMarkers,
          });
          // refs.map.fitBounds(bounds);
        },
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(Container);
