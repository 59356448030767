import React, { Fragment } from 'react';
import { Group, Button } from 'firefly/component';


export const SuggestPartyForm = ({ t, onSubmit, onChange, form, formLoading, errors }) => (
  <Fragment>
    <form onSubmit={onSubmit} className="b-contact-us__form">
      <p className="b-contact-us__form__subheader">{t('contact_us.suggest_party_modal.basic_info')}</p>

      <Group
        errors={errors}
        loading={formLoading}
        values={form}
        onChange={onChange}
        fields={[
          {
            name: 'link_on_party',
            label: t('contact_us.suggest_party_modal.party_link'),
            type: 'text',
            required: false
          },
          {
            name: 'description',
            label: t('contact_us.suggest_party_modal.description'),
            type: 'textarea',
            required: false
          }
        ]}
      />
      <p className="b-contact-us__form__help">{t('contact_us.suggest_party_modal.help')}</p>

      <Button className="b-button__main">{t('contact_us.suggest_party_modal.submit')}</Button>
    </form>
  </Fragment>
);
