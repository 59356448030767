import { graphql } from 'react-apollo';
import { compose, withProps } from 'recompose';
import { publicSettings } from '../query';

export const withSettings = compose(
  graphql(publicSettings),
  withProps(props => ({
    ...props,
    settings: props.data.publicSettings || {}
  }))
);
