import { Form } from 'firefly/component';

export default class FilterForm extends Form {
  onReset = () => {
    const { form } = this.state;
    let newForm = {};
    const keys = Object.keys(form);
    for (let i = 0; i < keys.length; i++) {
      const key = keys[i];
      newForm[key] = null;
    }

    const { callback, onSuccess } = this.props;

    this.setState({
      form: newForm
    }, () => {
      callback(this.state.form).then(response => {
        if (onSuccess) {
          onSuccess({
            response,
            form: this.state.form
          });
        }
      });
    });
  };
}
