import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/dance_icon.png';


export const Logo = ({ className = 'b-header__logo' }) => (
  <Link className={className} to='/'>
    <img className={className} src={logo} alt=""/>
  </Link>
);
