import { compose, withProps, lifecycle } from 'recompose';
import { graphql } from 'react-apollo';
import { editProfile } from '../query';
import { ModalEditProfile as Container } from '../container/ModalEditProfile';
import { withT, withUser } from '../hoc';
import { withFormState } from "firefly-hoc";
import {filterValues, toastSuccess} from "../utils";

export const ModalEditProfile = compose(
  withT,
  withUser,
  graphql(editProfile),
  withProps(props => ({
    ...props,
    callback: form => props.mutate({ variables: {
        input: filterValues({
          ...form,
          agree_terms: form.agree_terms,
          id: undefined,
          avatar_path: undefined,
          is_active: undefined,
          type: undefined,
          ownedCity: undefined,
          ownedParty: undefined,
          ownedFestival: undefined,
        })
      } }),
    onSuccess() {
      toastSuccess('Profile updated!');
      props.updateUser();
    }
  })),
  withFormState,

  lifecycle({
    componentDidUpdate() {
      const { props } = this;
      if (Object.keys(props.form).length === 0) {
        props.setForm((props.user || {}));
      }
    }
  })
)(Container);