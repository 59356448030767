import { compose, withProps, branch, renderComponent, withState } from 'recompose';
import { graphql } from 'react-apollo';
import { SpinnerGlobal } from 'firefly/component/Spinner';
import {create_pass_ticket, party} from '../query';
import { Party as Container } from '../container';
import { withT, withUser } from '../hoc';

export const Party = compose(
  withT,
  withUser,
  graphql(party, {
    options: props => ({
      variables: {
        id: Number(props.id)
      }
    })
  }),
  graphql(create_pass_ticket, { name: "create_pass_ticket" }),
  withState('modalProfileEdit', 'setModalProfileEdit', false),
  withProps(props => ({
    ...props,
    party: props.data.party || {},
    is_loading: props.data.loading || !props.data.party
  })),
  withProps(props => ({
    ...props,
  })),
  branch(props => props.is_loading, renderComponent(SpinnerGlobal))
)(Container);
