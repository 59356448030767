import React from 'react';
import { getErrors } from './utils';

export const Errors = ({ errors, path }) => {
  const finalErrors = path ? getErrors(path, errors) : errors;

  if (Array.isArray(finalErrors)) {
    return finalErrors.map((err, i) => (
      <div className="b-errors__error" key={i}>{err}</div>
    ));
  }

  return null;
};
