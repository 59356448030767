import React from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Icon } from 'firefly/component';
import BaseFilterForm from '../../../component/FilterForm';

export default class SearchQueryField extends BaseFilterForm {
  static propTypes = {
    t: PropTypes.func.isRequired
  };

  render() {
    const {
      form,
    } = this.state;
    const { t } = this.props;

    return (
      <form onSubmit={this.submit} className="b-admin-filters">
        <Input
          type='text'
          name="q"
          placeholder={t('festivals_page.filters.search')}
          value={form.q || ''}
          onChange={this.change('q')}
          className='b-input' />
        <Button type="submit" modifiers='icon'>
          <Icon icon='Search' />
        </Button>
      </form>
    );
  }
}
