import { compose, withProps } from 'recompose';
import { graphql } from 'react-apollo';
import { withFormState } from 'firefly-hoc';
import { suggest_party } from '../../query';
import { withCities, withT } from '../../hoc';
import { filterValues, toastSuccess } from '../../utils/index';
import { SuggestPartyForm as Container } from '../../container/ContactUs/SuggestPartyForm';

export const SuggestPartyForm = compose(
  withT,
  withCities,
  graphql(suggest_party),
  withProps(props => ({
    ...props,
    callback: form => props.mutate({ variables: {
      input: filterValues({
        ...form,
      })
    } }),

    onSuccess() {
      toastSuccess('Your request has been successfully sent');
      props.onClose();
    }
  })),
  withFormState,
)(Container);
