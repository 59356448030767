import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import * as user from './locales';
import * as admin from './locales/admin';

i18n
  .use(LanguageDetector)
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    ns: ['user', 'admin'],
    defaultNS: 'user',
    fallbackLng: 'ru',
    debug: true,
    resources: {
      en: {
        user: user.en,
        admin: admin.en
      },
      ru: {
        user: user.ru,
        admin: admin.ru
      },
    },
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
